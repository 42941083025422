@charset "UTF-8";
@import "~bootstrap-icons/font/bootstrap-icons.css";
@font-face {
  font-family: "Siemens Serif";
  font-weight: normal;
  font-style: normal;
  src: local("Siemens Serif Roman"), url("~@simpl/fonts/dist/fonts//SiemensSerif_Prof_Roman.woff2") format("woff2"), url("~@simpl/fonts/dist/fonts//SiemensSerif_Prof_Roman.woff") format("woff");
}
@font-face {
  font-family: "Siemens Serif";
  font-weight: normal;
  font-style: italic;
  src: local("Siemens Serif Roman Italic"), url("~@simpl/fonts/dist/fonts//SiemensSerif_Prof_Italic.woff2") format("woff2"), url("~@simpl/fonts/dist/fonts//SiemensSerif_Prof_Italic.woff") format("woff");
}
@font-face {
  font-family: "Siemens Serif";
  font-weight: 600;
  font-style: normal;
  src: local("Siemens Serif Semibold"), url("~@simpl/fonts/dist/fonts//SiemensSerif_Prof_Semibold.woff2") format("woff2"), url("~@simpl/fonts/dist/fonts//SiemensSerif_Prof_Semibold.woff") format("woff");
}
@font-face {
  font-family: "Siemens Serif";
  font-weight: 600;
  font-style: italic;
  src: local("Siemens Serif Semibold Italic"), url("~@simpl/fonts/dist/fonts//SiemensSerif_Prof_SemiboldItalic.woff2") format("woff2"), url("~@simpl/fonts/dist/fonts//SiemensSerif_Prof_SemiboldItalic.woff") format("woff");
}
@font-face {
  font-family: "Siemens Serif";
  font-weight: bold;
  font-style: normal;
  src: local("Siemens Serif Bold"), url("~@simpl/fonts/dist/fonts//SiemensSerif_Prof_Bold.woff2") format("woff2"), url("~@simpl/fonts/dist/fonts//SiemensSerif_Prof_Bold.woff") format("woff");
}
@font-face {
  font-family: "Siemens Serif";
  font-weight: bold;
  font-style: italic;
  src: local("Siemens Serif Bold Italic"), url("~@simpl/fonts/dist/fonts//SiemensSerif_Prof_BoldItalic.woff2") format("woff2"), url("~@simpl/fonts/dist/fonts//SiemensSerif_Prof_BoldItalic.woff") format("woff");
}
@font-face {
  font-family: "Siemens Sans";
  font-weight: normal;
  font-style: normal;
  src: local("Siemens Sans Roman"), url("~@simpl/fonts/dist/fonts//SiemensSans_Prof_Roman.woff2") format("woff2"), url("~@simpl/fonts/dist/fonts//SiemensSans_Prof_Roman.woff") format("woff");
}
@font-face {
  font-family: "Siemens Sans";
  font-weight: normal;
  font-style: italic;
  src: local("Siemens Sans Roman Italic"), url("~@simpl/fonts/dist/fonts//SiemensSans_Prof_Italic.woff2") format("woff2"), url("~@simpl/fonts/dist/fonts//SiemensSans_Prof_Italic.woff") format("woff");
}
@font-face {
  font-family: "Siemens Sans";
  font-weight: bold;
  font-style: normal;
  src: local("Siemens Sans Bold"), url("~@simpl/fonts/dist/fonts//SiemensSans_Prof_Bold.woff2") format("woff2"), url("~@simpl/fonts/dist/fonts//SiemensSans_Prof_Bold.woff") format("woff");
}
@font-face {
  font-family: "Siemens Sans";
  font-weight: bold;
  font-style: italic;
  src: local("Siemens Sans Bold Italic"), url("~@simpl/fonts/dist/fonts//SiemensSans_Prof_BoldItalic.woff2") format("woff2"), url("~@simpl/fonts/dist/fonts//SiemensSans_Prof_BoldItalic.woff") format("woff");
}
@font-face {
  font-family: "Siemens Sans";
  font-weight: 900;
  font-style: normal;
  src: local("Siemens Sans Black"), url("~@simpl/fonts/dist/fonts//SiemensSans_Prof_Black.woff2") format("woff2"), url("~@simpl/fonts/dist/fonts//SiemensSans_Prof_Black.woff") format("woff");
}
@font-face {
  font-family: "Siemens Sans";
  font-weight: 900;
  font-style: italic;
  src: local("Siemens Sans Black Italic"), url("~@simpl/fonts/dist/fonts//SiemensSans_Prof_BlackItalic.woff2") format("woff2"), url("~@simpl/fonts/dist/fonts//SiemensSans_Prof_BlackItalic.woff") format("woff");
}
/* stylelint-disable scss/dollar-variable-pattern */
/* stylelint-enable scss/dollar-variable-pattern */
/* stylelint-disable scss/dollar-variable-pattern */
/* stylelint-enable scss/dollar-variable-pattern */
/* the above colors will be used for button gradients, but if the following
is not null the button will get a plain background colors */
*,
*::before,
*::after {
  box-sizing: border-box;
}

html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

article, aside, figcaption, figure, footer, header, hgroup, main, nav, section {
  display: block;
}

body {
  margin: 0;
  font-family: "Siemens Sans";
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
  color: #2d373c;
  text-align: left;
  background-color: #fff;
}

[tabindex="-1"]:focus:not(:focus-visible) {
  outline: 0 !important;
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

h1, .h1d, h2, .h2d, h3, .h3d, h4, .h4d, .alert,
.alert-dismissible, h5, .h5d, h6 {
  margin-top: 0;
  margin-bottom: 0.5rem;
}

p, .p2, .p2d, .ps, .psd,
.p3,
.p3d, .p1, .p1d, .pd {
  margin-top: 0;
  margin-bottom: 1rem;
}

abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0;
  text-decoration-skip-ink: none;
}

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit;
}

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem;
}

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0;
}

dt {
  font-weight: 700;
}

dd {
  margin-bottom: 0.5rem;
  margin-left: 0;
}

blockquote {
  margin: 0 0 1rem;
}

b,
strong {
  font-weight: bolder;
}

small {
  font-size: 80%;
}

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

a {
  color: #2387aa;
  text-decoration: none;
  background-color: transparent;
}
a:hover {
  color: #005578;
  text-decoration: underline;
}

a:not([href]):not([class]) {
  color: inherit;
  text-decoration: none;
}
a:not([href]):not([class]):hover {
  color: inherit;
  text-decoration: none;
}

pre,
code,
kbd,
samp {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  font-size: 1em;
}

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  -ms-overflow-style: scrollbar;
}

figure {
  margin: 0 0 1rem;
}

img {
  vertical-align: middle;
  border-style: none;
}

svg {
  overflow: hidden;
  vertical-align: middle;
}

table {
  border-collapse: collapse;
}

caption {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: #6c757d;
  text-align: left;
  caption-side: bottom;
}

th {
  text-align: inherit;
  text-align: -webkit-match-parent;
}

label {
  display: inline-block;
  margin-bottom: 0.5rem;
}

button {
  border-radius: 0;
}

button:focus:not(:focus-visible) {
  outline: 0;
}

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

button,
input {
  overflow: visible;
}

button,
select {
  text-transform: none;
}

[role=button] {
  cursor: pointer;
}

select {
  word-wrap: normal;
}

button,
[type=button],
[type=reset],
[type=submit] {
  -webkit-appearance: button;
}

button:not(:disabled),
[type=button]:not(:disabled),
[type=reset]:not(:disabled),
[type=submit]:not(:disabled) {
  cursor: pointer;
}

button::-moz-focus-inner,
[type=button]::-moz-focus-inner,
[type=reset]::-moz-focus-inner,
[type=submit]::-moz-focus-inner {
  padding: 0;
  border-style: none;
}

input[type=radio],
input[type=checkbox] {
  box-sizing: border-box;
  padding: 0;
}

textarea {
  overflow: auto;
  resize: vertical;
}

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: 0.5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal;
}

progress {
  vertical-align: baseline;
}

[type=number]::-webkit-inner-spin-button,
[type=number]::-webkit-outer-spin-button {
  height: auto;
}

[type=search] {
  outline-offset: -2px;
  -webkit-appearance: none;
}

[type=search]::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button;
}

output {
  display: inline-block;
}

summary {
  display: list-item;
  cursor: pointer;
}

template {
  display: none;
}

[hidden] {
  display: none !important;
}

*:focus,
*:active {
  outline: none;
}

html {
  overflow-y: scroll;
  overflow-x: hidden;
  min-height: 100%;
}

body {
  min-height: 100vh;
  display: flex;
  overflow-x: hidden;
  flex-direction: column;
}

main {
  flex: 1;
}

table {
  border-spacing: 0;
}

.footer {
  width: 100%;
  padding-top: 40px;
  padding-bottom: 50px;
  background: #ebf0f5;
  font-family: "Siemens Sans";
}
.footer ul {
  list-style: none;
  padding-left: 0;
}
.footer ul > li {
  line-height: 16px;
  vertical-align: bottom;
}
.footer ul > li > a {
  color: #697882;
  line-height: 20px;
  padding-right: 10px;
  text-decoration: none;
}
.footer ul > li > a:hover {
  color: #005578;
  text-decoration: underline;
}
.footer ul > li > a:active {
  color: #005578;
}
.footer ul > li > a::before {
  font-family: "simpl-icons-newton";
  content: "";
  display: inline-block;
  font-size: 14px;
  position: relative;
  top: 3px;
  left: -3px;
}
.footer ul > li > a > span {
  text-decoration: none;
  border: 0;
}
.footer h4, .footer .h4d, .footer .alert,
.footer .alert-dismissible,
.footer h4 {
  margin-bottom: 20px;
}
.footer .sn-meta-navigation {
  margin-top: 50px;
  display: flex;
  flex-flow: column wrap;
}
.footer .sn-meta-navigation > div {
  display: flex;
  flex-flow: column wrap;
}
.footer .sn-meta-navigation > div > div {
  margin-bottom: 15px;
}
@media (min-width: 576px) {
  .footer .sn-meta-navigation {
    flex-flow: row wrap;
  }
  .footer .sn-meta-navigation > div {
    display: flex;
    flex-flow: row wrap;
  }
  .footer .sn-meta-navigation > div > div {
    margin-right: 15px;
  }
}

.img-fluid {
  max-width: 100%;
  height: auto;
}

.img-thumbnail {
  padding: 0.25rem;
  background-color: #fff;
  border: 1px solid #dee2e6;
  border-radius: 0;
  max-width: 100%;
  height: auto;
}

.figure {
  display: inline-block;
}

.figure-img {
  margin-bottom: 0.5rem;
  line-height: 1;
}

.figure-caption {
  font-size: 90%;
  color: #6c757d;
}

code {
  font-size: 87.5%;
  color: #e83e8c;
  word-wrap: break-word;
}
a > code {
  color: inherit;
}

kbd {
  padding: 0.2rem 0.4rem;
  font-size: 87.5%;
  color: #fff;
  background-color: #212529;
  border-radius: 0;
}
kbd kbd {
  padding: 0;
  font-size: 100%;
  font-weight: 700;
}

pre {
  display: block;
  font-size: 87.5%;
  color: #212529;
}
pre code {
  font-size: inherit;
  color: inherit;
  word-break: normal;
}

.pre-scrollable {
  max-height: 340px;
  overflow-y: scroll;
}

.container,
.container-fluid,
.container-xl,
.container-lg,
.container-md,
.container-sm {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 576px) {
  .container-sm, .container {
    max-width: 570px;
  }
}
@media (min-width: 768px) {
  .container-md, .container-sm, .container {
    max-width: 780px;
  }
}
@media (min-width: 992px) {
  .container-lg, .container-md, .container-sm, .container {
    max-width: 1000px;
  }
}
@media (min-width: 1200px) {
  .container-xl, .container-lg, .container-md, .container-sm, .container {
    max-width: 1200px;
  }
}
.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}

.no-gutters {
  margin-right: 0;
  margin-left: 0;
}
.no-gutters > .col,
.no-gutters > [class*=col-] {
  padding-right: 0;
  padding-left: 0;
}

.col-xl,
.col-xl-auto, .col-xl-12, .col-xl-11, .col-xl-10, .col-xl-9, .col-xl-8, .col-xl-7, .col-xl-6, .col-xl-5, .col-xl-4, .col-xl-3, .col-xl-2, .col-xl-1, .col-lg,
.col-lg-auto, .col-lg-12, .col-lg-11, .col-lg-10, .col-lg-9, .col-lg-8, .col-lg-7, .col-lg-6, .col-lg-5, .col-lg-4, .col-lg-3, .col-lg-2, .col-lg-1, .col-md,
.col-md-auto, .col-md-12, .col-md-11, .col-md-10, .col-md-9, .col-md-8, .col-md-7, .col-md-6, .col-md-5, .col-md-4, .col-md-3, .col-md-2, .col-md-1, .col-sm,
.col-sm-auto, .col-sm-12, .col-sm-11, .col-sm-10, .col-sm-9, .col-sm-8, .col-sm-7, .col-sm-6, .col-sm-5, .col-sm-4, .col-sm-3, .col-sm-2, .col-sm-1, .col,
.col-auto, .col-12, .col-11, .col-10, .col-9, .col-8, .col-7, .col-6, .col-5, .col-4, .col-3, .col-2, .col-1 {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
}

.col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%;
}

.row-cols-1 > * {
  flex: 0 0 100%;
  max-width: 100%;
}

.row-cols-2 > * {
  flex: 0 0 50%;
  max-width: 50%;
}

.row-cols-3 > * {
  flex: 0 0 33.3333333333%;
  max-width: 33.3333333333%;
}

.row-cols-4 > * {
  flex: 0 0 25%;
  max-width: 25%;
}

.row-cols-5 > * {
  flex: 0 0 20%;
  max-width: 20%;
}

.row-cols-6 > * {
  flex: 0 0 16.6666666667%;
  max-width: 16.6666666667%;
}

.col-auto {
  flex: 0 0 auto;
  width: auto;
  max-width: 100%;
}

.col-1 {
  flex: 0 0 8.3333333333%;
  max-width: 8.3333333333%;
}

.col-2 {
  flex: 0 0 16.6666666667%;
  max-width: 16.6666666667%;
}

.col-3 {
  flex: 0 0 25%;
  max-width: 25%;
}

.col-4 {
  flex: 0 0 33.3333333333%;
  max-width: 33.3333333333%;
}

.col-5 {
  flex: 0 0 41.6666666667%;
  max-width: 41.6666666667%;
}

.col-6 {
  flex: 0 0 50%;
  max-width: 50%;
}

.col-7 {
  flex: 0 0 58.3333333333%;
  max-width: 58.3333333333%;
}

.col-8 {
  flex: 0 0 66.6666666667%;
  max-width: 66.6666666667%;
}

.col-9 {
  flex: 0 0 75%;
  max-width: 75%;
}

.col-10 {
  flex: 0 0 83.3333333333%;
  max-width: 83.3333333333%;
}

.col-11 {
  flex: 0 0 91.6666666667%;
  max-width: 91.6666666667%;
}

.col-12 {
  flex: 0 0 100%;
  max-width: 100%;
}

.order-first {
  order: -1;
}

.order-last {
  order: 13;
}

.order-0 {
  order: 0;
}

.order-1 {
  order: 1;
}

.order-2 {
  order: 2;
}

.order-3 {
  order: 3;
}

.order-4 {
  order: 4;
}

.order-5 {
  order: 5;
}

.order-6 {
  order: 6;
}

.order-7 {
  order: 7;
}

.order-8 {
  order: 8;
}

.order-9 {
  order: 9;
}

.order-10 {
  order: 10;
}

.order-11 {
  order: 11;
}

.order-12 {
  order: 12;
}

.offset-1 {
  margin-left: 8.3333333333%;
}

.offset-2 {
  margin-left: 16.6666666667%;
}

.offset-3 {
  margin-left: 25%;
}

.offset-4 {
  margin-left: 33.3333333333%;
}

.offset-5 {
  margin-left: 41.6666666667%;
}

.offset-6 {
  margin-left: 50%;
}

.offset-7 {
  margin-left: 58.3333333333%;
}

.offset-8 {
  margin-left: 66.6666666667%;
}

.offset-9 {
  margin-left: 75%;
}

.offset-10 {
  margin-left: 83.3333333333%;
}

.offset-11 {
  margin-left: 91.6666666667%;
}

@media (min-width: 576px) {
  .col-sm {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }

  .row-cols-sm-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .row-cols-sm-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .row-cols-sm-3 > * {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }

  .row-cols-sm-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .row-cols-sm-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }

  .row-cols-sm-6 > * {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }

  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }

  .col-sm-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }

  .col-sm-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }

  .col-sm-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col-sm-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }

  .col-sm-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }

  .col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col-sm-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }

  .col-sm-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }

  .col-sm-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }

  .col-sm-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }

  .col-sm-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }

  .col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .order-sm-first {
    order: -1;
  }

  .order-sm-last {
    order: 13;
  }

  .order-sm-0 {
    order: 0;
  }

  .order-sm-1 {
    order: 1;
  }

  .order-sm-2 {
    order: 2;
  }

  .order-sm-3 {
    order: 3;
  }

  .order-sm-4 {
    order: 4;
  }

  .order-sm-5 {
    order: 5;
  }

  .order-sm-6 {
    order: 6;
  }

  .order-sm-7 {
    order: 7;
  }

  .order-sm-8 {
    order: 8;
  }

  .order-sm-9 {
    order: 9;
  }

  .order-sm-10 {
    order: 10;
  }

  .order-sm-11 {
    order: 11;
  }

  .order-sm-12 {
    order: 12;
  }

  .offset-sm-0 {
    margin-left: 0;
  }

  .offset-sm-1 {
    margin-left: 8.3333333333%;
  }

  .offset-sm-2 {
    margin-left: 16.6666666667%;
  }

  .offset-sm-3 {
    margin-left: 25%;
  }

  .offset-sm-4 {
    margin-left: 33.3333333333%;
  }

  .offset-sm-5 {
    margin-left: 41.6666666667%;
  }

  .offset-sm-6 {
    margin-left: 50%;
  }

  .offset-sm-7 {
    margin-left: 58.3333333333%;
  }

  .offset-sm-8 {
    margin-left: 66.6666666667%;
  }

  .offset-sm-9 {
    margin-left: 75%;
  }

  .offset-sm-10 {
    margin-left: 83.3333333333%;
  }

  .offset-sm-11 {
    margin-left: 91.6666666667%;
  }
}
@media (min-width: 768px) {
  .col-md {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }

  .row-cols-md-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .row-cols-md-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .row-cols-md-3 > * {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }

  .row-cols-md-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .row-cols-md-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }

  .row-cols-md-6 > * {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }

  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }

  .col-md-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }

  .col-md-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }

  .col-md-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col-md-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }

  .col-md-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }

  .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col-md-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }

  .col-md-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }

  .col-md-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }

  .col-md-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }

  .col-md-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }

  .col-md-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .order-md-first {
    order: -1;
  }

  .order-md-last {
    order: 13;
  }

  .order-md-0 {
    order: 0;
  }

  .order-md-1 {
    order: 1;
  }

  .order-md-2 {
    order: 2;
  }

  .order-md-3 {
    order: 3;
  }

  .order-md-4 {
    order: 4;
  }

  .order-md-5 {
    order: 5;
  }

  .order-md-6 {
    order: 6;
  }

  .order-md-7 {
    order: 7;
  }

  .order-md-8 {
    order: 8;
  }

  .order-md-9 {
    order: 9;
  }

  .order-md-10 {
    order: 10;
  }

  .order-md-11 {
    order: 11;
  }

  .order-md-12 {
    order: 12;
  }

  .offset-md-0 {
    margin-left: 0;
  }

  .offset-md-1 {
    margin-left: 8.3333333333%;
  }

  .offset-md-2 {
    margin-left: 16.6666666667%;
  }

  .offset-md-3 {
    margin-left: 25%;
  }

  .offset-md-4 {
    margin-left: 33.3333333333%;
  }

  .offset-md-5 {
    margin-left: 41.6666666667%;
  }

  .offset-md-6 {
    margin-left: 50%;
  }

  .offset-md-7 {
    margin-left: 58.3333333333%;
  }

  .offset-md-8 {
    margin-left: 66.6666666667%;
  }

  .offset-md-9 {
    margin-left: 75%;
  }

  .offset-md-10 {
    margin-left: 83.3333333333%;
  }

  .offset-md-11 {
    margin-left: 91.6666666667%;
  }
}
@media (min-width: 992px) {
  .col-lg {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }

  .row-cols-lg-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .row-cols-lg-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .row-cols-lg-3 > * {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }

  .row-cols-lg-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .row-cols-lg-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }

  .row-cols-lg-6 > * {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }

  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }

  .col-lg-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }

  .col-lg-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }

  .col-lg-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col-lg-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }

  .col-lg-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }

  .col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col-lg-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }

  .col-lg-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }

  .col-lg-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }

  .col-lg-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }

  .col-lg-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }

  .col-lg-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .order-lg-first {
    order: -1;
  }

  .order-lg-last {
    order: 13;
  }

  .order-lg-0 {
    order: 0;
  }

  .order-lg-1 {
    order: 1;
  }

  .order-lg-2 {
    order: 2;
  }

  .order-lg-3 {
    order: 3;
  }

  .order-lg-4 {
    order: 4;
  }

  .order-lg-5 {
    order: 5;
  }

  .order-lg-6 {
    order: 6;
  }

  .order-lg-7 {
    order: 7;
  }

  .order-lg-8 {
    order: 8;
  }

  .order-lg-9 {
    order: 9;
  }

  .order-lg-10 {
    order: 10;
  }

  .order-lg-11 {
    order: 11;
  }

  .order-lg-12 {
    order: 12;
  }

  .offset-lg-0 {
    margin-left: 0;
  }

  .offset-lg-1 {
    margin-left: 8.3333333333%;
  }

  .offset-lg-2 {
    margin-left: 16.6666666667%;
  }

  .offset-lg-3 {
    margin-left: 25%;
  }

  .offset-lg-4 {
    margin-left: 33.3333333333%;
  }

  .offset-lg-5 {
    margin-left: 41.6666666667%;
  }

  .offset-lg-6 {
    margin-left: 50%;
  }

  .offset-lg-7 {
    margin-left: 58.3333333333%;
  }

  .offset-lg-8 {
    margin-left: 66.6666666667%;
  }

  .offset-lg-9 {
    margin-left: 75%;
  }

  .offset-lg-10 {
    margin-left: 83.3333333333%;
  }

  .offset-lg-11 {
    margin-left: 91.6666666667%;
  }
}
@media (min-width: 1200px) {
  .col-xl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }

  .row-cols-xl-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .row-cols-xl-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .row-cols-xl-3 > * {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }

  .row-cols-xl-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .row-cols-xl-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }

  .row-cols-xl-6 > * {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }

  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }

  .col-xl-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }

  .col-xl-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }

  .col-xl-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col-xl-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }

  .col-xl-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }

  .col-xl-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col-xl-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }

  .col-xl-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }

  .col-xl-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }

  .col-xl-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }

  .col-xl-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }

  .col-xl-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .order-xl-first {
    order: -1;
  }

  .order-xl-last {
    order: 13;
  }

  .order-xl-0 {
    order: 0;
  }

  .order-xl-1 {
    order: 1;
  }

  .order-xl-2 {
    order: 2;
  }

  .order-xl-3 {
    order: 3;
  }

  .order-xl-4 {
    order: 4;
  }

  .order-xl-5 {
    order: 5;
  }

  .order-xl-6 {
    order: 6;
  }

  .order-xl-7 {
    order: 7;
  }

  .order-xl-8 {
    order: 8;
  }

  .order-xl-9 {
    order: 9;
  }

  .order-xl-10 {
    order: 10;
  }

  .order-xl-11 {
    order: 11;
  }

  .order-xl-12 {
    order: 12;
  }

  .offset-xl-0 {
    margin-left: 0;
  }

  .offset-xl-1 {
    margin-left: 8.3333333333%;
  }

  .offset-xl-2 {
    margin-left: 16.6666666667%;
  }

  .offset-xl-3 {
    margin-left: 25%;
  }

  .offset-xl-4 {
    margin-left: 33.3333333333%;
  }

  .offset-xl-5 {
    margin-left: 41.6666666667%;
  }

  .offset-xl-6 {
    margin-left: 50%;
  }

  .offset-xl-7 {
    margin-left: 58.3333333333%;
  }

  .offset-xl-8 {
    margin-left: 66.6666666667%;
  }

  .offset-xl-9 {
    margin-left: 75%;
  }

  .offset-xl-10 {
    margin-left: 83.3333333333%;
  }

  .offset-xl-11 {
    margin-left: 91.6666666667%;
  }
}
.table {
  width: 100%;
  margin-bottom: 1rem;
  color: #2d373c;
}
.table th,
.table td {
  padding: 0.75rem;
  vertical-align: top;
  border-top: 1px solid #dee2e6;
}
.table thead th {
  vertical-align: bottom;
  border-bottom: 2px solid #dee2e6;
}
.table tbody + tbody {
  border-top: 2px solid #dee2e6;
}

.table-sm th,
.table-sm td {
  padding: 0.3rem;
}

.table-bordered {
  border: 1px solid #dee2e6;
}
.table-bordered th,
.table-bordered td {
  border: 1px solid #dee2e6;
}
.table-bordered thead th,
.table-bordered thead td {
  border-bottom-width: 2px;
}

.table-borderless th,
.table-borderless td,
.table-borderless thead th,
.table-borderless tbody + tbody {
  border: 0;
}

.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.05);
}

.table-hover tbody tr:hover {
  color: #2d373c;
  background-color: rgba(0, 0, 0, 0.075);
}

.table-primary,
.table-primary > th,
.table-primary > td {
  background-color: #fee6ba;
}
.table-primary th,
.table-primary td,
.table-primary thead th,
.table-primary tbody + tbody {
  border-color: #fcd080;
}

.table-hover .table-primary:hover {
  background-color: #fedda1;
}
.table-hover .table-primary:hover > td,
.table-hover .table-primary:hover > th {
  background-color: #fedda1;
}

.table-secondary,
.table-secondary > th,
.table-secondary > td {
  background-color: #c1dde7;
}
.table-secondary th,
.table-secondary td,
.table-secondary thead th,
.table-secondary tbody + tbody {
  border-color: #8dc1d3;
}

.table-hover .table-secondary:hover {
  background-color: #afd3e0;
}
.table-hover .table-secondary:hover > td,
.table-hover .table-secondary:hover > th {
  background-color: #afd3e0;
}

.table-success,
.table-success > th,
.table-success > td {
  background-color: #bae3b8;
}
.table-success th,
.table-success td,
.table-success thead th,
.table-success tbody + tbody {
  border-color: #7fcb7a;
}

.table-hover .table-success:hover {
  background-color: #a8dca6;
}
.table-hover .table-success:hover > td,
.table-hover .table-success:hover > th {
  background-color: #a8dca6;
}

.table-info,
.table-info > th,
.table-info > td {
  background-color: #d5d9dc;
}
.table-info th,
.table-info td,
.table-info thead th,
.table-info tbody + tbody {
  border-color: #b1b9be;
}

.table-hover .table-info:hover {
  background-color: #c7ccd0;
}
.table-hover .table-info:hover > td,
.table-hover .table-info:hover > th {
  background-color: #c7ccd0;
}

.table-warning,
.table-warning > th,
.table-warning > td {
  background-color: #f9d9ba;
}
.table-warning th,
.table-warning td,
.table-warning thead th,
.table-warning tbody + tbody {
  border-color: #f5b980;
}

.table-hover .table-warning:hover {
  background-color: #f7cca3;
}
.table-hover .table-warning:hover > td,
.table-hover .table-warning:hover > th {
  background-color: #f7cca3;
}

.table-danger,
.table-danger > th,
.table-danger > td {
  background-color: #f5b8b8;
}
.table-danger th,
.table-danger td,
.table-danger thead th,
.table-danger tbody + tbody {
  border-color: #ed7a7a;
}

.table-hover .table-danger:hover {
  background-color: #f2a2a2;
}
.table-hover .table-danger:hover > td,
.table-hover .table-danger:hover > th {
  background-color: #f2a2a2;
}

.table-light,
.table-light > th,
.table-light > td {
  background-color: #ffebb8;
}
.table-light th,
.table-light td,
.table-light thead th,
.table-light tbody + tbody {
  border-color: #ffdb7a;
}

.table-hover .table-light:hover {
  background-color: #ffe49f;
}
.table-hover .table-light:hover > td,
.table-hover .table-light:hover > th {
  background-color: #ffe49f;
}

.table-dark,
.table-dark > th,
.table-dark > td {
  background-color: #f9d9ba;
}
.table-dark th,
.table-dark td,
.table-dark thead th,
.table-dark tbody + tbody {
  border-color: #f5b980;
}

.table-hover .table-dark:hover {
  background-color: #f7cca3;
}
.table-hover .table-dark:hover > td,
.table-hover .table-dark:hover > th {
  background-color: #f7cca3;
}

.table-inactive,
.table-inactive > th,
.table-inactive > td {
  background-color: #dfdfe1;
}
.table-inactive th,
.table-inactive td,
.table-inactive thead th,
.table-inactive tbody + tbody {
  border-color: #c3c3c8;
}

.table-hover .table-inactive:hover {
  background-color: #d2d2d5;
}
.table-hover .table-inactive:hover > td,
.table-hover .table-inactive:hover > th {
  background-color: #d2d2d5;
}

.table-active,
.table-active > th,
.table-active > td {
  background-color: rgba(0, 0, 0, 0.075);
}

.table-hover .table-active:hover {
  background-color: rgba(0, 0, 0, 0.075);
}
.table-hover .table-active:hover > td,
.table-hover .table-active:hover > th {
  background-color: rgba(0, 0, 0, 0.075);
}

.table .thead-dark th {
  color: #fff;
  background-color: #343a40;
  border-color: #454d55;
}
.table .thead-light th {
  color: #495057;
  background-color: #e9ecef;
  border-color: #dee2e6;
}

.table-dark {
  color: #fff;
  background-color: #343a40;
}
.table-dark th,
.table-dark td,
.table-dark thead th {
  border-color: #454d55;
}
.table-dark.table-bordered {
  border: 0;
}
.table-dark.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(255, 255, 255, 0.05);
}
.table-dark.table-hover tbody tr:hover {
  color: #fff;
  background-color: rgba(255, 255, 255, 0.075);
}

@media (max-width: 575.98px) {
  .table-responsive-sm {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-sm > .table-bordered {
    border: 0;
  }
}
@media (max-width: 767.98px) {
  .table-responsive-md {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-md > .table-bordered {
    border: 0;
  }
}
@media (max-width: 991.98px) {
  .table-responsive-lg {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-lg > .table-bordered {
    border: 0;
  }
}
@media (max-width: 1199.98px) {
  .table-responsive-xl {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-xl > .table-bordered {
    border: 0;
  }
}
.table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}
.table-responsive > .table-bordered {
  border: 0;
}

th {
  font-weight: 500;
}

.table {
  border-collapse: separate;
  font-size: 12px;
}
.table > thead > tr > th,
.table > thead > tr > td {
  filter: progid:DXImageTransform.Microsoft.gradient(enabled = false);
  background-color: #ebf0f5;
  border-width: 0 1px 0 1px;
  border-style: solid;
  border-color: #fff;
  font-weight: 500;
}
.table > thead > tr > th {
  white-space: nowrap;
  position: relative;
  position: sticky;
  top: 0;
  line-height: 0;
  z-index: 3;
  padding: 10px 10px 0;
}
.table > thead > tr > th:first-child {
  border-left: 0;
}
.table > thead > tr > th:last-child {
  border-right: 0;
}
.table > thead > tr > th .form-group {
  width: 100%;
  height: 100%;
  margin-top: 0;
  margin-bottom: 0;
}
.table > thead > tr > th .form-group label {
  top: 0;
  left: 0;
  right: 40px;
  cursor: auto;
  color: #2d373c;
  white-space: pre-wrap;
  line-height: 16px;
  font-weight: 500;
}
.table > thead > tr > th .form-group label.selected {
  font-size: 9px;
  top: -5px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.table > thead > tr > th .form-group .form-control {
  background: none;
  border: 0;
  box-shadow: none;
  margin-right: 40px;
  height: 40px;
  font-weight: normal;
  padding: 10px 0 0;
  text-align: left;
}
.table > thead > tr > th:not(.sort) .form-group label,
.table > thead > tr > th :not(.filter) .form-group label {
  right: 25px;
}
.table > thead > tr > th:not(.sort) .form-group .form-control,
.table > thead > tr > th :not(.filter) .form-group .form-control {
  margin-right: 25px;
}
.table > thead > tr > th:not(.sort.filter) .form-group label {
  right: 0;
}
.table > thead > tr > th .action-item-group {
  position: absolute;
  right: 10px;
  top: 10px;
}
.table > thead > tr > th .action-item-group .action {
  padding: 0;
  line-height: 10px;
  border: 0;
  min-width: 18px;
  min-height: 12px;
}
.table > thead > tr > th .action-item-group .action:focus {
  outline: none;
  box-shadow: none;
}
.table > thead > tr > th .action-item-group .action.toggle-sort {
  text-align: left;
}
.table > thead > tr > th .action-item-group .action.toggle-sort span.desc {
  margin-left: -6px;
  margin-top: -2px;
}
.table > thead > tr > th .action-item-group .action.toggle-sort.desc span.asc {
  display: none;
}
.table > thead > tr > th .action-item-group .action.toggle-sort.desc span.desc {
  margin-left: 0;
}
.table > thead > tr > th .action-item-group .action.toggle-sort.asc span.desc {
  display: none;
}
.table > thead > tr > th:hover {
  background-color: #dfe6ed;
}
.table > thead > tr > th.sort p.ps, .table > thead > tr > th.sort .ps, .table > thead > tr > th.sort .psd {
  margin-bottom: 6px;
}
.table > thead > tr > td:first-child {
  border: 0;
}
.table > thead.glyphicon {
  float: right;
}
.table > caption + thead > tr:first-child > th,
.table > caption + thead > tr:first-child > td,
.table > colgroup + thead > tr:first-child > th,
.table > colgroup + thead > tr:first-child > td,
.table > thead:first-child > tr:first-child > th,
.table > thead:first-child > tr:first-child > td {
  border-top: 1px solid transparent;
}
.table > tbody > tr > td {
  vertical-align: inherit;
  word-wrap: break-word;
}
.table > tbody > tr:first-child > td {
  border-top: 4px solid #dfe6ed;
}

.table-bordered {
  border: 0;
}

.form-control {
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
  color: #2d373c;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-control {
    transition: none;
  }
}
.form-control::-ms-expand {
  background-color: transparent;
  border: 0;
}
.form-control:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #2d373c;
}
.form-control:focus {
  color: #2d373c;
  background-color: #fff;
  border-color: #fdd387;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(250, 165, 10, 0.25);
}
.form-control::placeholder {
  color: #6c757d;
  opacity: 1;
}
.form-control:disabled, .form-control[readonly] {
  background-color: #e9ecef;
  opacity: 1;
}

input[type=date].form-control,
input[type=time].form-control,
input[type=datetime-local].form-control,
input[type=month].form-control {
  appearance: none;
}

select.form-control:focus::-ms-value {
  color: #2d373c;
  background-color: #fff;
}

.form-control-file,
.form-control-range {
  display: block;
  width: 100%;
}

.col-form-label {
  padding-top: calc(0.375rem + 1px);
  padding-bottom: calc(0.375rem + 1px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.5;
}

.col-form-label-lg {
  padding-top: calc(0.5rem + 1px);
  padding-bottom: calc(0.5rem + 1px);
  font-size: 1.09375rem;
  line-height: 1.5;
}

.col-form-label-sm {
  padding-top: calc(0.25rem + 1px);
  padding-bottom: calc(0.25rem + 1px);
  font-size: 0.765625rem;
  line-height: 1.5;
}

.form-control-plaintext {
  display: block;
  width: 100%;
  padding: 0.375rem 0;
  margin-bottom: 0;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #2d373c;
  background-color: transparent;
  border: solid transparent;
  border-width: 1px 0;
}
.form-control-plaintext.form-control-sm, .form-control-plaintext.form-control-lg {
  padding-right: 0;
  padding-left: 0;
}

.form-control-sm {
  height: calc(1.5em + 0.5rem + 2px);
  padding: 0.25rem 0.5rem;
  font-size: 0.765625rem;
  line-height: 1.5;
  border-radius: 0;
}

.form-control-lg {
  height: calc(1.5em + 1rem + 2px);
  padding: 0.5rem 1rem;
  font-size: 1.09375rem;
  line-height: 1.5;
  border-radius: 0;
}

select.form-control[size], select.form-control[multiple] {
  height: auto;
}

textarea.form-control {
  height: auto;
}

.form-group {
  margin-bottom: 20px;
}

.form-text {
  display: block;
  margin-top: 0.25rem;
}

.form-row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px;
}
.form-row > .col,
.form-row > [class*=col-] {
  padding-right: 5px;
  padding-left: 5px;
}

.form-check {
  position: relative;
  display: block;
  padding-left: 0;
}

.form-check-input {
  position: absolute;
  margin-top: 0.3rem;
  margin-left: 0;
}
.form-check-input[disabled] ~ .form-check-label, .form-check-input:disabled ~ .form-check-label {
  color: #6c757d;
}

.form-check-label {
  margin-bottom: 0;
}

.form-check-inline {
  display: inline-flex;
  align-items: center;
  padding-left: 0;
  margin-right: 0.75rem;
}
.form-check-inline .form-check-input {
  position: static;
  margin-top: 0;
  margin-right: 0.3125rem;
  margin-left: 0;
}

.was-validated :valid ~ .form-feedback .valid-feedback,
.is-valid ~ .form-feedback .valid-feedback {
  display: block;
}

.valid-feedback {
  font-size: 0.6875rem;
  display: none;
  padding: 2px 15px;
  background-color: #099b00;
  position: absolute;
  z-index: 2;
}

.was-validated .form-control:valid, .form-control.is-valid {
  border-color: #099b00;
}
.was-validated .form-control:valid:focus, .form-control.is-valid:focus {
  border-color: #099b00;
  box-shadow: 0 0 0 0.2rem rgba(9, 155, 0, 0.25);
}

.was-validated .custom-select:valid, .custom-select.is-valid {
  border-color: #099b00;
}
.was-validated .custom-select:valid:focus, .custom-select.is-valid:focus {
  border-color: #099b00;
  box-shadow: 0 0 0 0.2rem rgba(9, 155, 0, 0.25);
}

.was-validated .form-check-input:valid ~ .form-check-label, .form-check-input.is-valid ~ .form-check-label {
  color: #099b00;
}
.was-validated .form-check-input:valid ~ .valid-feedback,
.was-validated .form-check-input:valid ~ .valid-tooltip, .form-check-input.is-valid ~ .valid-feedback,
.form-check-input.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .custom-control-input:valid ~ .custom-control-label, .custom-control-input.is-valid ~ .custom-control-label {
  color: #099b00;
}
.was-validated .custom-control-input:valid ~ .custom-control-label::before, .custom-control-input.is-valid ~ .custom-control-label::before {
  border-color: #099b00;
}
.was-validated .custom-control-input:valid:checked ~ .custom-control-label::before, .custom-control-input.is-valid:checked ~ .custom-control-label::before {
  background-color: #0cce00;
  border-color: #0cce00;
}
.was-validated .custom-control-input:valid:focus ~ .custom-control-label::before, .custom-control-input.is-valid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(9, 155, 0, 0.25);
}
.was-validated .custom-control-input:valid:focus:not(:checked) ~ .custom-control-label::before, .custom-control-input.is-valid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #099b00;
}

.was-validated .custom-file-input:valid ~ .custom-file-label, .custom-file-input.is-valid ~ .custom-file-label {
  border-color: #099b00;
}
.was-validated .custom-file-input:valid:focus ~ .custom-file-label, .custom-file-input.is-valid:focus ~ .custom-file-label {
  border-color: #099b00;
  box-shadow: 0 0 0 0.2rem rgba(9, 155, 0, 0.25);
}

.was-validated :invalid ~ .form-feedback .invalid-feedback,
.is-invalid ~ .form-feedback .invalid-feedback {
  display: block;
}

.invalid-feedback {
  font-size: 0.6875rem;
  display: none;
  padding: 2px 15px;
  background-color: #dc0000;
  color: #fff;
  position: absolute;
  z-index: 2;
}

.was-validated .form-control:invalid, .form-control.is-invalid {
  border-color: #dc0000;
}
.was-validated .form-control:invalid:focus, .form-control.is-invalid:focus {
  border-color: #dc0000;
  box-shadow: 0 0 0 0.2rem rgba(220, 0, 0, 0.25);
}

.was-validated .custom-select:invalid, .custom-select.is-invalid {
  border-color: #dc0000;
}
.was-validated .custom-select:invalid:focus, .custom-select.is-invalid:focus {
  border-color: #dc0000;
  box-shadow: 0 0 0 0.2rem rgba(220, 0, 0, 0.25);
}

.was-validated .form-check-input:invalid ~ .form-check-label, .form-check-input.is-invalid ~ .form-check-label {
  color: #dc0000;
}
.was-validated .form-check-input:invalid ~ .invalid-feedback,
.was-validated .form-check-input:invalid ~ .invalid-tooltip, .form-check-input.is-invalid ~ .invalid-feedback,
.form-check-input.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .custom-control-input:invalid ~ .custom-control-label, .custom-control-input.is-invalid ~ .custom-control-label {
  color: #dc0000;
}
.was-validated .custom-control-input:invalid ~ .custom-control-label::before, .custom-control-input.is-invalid ~ .custom-control-label::before {
  border-color: #dc0000;
}
.was-validated .custom-control-input:invalid:checked ~ .custom-control-label::before, .custom-control-input.is-invalid:checked ~ .custom-control-label::before {
  background-color: #ff1010;
  border-color: #ff1010;
}
.was-validated .custom-control-input:invalid:focus ~ .custom-control-label::before, .custom-control-input.is-invalid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(220, 0, 0, 0.25);
}
.was-validated .custom-control-input:invalid:focus:not(:checked) ~ .custom-control-label::before, .custom-control-input.is-invalid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #dc0000;
}

.was-validated .custom-file-input:invalid ~ .custom-file-label, .custom-file-input.is-invalid ~ .custom-file-label {
  border-color: #dc0000;
}
.was-validated .custom-file-input:invalid:focus ~ .custom-file-label, .custom-file-input.is-invalid:focus ~ .custom-file-label {
  border-color: #dc0000;
  box-shadow: 0 0 0 0.2rem rgba(220, 0, 0, 0.25);
}

.form-inline {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
}
.form-inline .form-check {
  width: 100%;
}
@media (min-width: 576px) {
  .form-inline label {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 0;
  }
  .form-inline .form-group {
    display: flex;
    flex: 0 0 auto;
    flex-flow: row wrap;
    align-items: center;
    margin-bottom: 0;
  }
  .form-inline .form-control {
    display: inline-block;
    width: auto;
    vertical-align: middle;
  }
  .form-inline .form-control-plaintext {
    display: inline-block;
  }
  .form-inline .input-group,
.form-inline .custom-select {
    width: auto;
  }
  .form-inline .form-check {
    display: flex;
    align-items: center;
    justify-content: center;
    width: auto;
    padding-left: 0;
  }
  .form-inline .form-check-input {
    position: relative;
    flex-shrink: 0;
    margin-top: 0;
    margin-right: 0.25rem;
    margin-left: 0;
  }
  .form-inline .custom-control {
    align-items: center;
    justify-content: center;
  }
  .form-inline .custom-control-label {
    margin-bottom: 0;
  }
}

legend {
  font-size: 1.3125rem;
  font-weight: normal;
}

label {
  margin-bottom: 2px;
  font-weight: normal;
  color: inherit;
}

.form-check:not(.form-check-inline) {
  display: flex;
  align-items: center;
  margin: 3px 0;
}

.form-header {
  text-align: center;
}
.form-control {
  border-top: 1px solid #879baa;
  padding: 15px 15px;
  height: 50px;
}
.form-control:invalid:not(:focus) {
  color: #dc0000;
}
.form-control:invalid:not(:focus) + .form-error-msg {
  display: block;
}
.form-control:focus {
  box-shadow: inherit;
  border-color: #005578;
}

.form-control.dropdown-toggle {
  border-top-color: #cdd9e1;
  border-bottom-color: #879baa;
}
.form-control.dropdown-toggle:focus {
  border-color: #005578;
}

.form-group {
  position: relative;
}
.form-group .dropdown-toggle::after {
  content: none;
}
.form-group > .form-control,
.form-group .input-group > .form-control {
  padding-top: 22px;
  padding-bottom: 8px;
}
.form-group > .form-control:disabled,
.form-group .input-group > .form-control:disabled {
  background-color: #dfe6ed;
  border-color: #cdd9e1;
  border-top-color: #879baa;
  color: #879baa;
}

.form-group > label {
  position: absolute;
  left: 15px;
  top: 15px;
  color: #788791;
  z-index: 4;
  transition: top 0.1s, left 0.1s, font-size 0.1s linear 0s;
  pointer-events: none;
}

.form-group.form-group-dropdown .form-group-dropdown-value {
  background: transparent;
  border: 0;
  color: #788791;
  left: 15px;
  margin: 0;
  outline: none;
  padding: 0;
  position: absolute;
  top: 20px;
  transition: top 0.1s, left 0.1s, font-size 0.1s linear 0s;
  z-index: 1;
}

.form-group > label.selected {
  top: 8px;
  left: 16px;
  font-size: 9px;
}

.form-group > label.disabled {
  color: #879baa;
}

.form-group select {
  padding-left: 11px;
}

.form-group .dropdown-toggle {
  text-align: left;
  padding-top: 22px;
  padding-bottom: 8px;
  padding-right: 30px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.icon-dropdown {
  position: absolute;
  right: 17px;
  top: 17px;
  color: #788791;
}
.icon-dropdown::before {
  font-family: "simpl-icons-newton";
  content: "";
}

.form-group.open .icon-dropdown::before {
  font-family: "simpl-icons-newton";
  content: "";
}

button + .dropdown-menu,
.form-group .dropdown-menu {
  width: 100%;
  padding: 0;
  margin-top: -1px;
  border-color: #cdd9e1;
}
button + .dropdown-menu > a:not(.dropdown-divider),
button + .dropdown-menu .dropdown-item,
.form-group .dropdown-menu > a:not(.dropdown-divider),
.form-group .dropdown-menu .dropdown-item {
  padding: 5px 15px;
  height: 31px;
}
button + .dropdown-menu > a:not(.dropdown-divider):hover,
button + .dropdown-menu .dropdown-item:hover,
.form-group .dropdown-menu > a:not(.dropdown-divider):hover,
.form-group .dropdown-menu .dropdown-item:hover {
  padding-top: 3px;
  background-color: #005578;
  cursor: pointer;
  color: #fff;
}
button + .dropdown-menu > a:not(.dropdown-divider):hover::before,
button + .dropdown-menu .dropdown-item:hover::before,
.form-group .dropdown-menu > a:not(.dropdown-divider):hover::before,
.form-group .dropdown-menu .dropdown-item:hover::before {
  padding-right: 5px;
  font-family: "simpl-icons-newton";
  content: "";
  position: relative;
  top: 2px;
}

button + .dropdown-menu > a:not(.dropdown-divider),
button + .dropdown-menu .dropdown-item {
  padding-right: 29px;
}
button + .dropdown-menu > a:not(.dropdown-divider):hover,
button + .dropdown-menu .dropdown-item:hover {
  padding-right: 15px;
}

.open > .btn-secondary.dropdown-toggle {
  background-color: #003145;
  border-color: #003145;
  color: #fff;
}
.open > .btn-secondary.dropdown-toggle:hover, .open > .btn-secondary.dropdown-toggle:focus {
  background-color: #003145;
  border-color: #003145;
  color: #fff;
}

input[type=checkbox] {
  appearance: none;
  background-color: #fff;
  border: 1px solid #879baa;
  width: 21px;
  height: 21px;
  padding-right: 9px;
  border-radius: 0;
  display: inline-block;
  position: relative !important;
  margin: 1px 8px 0 0;
  cursor: pointer;
}
input[type=checkbox]:checked {
  border: 1px solid #005578;
}
input[type=checkbox]:checked::after {
  font-family: "simpl-icons-newton";
  content: "";
  font-size: 14px;
  position: absolute;
  left: 3px;
  color: #005578;
}
input[type=checkbox]:indeterminate::after {
  font-family: "simpl-icons-newton";
  content: "";
  position: absolute;
  left: 3px;
  color: #788791;
}
input[type=checkbox]:disabled {
  color: #879baa;
  background-color: #dfe6ed;
  border: 1px solid #cdd9e1;
}
input[type=checkbox]:disabled::after {
  color: #879baa;
}

label input[type=checkbox] {
  margin-bottom: -5px;
}

input[type=radio] {
  appearance: none;
  background-color: #fff;
  border: 1px solid #879baa;
  border-radius: 100%;
  width: 20px;
  height: 20px;
  display: inline-block;
  position: relative !important;
  margin-right: 8px;
  margin-top: 0;
  cursor: pointer;
}
input[type=radio]:checked {
  border: 1px solid #005578;
}
input[type=radio]:checked::after {
  content: " ";
  width: 14px;
  height: 14px;
  border-radius: 50px;
  position: absolute;
  top: 2px;
  background: #005578;
  box-shadow: inset 0 0 10px rgba(0, 0, 0, 0.3);
  text-shadow: none;
  left: 2px;
  font-size: 32px;
}
input[type=radio]:disabled {
  color: #879baa;
  background-color: #dfe6ed;
  border: 1px solid #cdd9e1;
}
input[type=radio]:disabled::after {
  background: #879baa;
}

label input[type=radio] {
  margin-top: 1px;
  margin-bottom: -6px;
}

.btn {
  display: inline-block;
  font-weight: middle;
  color: #2d373c;
  text-align: center;
  vertical-align: middle;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .btn {
    transition: none;
  }
}
.btn:hover {
  color: #2d373c;
  text-decoration: none;
}
.btn:focus, .btn.focus {
  outline: 0;
  box-shadow: none;
}
.btn.disabled, .btn:disabled {
  opacity: 0.65;
}
.btn:not(:disabled):not(.disabled) {
  cursor: pointer;
}
a.btn.disabled,
fieldset:disabled a.btn {
  pointer-events: none;
}

.btn-primary {
  color: #fff;
  background-color: #faa50a;
  border-color: #faa50a;
}
.btn-primary:hover {
  color: #fff;
  background-color: #d98e04;
  border-color: #cd8604;
}
.btn-primary:focus, .btn-primary.focus {
  color: #fff;
  background-color: #d98e04;
  border-color: #cd8604;
  box-shadow: 0 0 0 0 rgba(251, 179, 47, 0.5);
}
.btn-primary.disabled, .btn-primary:disabled {
  color: #fff;
  background-color: #faa50a;
  border-color: #faa50a;
}
.btn-primary:not(:disabled):not(.disabled):active, .btn-primary:not(:disabled):not(.disabled).active, .show > .btn-primary.dropdown-toggle {
  color: #fff;
  background-color: #cd8604;
  border-color: #c07e04;
}
.btn-primary:not(:disabled):not(.disabled):active:focus, .btn-primary:not(:disabled):not(.disabled).active:focus, .show > .btn-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0 rgba(251, 179, 47, 0.5);
}

.btn-secondary {
  color: #fff;
  background-color: #2387aa;
  border-color: #2387aa;
}
.btn-secondary:hover {
  color: #fff;
  background-color: #1c6e8a;
  border-color: #1a6580;
}
.btn-secondary:focus, .btn-secondary.focus {
  color: #fff;
  background-color: #1c6e8a;
  border-color: #1a6580;
  box-shadow: 0 0 0 0 rgba(68, 153, 183, 0.5);
}
.btn-secondary.disabled, .btn-secondary:disabled {
  color: #fff;
  background-color: #2387aa;
  border-color: #2387aa;
}
.btn-secondary:not(:disabled):not(.disabled):active, .btn-secondary:not(:disabled):not(.disabled).active, .show > .btn-secondary.dropdown-toggle {
  color: #fff;
  background-color: #1a6580;
  border-color: #185d75;
}
.btn-secondary:not(:disabled):not(.disabled):active:focus, .btn-secondary:not(:disabled):not(.disabled).active:focus, .show > .btn-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0 rgba(68, 153, 183, 0.5);
}

.btn-success {
  color: #fff;
  background-color: #099b00;
  border-color: #099b00;
}
.btn-success:hover {
  color: #fff;
  background-color: #077500;
  border-color: #066800;
}
.btn-success:focus, .btn-success.focus {
  color: #fff;
  background-color: #077500;
  border-color: #066800;
  box-shadow: 0 0 0 0 rgba(46, 170, 38, 0.5);
}
.btn-success.disabled, .btn-success:disabled {
  color: #fff;
  background-color: #099b00;
  border-color: #099b00;
}
.btn-success:not(:disabled):not(.disabled):active, .btn-success:not(:disabled):not(.disabled).active, .show > .btn-success.dropdown-toggle {
  color: #fff;
  background-color: #066800;
  border-color: #055b00;
}
.btn-success:not(:disabled):not(.disabled):active:focus, .btn-success:not(:disabled):not(.disabled).active:focus, .show > .btn-success.dropdown-toggle:focus {
  box-shadow: 0 0 0 0 rgba(46, 170, 38, 0.5);
}

.btn-info {
  color: #fff;
  background-color: #697882;
  border-color: #697882;
}
.btn-info:hover {
  color: #fff;
  background-color: #58646d;
  border-color: #525e66;
}
.btn-info:focus, .btn-info.focus {
  color: #fff;
  background-color: #58646d;
  border-color: #525e66;
  box-shadow: 0 0 0 0 rgba(128, 140, 149, 0.5);
}
.btn-info.disabled, .btn-info:disabled {
  color: #fff;
  background-color: #697882;
  border-color: #697882;
}
.btn-info:not(:disabled):not(.disabled):active, .btn-info:not(:disabled):not(.disabled).active, .show > .btn-info.dropdown-toggle {
  color: #fff;
  background-color: #525e66;
  border-color: #4d575f;
}
.btn-info:not(:disabled):not(.disabled):active:focus, .btn-info:not(:disabled):not(.disabled).active:focus, .show > .btn-info.dropdown-toggle:focus {
  box-shadow: 0 0 0 0 rgba(128, 140, 149, 0.5);
}

.btn-warning {
  color: #fff;
  background-color: #eb780a;
  border-color: #eb780a;
}
.btn-warning:hover {
  color: #fff;
  background-color: #c66508;
  border-color: #ba5f08;
}
.btn-warning:focus, .btn-warning.focus {
  color: #fff;
  background-color: #c66508;
  border-color: #ba5f08;
  box-shadow: 0 0 0 0 rgba(238, 140, 47, 0.5);
}
.btn-warning.disabled, .btn-warning:disabled {
  color: #fff;
  background-color: #eb780a;
  border-color: #eb780a;
}
.btn-warning:not(:disabled):not(.disabled):active, .btn-warning:not(:disabled):not(.disabled).active, .show > .btn-warning.dropdown-toggle {
  color: #fff;
  background-color: #ba5f08;
  border-color: #ae5907;
}
.btn-warning:not(:disabled):not(.disabled):active:focus, .btn-warning:not(:disabled):not(.disabled).active:focus, .show > .btn-warning.dropdown-toggle:focus {
  box-shadow: 0 0 0 0 rgba(238, 140, 47, 0.5);
}

.btn-danger {
  color: #fff;
  background-color: #dc0000;
  border-color: #dc0000;
}
.btn-danger:hover {
  color: #fff;
  background-color: #b60000;
  border-color: #a90000;
}
.btn-danger:focus, .btn-danger.focus {
  color: #fff;
  background-color: #b60000;
  border-color: #a90000;
  box-shadow: 0 0 0 0 rgba(225, 38, 38, 0.5);
}
.btn-danger.disabled, .btn-danger:disabled {
  color: #fff;
  background-color: #dc0000;
  border-color: #dc0000;
}
.btn-danger:not(:disabled):not(.disabled):active, .btn-danger:not(:disabled):not(.disabled).active, .show > .btn-danger.dropdown-toggle {
  color: #fff;
  background-color: #a90000;
  border-color: #9c0000;
}
.btn-danger:not(:disabled):not(.disabled):active:focus, .btn-danger:not(:disabled):not(.disabled).active:focus, .show > .btn-danger.dropdown-toggle:focus {
  box-shadow: 0 0 0 0 rgba(225, 38, 38, 0.5);
}

.btn-light {
  color: #fff;
  background-color: #ffb900;
  border-color: #ffb900;
}
.btn-light:hover {
  color: #fff;
  background-color: #d99d00;
  border-color: #cc9400;
}
.btn-light:focus, .btn-light.focus {
  color: #fff;
  background-color: #d99d00;
  border-color: #cc9400;
  box-shadow: 0 0 0 0 rgba(255, 196, 38, 0.5);
}
.btn-light.disabled, .btn-light:disabled {
  color: #fff;
  background-color: #ffb900;
  border-color: #ffb900;
}
.btn-light:not(:disabled):not(.disabled):active, .btn-light:not(:disabled):not(.disabled).active, .show > .btn-light.dropdown-toggle {
  color: #fff;
  background-color: #cc9400;
  border-color: #bf8b00;
}
.btn-light:not(:disabled):not(.disabled):active:focus, .btn-light:not(:disabled):not(.disabled).active:focus, .show > .btn-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 0 rgba(255, 196, 38, 0.5);
}

.btn-dark {
  color: #fff;
  background-color: #eb780a;
  border-color: #eb780a;
}
.btn-dark:hover {
  color: #fff;
  background-color: #c66508;
  border-color: #ba5f08;
}
.btn-dark:focus, .btn-dark.focus {
  color: #fff;
  background-color: #c66508;
  border-color: #ba5f08;
  box-shadow: 0 0 0 0 rgba(238, 140, 47, 0.5);
}
.btn-dark.disabled, .btn-dark:disabled {
  color: #fff;
  background-color: #eb780a;
  border-color: #eb780a;
}
.btn-dark:not(:disabled):not(.disabled):active, .btn-dark:not(:disabled):not(.disabled).active, .show > .btn-dark.dropdown-toggle {
  color: #fff;
  background-color: #ba5f08;
  border-color: #ae5907;
}
.btn-dark:not(:disabled):not(.disabled):active:focus, .btn-dark:not(:disabled):not(.disabled).active:focus, .show > .btn-dark.dropdown-toggle:focus {
  box-shadow: 0 0 0 0 rgba(238, 140, 47, 0.5);
}

.btn-inactive {
  color: #fff;
  background-color: #8b8b95;
  border-color: #8b8b95;
}
.btn-inactive:hover {
  color: #fff;
  background-color: #777783;
  border-color: #71717c;
}
.btn-inactive:focus, .btn-inactive.focus {
  color: #fff;
  background-color: #777783;
  border-color: #71717c;
  box-shadow: 0 0 0 0 rgba(156, 156, 165, 0.5);
}
.btn-inactive.disabled, .btn-inactive:disabled {
  color: #fff;
  background-color: #8b8b95;
  border-color: #8b8b95;
}
.btn-inactive:not(:disabled):not(.disabled):active, .btn-inactive:not(:disabled):not(.disabled).active, .show > .btn-inactive.dropdown-toggle {
  color: #fff;
  background-color: #71717c;
  border-color: #6b6b75;
}
.btn-inactive:not(:disabled):not(.disabled):active:focus, .btn-inactive:not(:disabled):not(.disabled).active:focus, .show > .btn-inactive.dropdown-toggle:focus {
  box-shadow: 0 0 0 0 rgba(156, 156, 165, 0.5);
}

.btn-outline-primary {
  color: #faa50a;
  border-color: #faa50a;
}
.btn-outline-primary:hover {
  color: #fff;
  background-color: #faa50a;
  border-color: #faa50a;
}
.btn-outline-primary:focus, .btn-outline-primary.focus {
  box-shadow: 0 0 0 0 rgba(250, 165, 10, 0.5);
}
.btn-outline-primary.disabled, .btn-outline-primary:disabled {
  color: #faa50a;
  background-color: transparent;
}
.btn-outline-primary:not(:disabled):not(.disabled):active, .btn-outline-primary:not(:disabled):not(.disabled).active, .show > .btn-outline-primary.dropdown-toggle {
  color: #fff;
  background-color: #faa50a;
  border-color: #faa50a;
}
.btn-outline-primary:not(:disabled):not(.disabled):active:focus, .btn-outline-primary:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0 rgba(250, 165, 10, 0.5);
}

.btn-outline-secondary, .btn-default {
  color: #2387aa;
  border-color: #2387aa;
}
.btn-outline-secondary:hover, .btn-default:hover {
  color: #fff;
  background-color: #2387aa;
  border-color: #2387aa;
}
.btn-outline-secondary:focus, .btn-default:focus, .btn-outline-secondary.focus, .focus.btn-default {
  box-shadow: 0 0 0 0 rgba(35, 135, 170, 0.5);
}
.btn-outline-secondary.disabled, .disabled.btn-default, .btn-outline-secondary:disabled, .btn-default:disabled {
  color: #2387aa;
  background-color: transparent;
}
.btn-outline-secondary:not(:disabled):not(.disabled):active, .btn-default:not(:disabled):not(.disabled):active, .btn-outline-secondary:not(:disabled):not(.disabled).active, .btn-default:not(:disabled):not(.disabled).active, .show > .btn-outline-secondary.dropdown-toggle, .show > .dropdown-toggle.btn-default {
  color: #fff;
  background-color: #2387aa;
  border-color: #2387aa;
}
.btn-outline-secondary:not(:disabled):not(.disabled):active:focus, .btn-default:not(:disabled):not(.disabled):active:focus, .btn-outline-secondary:not(:disabled):not(.disabled).active:focus, .btn-default:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-secondary.dropdown-toggle:focus, .show > .dropdown-toggle.btn-default:focus {
  box-shadow: 0 0 0 0 rgba(35, 135, 170, 0.5);
}

.btn-outline-success {
  color: #099b00;
  border-color: #099b00;
}
.btn-outline-success:hover {
  color: #fff;
  background-color: #099b00;
  border-color: #099b00;
}
.btn-outline-success:focus, .btn-outline-success.focus {
  box-shadow: 0 0 0 0 rgba(9, 155, 0, 0.5);
}
.btn-outline-success.disabled, .btn-outline-success:disabled {
  color: #099b00;
  background-color: transparent;
}
.btn-outline-success:not(:disabled):not(.disabled):active, .btn-outline-success:not(:disabled):not(.disabled).active, .show > .btn-outline-success.dropdown-toggle {
  color: #fff;
  background-color: #099b00;
  border-color: #099b00;
}
.btn-outline-success:not(:disabled):not(.disabled):active:focus, .btn-outline-success:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-success.dropdown-toggle:focus {
  box-shadow: 0 0 0 0 rgba(9, 155, 0, 0.5);
}

.btn-outline-info {
  color: #697882;
  border-color: #697882;
}
.btn-outline-info:hover {
  color: #fff;
  background-color: #697882;
  border-color: #697882;
}
.btn-outline-info:focus, .btn-outline-info.focus {
  box-shadow: 0 0 0 0 rgba(105, 120, 130, 0.5);
}
.btn-outline-info.disabled, .btn-outline-info:disabled {
  color: #697882;
  background-color: transparent;
}
.btn-outline-info:not(:disabled):not(.disabled):active, .btn-outline-info:not(:disabled):not(.disabled).active, .show > .btn-outline-info.dropdown-toggle {
  color: #fff;
  background-color: #697882;
  border-color: #697882;
}
.btn-outline-info:not(:disabled):not(.disabled):active:focus, .btn-outline-info:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-info.dropdown-toggle:focus {
  box-shadow: 0 0 0 0 rgba(105, 120, 130, 0.5);
}

.btn-outline-warning {
  color: #eb780a;
  border-color: #eb780a;
}
.btn-outline-warning:hover {
  color: #fff;
  background-color: #eb780a;
  border-color: #eb780a;
}
.btn-outline-warning:focus, .btn-outline-warning.focus {
  box-shadow: 0 0 0 0 rgba(235, 120, 10, 0.5);
}
.btn-outline-warning.disabled, .btn-outline-warning:disabled {
  color: #eb780a;
  background-color: transparent;
}
.btn-outline-warning:not(:disabled):not(.disabled):active, .btn-outline-warning:not(:disabled):not(.disabled).active, .show > .btn-outline-warning.dropdown-toggle {
  color: #fff;
  background-color: #eb780a;
  border-color: #eb780a;
}
.btn-outline-warning:not(:disabled):not(.disabled):active:focus, .btn-outline-warning:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-warning.dropdown-toggle:focus {
  box-shadow: 0 0 0 0 rgba(235, 120, 10, 0.5);
}

.btn-outline-danger {
  color: #dc0000;
  border-color: #dc0000;
}
.btn-outline-danger:hover {
  color: #fff;
  background-color: #dc0000;
  border-color: #dc0000;
}
.btn-outline-danger:focus, .btn-outline-danger.focus {
  box-shadow: 0 0 0 0 rgba(220, 0, 0, 0.5);
}
.btn-outline-danger.disabled, .btn-outline-danger:disabled {
  color: #dc0000;
  background-color: transparent;
}
.btn-outline-danger:not(:disabled):not(.disabled):active, .btn-outline-danger:not(:disabled):not(.disabled).active, .show > .btn-outline-danger.dropdown-toggle {
  color: #fff;
  background-color: #dc0000;
  border-color: #dc0000;
}
.btn-outline-danger:not(:disabled):not(.disabled):active:focus, .btn-outline-danger:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-danger.dropdown-toggle:focus {
  box-shadow: 0 0 0 0 rgba(220, 0, 0, 0.5);
}

.btn-outline-light {
  color: #ffb900;
  border-color: #ffb900;
}
.btn-outline-light:hover {
  color: #fff;
  background-color: #ffb900;
  border-color: #ffb900;
}
.btn-outline-light:focus, .btn-outline-light.focus {
  box-shadow: 0 0 0 0 rgba(255, 185, 0, 0.5);
}
.btn-outline-light.disabled, .btn-outline-light:disabled {
  color: #ffb900;
  background-color: transparent;
}
.btn-outline-light:not(:disabled):not(.disabled):active, .btn-outline-light:not(:disabled):not(.disabled).active, .show > .btn-outline-light.dropdown-toggle {
  color: #fff;
  background-color: #ffb900;
  border-color: #ffb900;
}
.btn-outline-light:not(:disabled):not(.disabled):active:focus, .btn-outline-light:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 0 rgba(255, 185, 0, 0.5);
}

.btn-outline-dark {
  color: #eb780a;
  border-color: #eb780a;
}
.btn-outline-dark:hover {
  color: #fff;
  background-color: #eb780a;
  border-color: #eb780a;
}
.btn-outline-dark:focus, .btn-outline-dark.focus {
  box-shadow: 0 0 0 0 rgba(235, 120, 10, 0.5);
}
.btn-outline-dark.disabled, .btn-outline-dark:disabled {
  color: #eb780a;
  background-color: transparent;
}
.btn-outline-dark:not(:disabled):not(.disabled):active, .btn-outline-dark:not(:disabled):not(.disabled).active, .show > .btn-outline-dark.dropdown-toggle {
  color: #fff;
  background-color: #eb780a;
  border-color: #eb780a;
}
.btn-outline-dark:not(:disabled):not(.disabled):active:focus, .btn-outline-dark:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-dark.dropdown-toggle:focus {
  box-shadow: 0 0 0 0 rgba(235, 120, 10, 0.5);
}

.btn-outline-inactive {
  color: #8b8b95;
  border-color: #8b8b95;
}
.btn-outline-inactive:hover {
  color: #fff;
  background-color: #8b8b95;
  border-color: #8b8b95;
}
.btn-outline-inactive:focus, .btn-outline-inactive.focus {
  box-shadow: 0 0 0 0 rgba(139, 139, 149, 0.5);
}
.btn-outline-inactive.disabled, .btn-outline-inactive:disabled {
  color: #8b8b95;
  background-color: transparent;
}
.btn-outline-inactive:not(:disabled):not(.disabled):active, .btn-outline-inactive:not(:disabled):not(.disabled).active, .show > .btn-outline-inactive.dropdown-toggle {
  color: #fff;
  background-color: #8b8b95;
  border-color: #8b8b95;
}
.btn-outline-inactive:not(:disabled):not(.disabled):active:focus, .btn-outline-inactive:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-inactive.dropdown-toggle:focus {
  box-shadow: 0 0 0 0 rgba(139, 139, 149, 0.5);
}

.btn-link {
  font-weight: 400;
  color: #2387aa;
  text-decoration: none;
}
.btn-link:hover {
  color: #005578;
  text-decoration: underline;
}
.btn-link:focus, .btn-link.focus {
  text-decoration: underline;
}
.btn-link:disabled, .btn-link.disabled {
  color: #6c757d;
  pointer-events: none;
}

.btn-lg, .btn-group-lg > .btn {
  padding: 0.5rem 1rem;
  font-size: 1.09375rem;
  line-height: 1.5;
  border-radius: 0;
}

.btn-sm, .btn-group-sm > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.765625rem;
  line-height: 1.5;
  border-radius: 0;
}

.btn-block {
  display: block;
  width: 100%;
}
.btn-block + .btn-block {
  margin-top: 0.5rem;
}

input[type=submit].btn-block,
input[type=reset].btn-block,
input[type=button].btn-block {
  width: 100%;
}

.btn-primary:not(:disabled):not(.disabled):hover {
  background: #eb780a;
  border-color: #eb780a;
}

.btn-secondary:not(:disabled):not(.disabled):hover {
  background: #005578;
  border-color: #005578;
}

.btn-outline-primary:not(:disabled):not(.disabled):hover {
  background: #eb780a;
  border-color: #eb780a;
}

.btn-outline-secondary:not(:disabled):not(.disabled):hover, .btn-default:not(:disabled):not(.disabled):hover {
  background: #005578;
  border-color: #005578;
}

.btn.btn-link {
  padding: 0;
}
.btn.disabled, .btn[disabled] {
  opacity: 0.5;
}

[class*=col-] + [class*=col-] > .btn {
  margin-top: 5px;
}

@media (min-width: 992px) {
  [class*=col-lg-] + [class*=col-lg-] > .btn {
    margin-top: 0;
  }
}
@media (min-width: 768px) {
  [class*=col-md-] + [class*=col-md-] > .btn {
    margin-top: 0;
  }
}
@media (min-width: 576px) {
  [class*=col-sm-] + [class*=col-sm-] > .btn {
    margin-top: 0;
  }
}
@media (min-width: 0) {
  [class*=col-xs-] + [class*=col-xs-] > .btn {
    margin-top: 0;
  }
}
.btn-normal-width, .navbar-btn, .modal-footer .btn,
.form-header .btn,
.form-footer .btn,
form .btn {
  min-width: 90px;
}

.btn-wide {
  min-width: 180px;
}

.btn-narrow {
  min-width: 67.5px;
}

.btn + .btn,
.btn-group + .btn-group {
  margin-left: 0;
}

.modal-footer .btn + .btn {
  margin-left: 5px;
}

.btn-circle, .btn.btn-circle {
  display: inline-block;
  width: 46px;
  min-width: 46px;
  height: 46px;
  min-height: 46px;
  font-size: 28px;
  padding: 0;
  border-radius: 50%;
}
.btn-circle:focus, .btn-circle:active, .btn-circle:active:focus {
  outline: none;
}
.btn-circle.btn-secondary {
  color: #2387aa;
}

.btn-circle.btn-xs {
  display: inline-block;
  width: 30px;
  min-width: 30px;
  height: 30px;
  min-height: 30px;
  font-size: 20px;
}

.btn-circle.btn-sm, .btn-group-sm > .btn-circle.btn {
  display: inline-block;
  width: 34px;
  min-width: 34px;
  height: 34px;
  min-height: 34px;
  font-size: 24px;
}

.btn-circle.btn-lg, .btn-group-lg > .btn-circle.btn {
  display: inline-block;
  width: 60px;
  min-width: 60px;
  height: 60px;
  min-height: 60px;
  font-size: 40px;
}

a.btn {
  text-decoration: none;
}

.fade {
  transition: opacity 0.15s linear;
}
@media (prefers-reduced-motion: reduce) {
  .fade {
    transition: none;
  }
}
.fade:not(.show) {
  opacity: 0;
}

.collapse:not(.show) {
  display: none;
}

.collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease;
}
@media (prefers-reduced-motion: reduce) {
  .collapsing {
    transition: none;
  }
}

.dropup,
.dropright,
.dropdown,
.dropleft {
  position: relative;
}

.dropdown-toggle {
  white-space: nowrap;
}
.dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
}
.dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 10rem;
  padding: 0.5rem 0;
  margin: 0.125rem 0 0;
  font-size: 0.875rem;
  color: #2d373c;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0;
}

.dropdown-menu-left {
  right: auto;
  left: 0;
}

.dropdown-menu-right {
  right: 0;
  left: auto;
}

@media (min-width: 576px) {
  .dropdown-menu-sm-left {
    right: auto;
    left: 0;
  }

  .dropdown-menu-sm-right {
    right: 0;
    left: auto;
  }
}
@media (min-width: 768px) {
  .dropdown-menu-md-left {
    right: auto;
    left: 0;
  }

  .dropdown-menu-md-right {
    right: 0;
    left: auto;
  }
}
@media (min-width: 992px) {
  .dropdown-menu-lg-left {
    right: auto;
    left: 0;
  }

  .dropdown-menu-lg-right {
    right: 0;
    left: auto;
  }
}
@media (min-width: 1200px) {
  .dropdown-menu-xl-left {
    right: auto;
    left: 0;
  }

  .dropdown-menu-xl-right {
    right: 0;
    left: auto;
  }
}
.dropup .dropdown-menu {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: 0.125rem;
}
.dropup .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0;
  border-right: 0.3em solid transparent;
  border-bottom: 0.3em solid;
  border-left: 0.3em solid transparent;
}
.dropup .dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropright .dropdown-menu {
  top: 0;
  right: auto;
  left: 100%;
  margin-top: 0;
  margin-left: 0.125rem;
}
.dropright .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0;
  border-bottom: 0.3em solid transparent;
  border-left: 0.3em solid;
}
.dropright .dropdown-toggle:empty::after {
  margin-left: 0;
}
.dropright .dropdown-toggle::after {
  vertical-align: 0;
}

.dropleft .dropdown-menu {
  top: 0;
  right: 100%;
  left: auto;
  margin-top: 0;
  margin-right: 0.125rem;
}
.dropleft .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
}
.dropleft .dropdown-toggle::after {
  display: none;
}
.dropleft .dropdown-toggle::before {
  display: inline-block;
  margin-right: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0.3em solid;
  border-bottom: 0.3em solid transparent;
}
.dropleft .dropdown-toggle:empty::after {
  margin-left: 0;
}
.dropleft .dropdown-toggle::before {
  vertical-align: 0;
}

.dropdown-menu[x-placement^=top], .dropdown-menu[x-placement^=right], .dropdown-menu[x-placement^=bottom], .dropdown-menu[x-placement^=left] {
  right: auto;
  bottom: auto;
}

.dropdown-divider {
  height: 0;
  margin: 0.5rem 0;
  overflow: hidden;
  border-top: 1px solid #e9ecef;
}

.dropdown-item {
  display: block;
  width: 100%;
  padding: 0.25rem 1.5rem;
  clear: both;
  font-weight: 400;
  color: #212529;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
}
.dropdown-item:hover, .dropdown-item:focus {
  color: #16181b;
  text-decoration: none;
  background-color: #e9ecef;
}
.dropdown-item.active, .dropdown-item:active {
  color: #fff;
  text-decoration: none;
  background-color: #faa50a;
}
.dropdown-item.disabled, .dropdown-item:disabled {
  color: #adb5bd;
  pointer-events: none;
  background-color: transparent;
}

.dropdown-menu.show {
  display: block;
}

.dropdown-header {
  display: block;
  padding: 0.5rem 1.5rem;
  margin-bottom: 0;
  font-size: 0.765625rem;
  color: #6c757d;
  white-space: nowrap;
}

.dropdown-item-text {
  display: block;
  padding: 0.25rem 1.5rem;
  color: #212529;
}

.caret {
  margin-left: 6px;
  border-top: 0.3em dashed;
  border-right: 0.3em solid transparent;
  border-left: 0.3em solid transparent;
}

.dropdown-menu > li.selected {
  background-color: #dbe8f0;
}

.dropdown-menu {
  padding-top: 2px;
}

.dropdown-header {
  color: #6c757d;
  background: #dfe6ed;
  font-size: 1rem;
  font-weight: normal;
  padding: 10px 16px;
}

a.dropdown-item {
  text-decoration: none;
}

.btn-group,
.btn-group-vertical {
  position: relative;
  display: inline-flex;
  vertical-align: middle;
}
.btn-group > .btn,
.btn-group-vertical > .btn {
  position: relative;
  flex: 1 1 auto;
}
.btn-group > .btn:hover,
.btn-group-vertical > .btn:hover {
  z-index: 1;
}
.btn-group > .btn:focus, .btn-group > .btn:active, .btn-group > .btn.active,
.btn-group-vertical > .btn:focus,
.btn-group-vertical > .btn:active,
.btn-group-vertical > .btn.active {
  z-index: 1;
}

.btn-toolbar {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}
.btn-toolbar .input-group {
  width: auto;
}

.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) {
  margin-left: -1px;
}
.btn-group > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group > .btn-group:not(:last-child) > .btn {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.dropdown-toggle-split {
  padding-right: 0.5625rem;
  padding-left: 0.5625rem;
}
.dropdown-toggle-split::after, .dropup .dropdown-toggle-split::after, .dropright .dropdown-toggle-split::after {
  margin-left: 0;
}
.dropleft .dropdown-toggle-split::before {
  margin-right: 0;
}

.btn-sm + .dropdown-toggle-split, .btn-group-sm > .btn + .dropdown-toggle-split {
  padding-right: 0.375rem;
  padding-left: 0.375rem;
}

.btn-lg + .dropdown-toggle-split, .btn-group-lg > .btn + .dropdown-toggle-split {
  padding-right: 0.75rem;
  padding-left: 0.75rem;
}

.btn-group-vertical {
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}
.btn-group-vertical > .btn,
.btn-group-vertical > .btn-group {
  width: 100%;
}
.btn-group-vertical > .btn:not(:first-child),
.btn-group-vertical > .btn-group:not(:first-child) {
  margin-top: -1px;
}
.btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group-vertical > .btn-group:not(:last-child) > .btn {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.btn-group-vertical > .btn:not(:first-child),
.btn-group-vertical > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.btn-group-toggle > .btn,
.btn-group-toggle > .btn-group > .btn {
  margin-bottom: 0;
}
.btn-group-toggle > .btn input[type=radio],
.btn-group-toggle > .btn input[type=checkbox],
.btn-group-toggle > .btn-group > .btn input[type=radio],
.btn-group-toggle > .btn-group > .btn input[type=checkbox] {
  position: absolute;
  clip: rect(0, 0, 0, 0);
  pointer-events: none;
}

.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%;
}
.input-group > .form-control,
.input-group > .form-control-plaintext,
.input-group > .custom-select,
.input-group > .custom-file {
  position: relative;
  flex: 1 1 auto;
  width: 1%;
  min-width: 0;
  margin-bottom: 0;
}
.input-group > .form-control + .form-control,
.input-group > .form-control + .custom-select,
.input-group > .form-control + .custom-file,
.input-group > .form-control-plaintext + .form-control,
.input-group > .form-control-plaintext + .custom-select,
.input-group > .form-control-plaintext + .custom-file,
.input-group > .custom-select + .form-control,
.input-group > .custom-select + .custom-select,
.input-group > .custom-select + .custom-file,
.input-group > .custom-file + .form-control,
.input-group > .custom-file + .custom-select,
.input-group > .custom-file + .custom-file {
  margin-left: -1px;
}
.input-group > .form-control:focus,
.input-group > .custom-select:focus,
.input-group > .custom-file .custom-file-input:focus ~ .custom-file-label {
  z-index: 3;
}
.input-group > .custom-file .custom-file-input:focus {
  z-index: 4;
}
.input-group > .form-control:not(:first-child),
.input-group > .custom-select:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.input-group > .custom-file {
  display: flex;
  align-items: center;
}
.input-group > .custom-file:not(:last-child) .custom-file-label, .input-group > .custom-file:not(:first-child) .custom-file-label {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.input-group:not(.has-validation) > .form-control:not(:last-child),
.input-group:not(.has-validation) > .custom-select:not(:last-child),
.input-group:not(.has-validation) > .custom-file:not(:last-child) .custom-file-label::after {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.input-group.has-validation > .form-control:nth-last-child(n+3),
.input-group.has-validation > .custom-select:nth-last-child(n+3),
.input-group.has-validation > .custom-file:nth-last-child(n+3) .custom-file-label::after {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group-prepend,
.input-group-append {
  display: flex;
}
.input-group-prepend .btn,
.input-group-append .btn {
  position: relative;
  z-index: 2;
}
.input-group-prepend .btn:focus,
.input-group-append .btn:focus {
  z-index: 3;
}
.input-group-prepend .btn + .btn,
.input-group-prepend .btn + .input-group-text,
.input-group-prepend .input-group-text + .input-group-text,
.input-group-prepend .input-group-text + .btn,
.input-group-append .btn + .btn,
.input-group-append .btn + .input-group-text,
.input-group-append .input-group-text + .input-group-text,
.input-group-append .input-group-text + .btn {
  margin-left: -1px;
}

.input-group-prepend {
  margin-right: -1px;
}

.input-group-append {
  margin-left: -1px;
}

.input-group-text {
  display: flex;
  align-items: center;
  padding: 0.375rem 0.75rem;
  margin-bottom: 0;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
  color: #2d373c;
  text-align: center;
  white-space: nowrap;
  background-color: #e9ecef;
  border: 1px solid #ced4da;
  border-radius: 0;
}
.input-group-text input[type=radio],
.input-group-text input[type=checkbox] {
  margin-top: 0;
}

.input-group-lg > .form-control:not(textarea),
.input-group-lg > .custom-select {
  height: calc(1.5em + 1rem + 2px);
}

.input-group-lg > .form-control,
.input-group-lg > .custom-select,
.input-group-lg > .input-group-prepend > .input-group-text,
.input-group-lg > .input-group-append > .input-group-text,
.input-group-lg > .input-group-prepend > .btn,
.input-group-lg > .input-group-append > .btn {
  padding: 0.5rem 1rem;
  font-size: 1.09375rem;
  line-height: 1.5;
  border-radius: 0;
}

.input-group-sm > .form-control:not(textarea),
.input-group-sm > .custom-select {
  height: calc(1.5em + 0.5rem + 2px);
}

.input-group-sm > .form-control,
.input-group-sm > .custom-select,
.input-group-sm > .input-group-prepend > .input-group-text,
.input-group-sm > .input-group-append > .input-group-text,
.input-group-sm > .input-group-prepend > .btn,
.input-group-sm > .input-group-append > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.765625rem;
  line-height: 1.5;
  border-radius: 0;
}

.input-group-lg > .custom-select,
.input-group-sm > .custom-select {
  padding-right: 1.75rem;
}

.input-group > .input-group-prepend > .btn,
.input-group > .input-group-prepend > .input-group-text,
.input-group:not(.has-validation) > .input-group-append:not(:last-child) > .btn,
.input-group:not(.has-validation) > .input-group-append:not(:last-child) > .input-group-text,
.input-group.has-validation > .input-group-append:nth-last-child(n+3) > .btn,
.input-group.has-validation > .input-group-append:nth-last-child(n+3) > .input-group-text,
.input-group > .input-group-append:last-child > .btn:not(:last-child):not(.dropdown-toggle),
.input-group > .input-group-append:last-child > .input-group-text:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group > .input-group-append > .btn,
.input-group > .input-group-append > .input-group-text,
.input-group > .input-group-prepend:not(:first-child) > .btn,
.input-group > .input-group-prepend:not(:first-child) > .input-group-text,
.input-group > .input-group-prepend:first-child > .btn:not(:first-child),
.input-group > .input-group-prepend:first-child > .input-group-text:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.input-group {
  width: 100%;
}
.input-group > .input-group {
  flex: 1 1;
}

.input-group-append,
.input-group-prepend,
.input-group-text {
  background-color: #dfe6ed;
  border-top-color: #879baa;
  color: #697882;
  min-width: 50px;
  text-align: center;
  justify-content: center;
}

.form-group .action-item-group {
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  z-index: 3;
}
.form-group .action-item-group .action-item {
  padding-right: 17px;
}

.custom-control {
  position: relative;
  z-index: 1;
  display: block;
  min-height: 1.3125rem;
  padding-left: 1.5rem;
  color-adjust: exact;
}

.custom-control-inline {
  display: inline-flex;
  margin-right: 1rem;
}

.custom-control-input {
  position: absolute;
  left: 0;
  z-index: -1;
  width: 1rem;
  height: 1.15625rem;
  opacity: 0;
}
.custom-control-input:checked ~ .custom-control-label::before {
  color: #fff;
  border-color: #faa50a;
  background-color: #faa50a;
}
.custom-control-input:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(250, 165, 10, 0.25);
}
.custom-control-input:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #fdd387;
}
.custom-control-input:not(:disabled):active ~ .custom-control-label::before {
  color: #fff;
  background-color: #fee5b9;
  border-color: #fee5b9;
}
.custom-control-input[disabled] ~ .custom-control-label, .custom-control-input:disabled ~ .custom-control-label {
  color: #6c757d;
}
.custom-control-input[disabled] ~ .custom-control-label::before, .custom-control-input:disabled ~ .custom-control-label::before {
  background-color: #e9ecef;
}

.custom-control-label {
  position: relative;
  margin-bottom: 0;
  vertical-align: top;
}
.custom-control-label::before {
  position: absolute;
  top: 0.15625rem;
  left: -1.5rem;
  display: block;
  width: 1rem;
  height: 1rem;
  pointer-events: none;
  content: "";
  background-color: #fff;
  border: #adb5bd solid 1px;
}
.custom-control-label::after {
  position: absolute;
  top: 0.15625rem;
  left: -1.5rem;
  display: block;
  width: 1rem;
  height: 1rem;
  content: "";
  background: 50%/50% 50% no-repeat;
}

.custom-checkbox .custom-control-label::before {
  border-radius: 0;
}
.custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26l2.974 2.99L8 2.193z'/%3e%3c/svg%3e");
}
.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::before {
  border-color: #faa50a;
  background-color: #faa50a;
}
.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='4' viewBox='0 0 4 4'%3e%3cpath stroke='%23fff' d='M0 2h4'/%3e%3c/svg%3e");
}
.custom-checkbox .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(250, 165, 10, 0.5);
}
.custom-checkbox .custom-control-input:disabled:indeterminate ~ .custom-control-label::before {
  background-color: rgba(250, 165, 10, 0.5);
}

.custom-radio .custom-control-label::before {
  border-radius: 50%;
}
.custom-radio .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e");
}
.custom-radio .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(250, 165, 10, 0.5);
}

.custom-switch {
  padding-left: 2.25rem;
}
.custom-switch .custom-control-label::before {
  left: -2.25rem;
  width: 1.75rem;
  pointer-events: all;
  border-radius: 0.5rem;
}
.custom-switch .custom-control-label::after {
  top: calc(0.15625rem + 2px);
  left: calc(-2.25rem + 2px);
  width: calc(1rem - 4px);
  height: calc(1rem - 4px);
  background-color: #adb5bd;
  border-radius: 0.5rem;
  transition: transform 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .custom-switch .custom-control-label::after {
    transition: none;
  }
}
.custom-switch .custom-control-input:checked ~ .custom-control-label::after {
  background-color: #fff;
  transform: translateX(0.75rem);
}
.custom-switch .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(250, 165, 10, 0.5);
}

.custom-select {
  display: inline-block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 1.75rem 0.375rem 0.75rem;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
  color: #2d373c;
  vertical-align: middle;
  background: #fff url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") right 0.75rem center/8px 10px no-repeat;
  border: 1px solid #ced4da;
  border-radius: 0;
  appearance: none;
}
.custom-select:focus {
  border-color: #fdd387;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(250, 165, 10, 0.25);
}
.custom-select:focus::-ms-value {
  color: #2d373c;
  background-color: #fff;
}
.custom-select[multiple], .custom-select[size]:not([size="1"]) {
  height: auto;
  padding-right: 0.75rem;
  background-image: none;
}
.custom-select:disabled {
  color: #6c757d;
  background-color: #e9ecef;
}
.custom-select::-ms-expand {
  display: none;
}
.custom-select:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #2d373c;
}

.custom-select-sm {
  height: calc(1.5em + 0.5rem + 2px);
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  padding-left: 0.5rem;
  font-size: 0.765625rem;
}

.custom-select-lg {
  height: calc(1.5em + 1rem + 2px);
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1rem;
  font-size: 1.09375rem;
}

.custom-file {
  position: relative;
  display: inline-block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  margin-bottom: 0;
}

.custom-file-input {
  position: relative;
  z-index: 2;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  margin: 0;
  overflow: hidden;
  opacity: 0;
}
.custom-file-input:focus ~ .custom-file-label {
  border-color: #fdd387;
  box-shadow: 0 0 0 0.2rem rgba(250, 165, 10, 0.25);
}
.custom-file-input[disabled] ~ .custom-file-label, .custom-file-input:disabled ~ .custom-file-label {
  background-color: #e9ecef;
}
.custom-file-input:lang(en) ~ .custom-file-label::after {
  content: "Browse";
}
.custom-file-input ~ .custom-file-label[data-browse]::after {
  content: attr(data-browse);
}

.custom-file-label {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  overflow: hidden;
  font-weight: 400;
  line-height: 1.5;
  color: #2d373c;
  background-color: #fff;
  border: 1px solid #ced4da;
  border-radius: 0;
}
.custom-file-label::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 3;
  display: block;
  height: calc(1.5em + 0.75rem);
  padding: 0.375rem 0.75rem;
  line-height: 1.5;
  color: #2d373c;
  content: "Browse";
  background-color: #e9ecef;
  border-left: inherit;
  border-radius: 0 0 0 0;
}

.custom-range {
  width: 100%;
  height: 1.4rem;
  padding: 0;
  background-color: transparent;
  appearance: none;
}
.custom-range:focus {
  outline: 0;
}
.custom-range:focus::-webkit-slider-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(250, 165, 10, 0.25);
}
.custom-range:focus::-moz-range-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(250, 165, 10, 0.25);
}
.custom-range:focus::-ms-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(250, 165, 10, 0.25);
}
.custom-range::-moz-focus-outer {
  border: 0;
}
.custom-range::-webkit-slider-thumb {
  width: 1rem;
  height: 1rem;
  margin-top: -0.25rem;
  background-color: #faa50a;
  border: 0;
  border-radius: 1rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}
@media (prefers-reduced-motion: reduce) {
  .custom-range::-webkit-slider-thumb {
    transition: none;
  }
}
.custom-range::-webkit-slider-thumb:active {
  background-color: #fee5b9;
}
.custom-range::-webkit-slider-runnable-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: #dee2e6;
  border-color: transparent;
  border-radius: 1rem;
}
.custom-range::-moz-range-thumb {
  width: 1rem;
  height: 1rem;
  background-color: #faa50a;
  border: 0;
  border-radius: 1rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}
@media (prefers-reduced-motion: reduce) {
  .custom-range::-moz-range-thumb {
    transition: none;
  }
}
.custom-range::-moz-range-thumb:active {
  background-color: #fee5b9;
}
.custom-range::-moz-range-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: #dee2e6;
  border-color: transparent;
  border-radius: 1rem;
}
.custom-range::-ms-thumb {
  width: 1rem;
  height: 1rem;
  margin-top: 0;
  margin-right: 0.2rem;
  margin-left: 0.2rem;
  background-color: #faa50a;
  border: 0;
  border-radius: 1rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}
@media (prefers-reduced-motion: reduce) {
  .custom-range::-ms-thumb {
    transition: none;
  }
}
.custom-range::-ms-thumb:active {
  background-color: #fee5b9;
}
.custom-range::-ms-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: transparent;
  border-color: transparent;
  border-width: 0.5rem;
}
.custom-range::-ms-fill-lower {
  background-color: #dee2e6;
  border-radius: 1rem;
}
.custom-range::-ms-fill-upper {
  margin-right: 15px;
  background-color: #dee2e6;
  border-radius: 1rem;
}
.custom-range:disabled::-webkit-slider-thumb {
  background-color: #adb5bd;
}
.custom-range:disabled::-webkit-slider-runnable-track {
  cursor: default;
}
.custom-range:disabled::-moz-range-thumb {
  background-color: #adb5bd;
}
.custom-range:disabled::-moz-range-track {
  cursor: default;
}
.custom-range:disabled::-ms-thumb {
  background-color: #adb5bd;
}

.custom-control-label::before,
.custom-file-label,
.custom-select {
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .custom-control-label::before,
.custom-file-label,
.custom-select {
    transition: none;
  }
}

.nav, .sidebar, .form-header, .form-footer {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.nav-link {
  display: block;
  padding: 0.875rem 0.938rem;
}
.nav-link:hover, .nav-link:focus {
  text-decoration: none;
}
.nav-link.disabled {
  color: #6c757d;
  pointer-events: none;
  cursor: default;
}

.nav-tabs {
  border-bottom: 1px solid #dee2e6;
}
.nav-tabs .nav-link {
  margin-bottom: -1px;
  border: 1px solid transparent;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
  border-color: #e9ecef #e9ecef #dee2e6;
}
.nav-tabs .nav-link.disabled {
  color: #6c757d;
  background-color: transparent;
  border-color: transparent;
}
.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  color: #2387aa;
  background-color: #fff;
  border-color: transparent transparent transparent;
}
.nav-tabs .dropdown-menu {
  margin-top: -1px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.nav-pills .nav-link {
  border-radius: 0;
}
.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #fff;
  background-color: #faa50a;
}

.nav-fill > .nav-link,
.nav-fill .nav-item {
  flex: 1 1 auto;
  text-align: center;
}

.nav-justified > .nav-link,
.nav-justified .nav-item {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center;
}

.tab-content > .tab-pane {
  display: none;
}
.tab-content > .active {
  display: block;
}

.nav:hover, .sidebar:hover, .form-header:hover, .form-footer:hover {
  color: inherit;
}

a.nav-link {
  text-decoration: none;
}

.nav-tabs {
  background: #ebf0f5;
  width: 100%;
  border: 0;
  font-weight: inherit;
}
@media (max-width: 767.98px) {
  .nav-tabs > .nav-link {
    flex: 1 100%;
  }
}
.nav-tabs .nav-link {
  min-width: 0;
  margin: 0;
  padding: 10px 10px 20px 10px;
  border: 0;
  border-right: 2px solid #fff;
  cursor: pointer;
  text-align: left;
  flex: 1;
}
.nav-tabs .nav-link:not(.active):hover, .nav-tabs .nav-link:not(.active):focus {
  background: #dfe6ed;
  border-color: #e9ecef #e9ecef #dee2e6;
}
.nav-tabs .nav-link.disabled {
  color: #6c757d;
  background-color: transparent;
  border-color: transparent;
}
.nav-tabs.nav-justified .nav-link.active {
  border: 1px solid #dee2e6;
  border-bottom: 0;
}

.nav-justified > li > a {
  text-align: left;
}

.tab-pane {
  padding-top: 30px;
  padding-bottom: 30px;
}

.navbar {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: 20px 15px;
}
.navbar .container,
.navbar .container-fluid,
.navbar .container-sm,
.navbar .container-md,
.navbar .container-lg,
.navbar .container-xl {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
}
.navbar-brand {
  display: inline-block;
  padding-top: 10px;
  padding-bottom: 10px;
  margin-right: 15px;
  font-size: 0;
  line-height: inherit;
  white-space: nowrap;
}
.navbar-brand:hover, .navbar-brand:focus {
  text-decoration: none;
}

.navbar-nav {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}
.navbar-nav .nav-link {
  padding-right: 0;
  padding-left: 0;
}
.navbar-nav .dropdown-menu {
  position: static;
  float: none;
}

.navbar-text, .form-header h2, .form-header .h2d {
  display: inline-block;
  padding-top: 0.875rem;
  padding-bottom: 0.875rem;
}

.navbar-collapse {
  flex-basis: 100%;
  flex-grow: 1;
  align-items: center;
}

.navbar-toggler {
  padding: 0.25rem 0.75rem;
  font-size: 1.09375rem;
  line-height: 1;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 0;
}
.navbar-toggler:hover, .navbar-toggler:focus {
  text-decoration: none;
}

.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  content: "";
  background: 50%/100% 100% no-repeat;
}

.navbar-nav-scroll {
  max-height: 75vh;
  overflow-y: auto;
}

@media (max-width: 575.98px) {
  .navbar-expand-sm > .container,
.navbar-expand-sm > .container-fluid,
.navbar-expand-sm > .container-sm,
.navbar-expand-sm > .container-md,
.navbar-expand-sm > .container-lg,
.navbar-expand-sm > .container-xl {
    padding-right: 0;
    padding-left: 0;
  }
}
@media (min-width: 576px) {
  .navbar-expand-sm {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-sm .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-sm .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-sm .navbar-nav .nav-link {
    padding-right: 0;
    padding-left: 0;
  }
  .navbar-expand-sm > .container,
.navbar-expand-sm > .container-fluid,
.navbar-expand-sm > .container-sm,
.navbar-expand-sm > .container-md,
.navbar-expand-sm > .container-lg,
.navbar-expand-sm > .container-xl {
    flex-wrap: nowrap;
  }
  .navbar-expand-sm .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-sm .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-sm .navbar-toggler {
    display: none;
  }
}
@media (max-width: 767.98px) {
  .navbar-expand-md > .container,
.navbar-expand-md > .container-fluid,
.navbar-expand-md > .container-sm,
.navbar-expand-md > .container-md,
.navbar-expand-md > .container-lg,
.navbar-expand-md > .container-xl {
    padding-right: 0;
    padding-left: 0;
  }
}
@media (min-width: 768px) {
  .navbar-expand-md {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-md .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-md .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-md .navbar-nav .nav-link {
    padding-right: 0;
    padding-left: 0;
  }
  .navbar-expand-md > .container,
.navbar-expand-md > .container-fluid,
.navbar-expand-md > .container-sm,
.navbar-expand-md > .container-md,
.navbar-expand-md > .container-lg,
.navbar-expand-md > .container-xl {
    flex-wrap: nowrap;
  }
  .navbar-expand-md .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-md .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-md .navbar-toggler {
    display: none;
  }
}
@media (max-width: 991.98px) {
  .navbar-expand-lg > .container,
.navbar-expand-lg > .container-fluid,
.navbar-expand-lg > .container-sm,
.navbar-expand-lg > .container-md,
.navbar-expand-lg > .container-lg,
.navbar-expand-lg > .container-xl {
    padding-right: 0;
    padding-left: 0;
  }
}
@media (min-width: 992px) {
  .navbar-expand-lg {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-lg .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-lg .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-lg .navbar-nav .nav-link {
    padding-right: 0;
    padding-left: 0;
  }
  .navbar-expand-lg > .container,
.navbar-expand-lg > .container-fluid,
.navbar-expand-lg > .container-sm,
.navbar-expand-lg > .container-md,
.navbar-expand-lg > .container-lg,
.navbar-expand-lg > .container-xl {
    flex-wrap: nowrap;
  }
  .navbar-expand-lg .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-lg .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-lg .navbar-toggler {
    display: none;
  }
}
@media (max-width: 1199.98px) {
  .navbar-expand-xl > .container,
.navbar-expand-xl > .container-fluid,
.navbar-expand-xl > .container-sm,
.navbar-expand-xl > .container-md,
.navbar-expand-xl > .container-lg,
.navbar-expand-xl > .container-xl {
    padding-right: 0;
    padding-left: 0;
  }
}
@media (min-width: 1200px) {
  .navbar-expand-xl {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-xl .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-xl .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-xl .navbar-nav .nav-link {
    padding-right: 0;
    padding-left: 0;
  }
  .navbar-expand-xl > .container,
.navbar-expand-xl > .container-fluid,
.navbar-expand-xl > .container-sm,
.navbar-expand-xl > .container-md,
.navbar-expand-xl > .container-lg,
.navbar-expand-xl > .container-xl {
    flex-wrap: nowrap;
  }
  .navbar-expand-xl .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-xl .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-xl .navbar-toggler {
    display: none;
  }
}
.navbar-expand {
  flex-flow: row nowrap;
  justify-content: flex-start;
}
.navbar-expand > .container,
.navbar-expand > .container-fluid,
.navbar-expand > .container-sm,
.navbar-expand > .container-md,
.navbar-expand > .container-lg,
.navbar-expand > .container-xl {
  padding-right: 0;
  padding-left: 0;
}
.navbar-expand .navbar-nav {
  flex-direction: row;
}
.navbar-expand .navbar-nav .dropdown-menu {
  position: absolute;
}
.navbar-expand .navbar-nav .nav-link {
  padding-right: 0;
  padding-left: 0;
}
.navbar-expand > .container,
.navbar-expand > .container-fluid,
.navbar-expand > .container-sm,
.navbar-expand > .container-md,
.navbar-expand > .container-lg,
.navbar-expand > .container-xl {
  flex-wrap: nowrap;
}
.navbar-expand .navbar-nav-scroll {
  overflow: visible;
}
.navbar-expand .navbar-collapse {
  display: flex !important;
  flex-basis: auto;
}
.navbar-expand .navbar-toggler {
  display: none;
}

.navbar-light .navbar-brand {
  color: rgba(0, 0, 0, 0.9);
}
.navbar-light .navbar-brand:hover, .navbar-light .navbar-brand:focus {
  color: rgba(0, 0, 0, 0.9);
}
.navbar-light .navbar-nav .nav-link {
  color: #788791;
}
.navbar-light .navbar-nav .nav-link:hover, .navbar-light .navbar-nav .nav-link:focus {
  color: rgba(0, 0, 0, 0.7);
}
.navbar-light .navbar-nav .nav-link.disabled {
  color: rgba(0, 0, 0, 0.3);
}
.navbar-light .navbar-nav .show > .nav-link,
.navbar-light .navbar-nav .active > .nav-link,
.navbar-light .navbar-nav .nav-link.show,
.navbar-light .navbar-nav .nav-link.active {
  color: rgba(0, 0, 0, 0.9);
}
.navbar-light .navbar-toggler {
  color: #788791;
  border-color: rgba(0, 0, 0, 0.1);
}
.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='%23788791' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}
.navbar-light .navbar-text, .navbar-light .form-header h2, .navbar-light .form-header .h2d, .form-header .navbar-light h2, .form-header .navbar-light .h2d {
  color: #788791;
}
.navbar-light .navbar-text a, .navbar-light .form-header h2 a, .navbar-light .form-header .h2d a, .form-header .navbar-light h2 a, .form-header .navbar-light .h2d a {
  color: rgba(0, 0, 0, 0.9);
}
.navbar-light .navbar-text a:hover, .navbar-light .form-header h2 a:hover, .navbar-light .form-header .h2d a:hover, .form-header .navbar-light h2 a:hover, .form-header .navbar-light .h2d a:hover, .navbar-light .navbar-text a:focus, .navbar-light .form-header h2 a:focus, .navbar-light .form-header .h2d a:focus, .form-header .navbar-light h2 a:focus, .form-header .navbar-light .h2d a:focus {
  color: rgba(0, 0, 0, 0.9);
}

.navbar-dark .navbar-brand {
  color: #fff;
}
.navbar-dark .navbar-brand:hover, .navbar-dark .navbar-brand:focus {
  color: #fff;
}
.navbar-dark .navbar-nav .nav-link {
  color: rgba(255, 255, 255, 0.5);
}
.navbar-dark .navbar-nav .nav-link:hover, .navbar-dark .navbar-nav .nav-link:focus {
  color: rgba(255, 255, 255, 0.75);
}
.navbar-dark .navbar-nav .nav-link.disabled {
  color: rgba(255, 255, 255, 0.25);
}
.navbar-dark .navbar-nav .show > .nav-link,
.navbar-dark .navbar-nav .active > .nav-link,
.navbar-dark .navbar-nav .nav-link.show,
.navbar-dark .navbar-nav .nav-link.active {
  color: #fff;
}
.navbar-dark .navbar-toggler {
  color: rgba(255, 255, 255, 0.5);
  border-color: rgba(255, 255, 255, 0.1);
}
.navbar-dark .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 255, 255, 0.5%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}
.navbar-dark .navbar-text, .navbar-dark .form-header h2, .navbar-dark .form-header .h2d, .form-header .navbar-dark h2, .form-header .navbar-dark .h2d {
  color: rgba(255, 255, 255, 0.5);
}
.navbar-dark .navbar-text a, .navbar-dark .form-header h2 a, .navbar-dark .form-header .h2d a, .form-header .navbar-dark h2 a, .form-header .navbar-dark .h2d a {
  color: #fff;
}
.navbar-dark .navbar-text a:hover, .navbar-dark .form-header h2 a:hover, .navbar-dark .form-header .h2d a:hover, .form-header .navbar-dark h2 a:hover, .form-header .navbar-dark .h2d a:hover, .navbar-dark .navbar-text a:focus, .navbar-dark .form-header h2 a:focus, .navbar-dark .form-header .h2d a:focus, .form-header .navbar-dark h2 a:focus, .form-header .navbar-dark .h2d a:focus {
  color: #fff;
}

.navbar-expand-lg {
  align-items: stretch;
}
.navbar-expand-lg .navbar-collapse {
  flex-basis: 100%;
}
.navbar-expand-lg > .container,
.navbar-expand-lg > .container-fluid,
.navbar-expand-lg > .container-sm,
.navbar-expand-lg > .container-md,
.navbar-expand-lg > .container-lg,
.navbar-expand-lg > .container-xl {
  flex-wrap: wrap;
}
.navbar-expand-lg > .container-fluid, .navbar-expand-lg > .container-sm, .navbar-expand-lg > .container-md, .navbar-expand-lg > .container-lg, .navbar-expand-lg > .container-xl {
  padding-right: 0;
  padding-left: 0;
}

#navbar {
  display: flex;
  margin-left: auto;
}

ul.header-link-list {
  list-style: none;
  padding-left: 0;
  padding: 70px 0 20px;
}
ul.header-link-list > li {
  line-height: 16px;
  vertical-align: bottom;
}
ul.header-link-list > li > a {
  color: #2387aa;
  line-height: 20px;
  padding-right: 10px;
  text-decoration: none;
}
ul.header-link-list > li > a:hover {
  color: #005578;
  text-decoration: underline;
}
ul.header-link-list > li > a:active {
  color: #005578;
}
ul.header-link-list > li > a > span {
  text-decoration: none;
  border: 0;
}
ul.header-link-list > .nav-dropdown.show :hover {
  background: #ebf0f5;
}
ul.header-link-list > li {
  border-top: 1px solid #cdd9e1;
  vertical-align: middle;
}
ul.header-link-list > li :hover {
  background: #dfe6ed;
}
ul.header-link-list > li > a {
  width: 100%;
  display: inline-block;
  padding: 0.875rem 0.938rem;
  cursor: pointer;
}
ul.header-link-list > li:last-child {
  border-bottom: 1px solid #cdd9e1;
}

.navbar {
  margin-bottom: 0;
  padding: 10px 15px;
}
.navbar .nav-dropdown .container {
  display: block;
}
.navbar .btn,
.navbar .btn:hover,
.navbar .btn:active {
  border: 1px solid transparent;
}
.navbar.intranet .navbar-right {
  display: block;
}
.navbar.intranet .navbar-toggler {
  display: none;
}

.navbar-light {
  background: rgba(255, 255, 255, 0.92);
}
.navbar-light:hover, .navbar-light:focus {
  background: #fff;
}
.navbar-light .navbar-nav .nav-link {
  color: #788791;
}
.navbar-light .navbar-nav > .show > a, .navbar-light .navbar-nav > .show > a:hover, .navbar-light .navbar-nav > .show > a:focus {
  border-color: transparent;
}
.navbar-light .navbar-nav > .nav-dropdown {
  position: static;
}

.navbar-collapse {
  padding-left: 15px;
  padding-right: 15px;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.1);
}

.navbar-toggler {
  border-style: none;
  min-width: 40px;
  padding: 0 7px;
  margin-left: 5px;
  height: 40px;
  background-color: #007373;
  color: #fff;
  font-size: 21px;
}
.navbar-header-justified .navbar-toggler {
  left: -20px;
}
.navbar-toggler:nth-last-of-type(2) {
  margin-left: 5px;
  padding: inherit;
  background: transparent;
}
.navbar-toggler:nth-last-of-type(2):hover {
  background-color: #007373;
  color: #fff;
}

.navbar-toggler-icon {
  background-color: #fff;
  height: 3px;
  display: block;
  width: 24px;
  border-radius: 1px;
  margin-top: 4px;
  margin-bottom: 4px;
}

main {
  margin-top: 58px;
}

.navbar.fixed-top {
  border-width: 0 0 2px;
}

.navbar-brand {
  width: 142px;
  align-self: flex-start;
  margin: auto 0;
}
.navbar-brand > .logo-claim {
  display: none;
  transition: opacity 0.3s linear 0.3s;
  max-height: 29px;
}

.logo {
  height: 19px;
}

.site-identifier {
  display: block;
  width: 100%;
  padding: 12px 15px;
  margin-top: 2px;
}

.navbar-underline {
  border-top: 4px solid #099;
  position: absolute;
  bottom: 0;
  opacity: 1;
  transition: left 0.5s, width 0.5s, opacity 0.2s linear 0.5s;
  display: none;
}

.mobile-background {
  background: rgba(45, 55, 60, 0.75);
  margin: 0;
  height: 100vh;
  position: fixed;
  top: 62px;
  right: 0;
  bottom: 0;
  left: 0;
  transition: padding-top 0s linear 0s;
}

.nav-mobile {
  background-color: #ebf0f5;
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
}

.phone-collapse {
  display: none;
}

.no-transition {
  transition: height 0.01s;
}

.has-navbar-fixed-top {
  margin-top: 50px;
}

.nav-dropdown-menu {
  display: none;
  z-index: 1000;
  text-align: left;
}

.navbar-nav {
  font-family: "Siemens Sans";
  width: max-content;
}
.navbar-nav > .nav-item > .nav-link {
  display: flex;
  align-items: center;
}
.navbar-nav > .nav-item > .nav-link:hover {
  text-decoration: none;
}
.navbar-nav .nav-item.nav-icon > .nav-link {
  min-width: inherit;
}
.navbar-nav.navbar-primary {
  opacity: 1;
  width: 100%;
  height: 100%;
  padding: 20px 15px;
  margin-top: 0;
  transition: opacity 0.3s linear 0.3s, visibility 0s linear 0.3s, max-height 0.5s linear 0.5s;
  position: relative;
  font-size: 18px;
  visibility: visible;
  max-height: none;
  background: #fff;
}
.navbar-nav.navbar-primary .header-link-list {
  margin-bottom: -1px;
}
.navbar-nav.navbar-primary .nav-dropdown.open > .nav-dropdown-menu {
  min-height: 100%;
}
.navbar-nav.navbar-primary .nav-dropdown.open > .nav-dropdown-menu div[class*=col-] {
  padding: 0;
}
.navbar-nav.navbar-primary .nav-item {
  border-top: 1px solid #cdd9e1;
  font-size: 0.875rem;
}
.navbar-nav.navbar-primary .nav-item:hover {
  background: #dfe6ed;
  text-decoration: underline;
}
.navbar-nav.navbar-primary .nav-item:last-child {
  border-bottom: 1px solid #cdd9e1;
}
.navbar-nav.navbar-primary .nav-item .nav-link {
  justify-content: space-between;
  align-items: center;
  width: 100%;
  color: #3c464b;
  padding-left: 0.938rem;
  padding-right: 0.938rem;
}
.navbar-nav.navbar-primary .nav-item .nav-link.active {
  color: #3c464b;
}
.navbar-nav.navbar-primary .nav-item .nav-dropdown-menu .container {
  width: inherit;
  display: block;
}
.navbar-nav.navbar-primary .nav-item .nav-dropdown-toggle::after {
  content: "";
}
.navbar-nav.navbar-primary > .nav-dropdown > .nav-dropdown-menu {
  position: absolute;
  top: 0;
  padding: 20px 0;
  margin: auto;
}
.navbar-nav.navbar-primary > .nav-dropdown > .nav-dropdown-menu .header-link-list {
  padding: 0;
}
.navbar-nav.navbar-primary > .nav-dropdown > .nav-dropdown-menu div[class*=col-] {
  width: 100%;
}
.navbar-nav.navbar-right {
  margin-left: auto;
  margin-right: 0;
  flex-direction: row;
}
.navbar-nav.navbar-right > .nav-item {
  position: relative;
  margin: 0 5px;
  display: inline-flex;
}
.navbar-nav.navbar-right > .nav-item i,
.navbar-nav.navbar-right > .nav-item em {
  font-size: 21px;
}
.navbar-nav.navbar-right > .nav-item:hover > .nav-link {
  color: #50bed7;
}
.navbar-nav.navbar-right > .nav-item > .nav-link {
  border: 0;
  border-bottom: 0 solid transparent;
  padding-top: 0;
  padding-bottom: 0;
}
.navbar-nav.navbar-right > .nav-item > .nav-link > span {
  display: none;
  max-width: 300px;
  opacity: 1;
  white-space: nowrap;
  transition: max-width 0.3s linear 0.3s, opacity 0.15s linear 0.3s;
}
.navbar-nav.navbar-right > .nav-item > .nav-link > .user-profile {
  width: 25px;
  height: 20px;
  overflow: visible;
  margin-right: 8px;
}
.navbar-nav.navbar-right > .nav-item > .nav-link > .user-profile > img {
  pointer-events: none;
  border-radius: 100%;
  border: 1px solid #788791;
  width: 25px;
  height: 25px;
  margin-right: 8px;
  margin-left: 1px;
  position: absolute;
}
.navbar-nav.navbar-right > .nav-item > .nav-link > .user-profile:hover > img {
  border: 2px solid #50b6d3;
  width: 27px;
  height: 27px;
  margin: -1px 7px -1px 0;
}
.navbar-nav.navbar-right > .nav-dropdown > .nav-dropdown-menu {
  top: 62px;
  min-height: 0;
}
.navbar-nav.navbar-right > .nav-dropdown > .nav-dropdown-menu ul.header-link-list {
  padding: 0 0 20px;
}
.navbar-nav.navbar-right > .nav-dropdown > .nav-dropdown-menu ul.header-link-list > li {
  border: 0;
}
.navbar-nav.navbar-right > .nav-dropdown > .nav-dropdown-menu ul.header-link-list > li > a {
  padding: 0;
}
.navbar-nav.navbar-right > .nav-dropdown > .nav-dropdown-menu ul.header-link-list > li :hover {
  background: none;
}
.navbar-nav .application-title {
  margin: 0 -2px 0 0;
  padding: 0 18px 0 0;
  font-size: 20px;
  line-height: 24px;
  border-right: 1px solid #697882;
  color: #697882;
  display: none;
}
.navbar-nav .nav-dropdown .nav-dropdown-toggle::after {
  font-family: "simpl-icons-newton";
  content: "";
  font-size: 14px;
  float: right;
}
.navbar-nav .nav-dropdown .nav-dropdown-menu {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 128px;
  right: 0;
  left: auto;
  margin: 0;
  background-color: #fff;
  color: #697882;
  border: 0;
  border-bottom: 2px solid #dfe6ed;
  box-shadow: none;
}
.navbar-nav .nav-dropdown .nav-dropdown-menu h1, .navbar-nav .nav-dropdown .nav-dropdown-menu .h1d {
  font-size: 24px;
  line-height: 26px;
  margin-bottom: 15px;
}
.navbar-nav .nav-dropdown .nav-dropdown-menu h2, .navbar-nav .nav-dropdown .nav-dropdown-menu .h2d {
  font-size: 20px;
  line-height: 20px;
  margin-bottom: 12px;
}
.navbar-nav .nav-dropdown .nav-dropdown-menu h3, .navbar-nav .nav-dropdown .nav-dropdown-menu .h3d {
  font-size: 18px;
  line-height: 18px;
}
.navbar-nav .nav-dropdown .nav-dropdown-menu h4, .navbar-nav .nav-dropdown .nav-dropdown-menu .h4d, .navbar-nav .nav-dropdown .nav-dropdown-menu .alert,
.navbar-nav .nav-dropdown .nav-dropdown-menu .alert-dismissible {
  line-height: 16px;
  font-size: 16px;
}
.navbar-nav .nav-dropdown .nav-dropdown-menu h5, .navbar-nav .nav-dropdown .nav-dropdown-menu .h5d {
  font-size: 15px;
  font-weight: bold;
}
.navbar-nav .nav-dropdown .nav-dropdown-menu h6 {
  font-size: 14px;
  font-weight: bold;
}
.navbar-nav > .nav-dropdown > .nav-dropdown-toggle::after {
  content: none;
  border: 0;
}

.navbar-search-placeholder {
  transition: width 0.1s linear 0.3s;
  width: 0;
  display: inline-block;
}

.navbar-search {
  position: fixed;
  width: 100%;
  top: 5px;
  transition: top 0.1s linear 0s;
}
.navbar-search input[type=search] {
  position: relative;
  display: inline-block;
  height: 30px;
  padding-right: 40px;
  width: 83%;
  transition: margin-left 0s linear 0s;
  text-overflow: ellipsis;
  margin: 20px 20px 20px 13px;
}
.navbar-search input[type=search] ~ button.search-button {
  position: relative;
  background: transparent;
  right: 60px;
  bottom: 0;
  border: 0;
  margin-right: 1px;
  margin-top: -29px;
  width: 30px;
  height: 28px;
  line-height: 28px;
  color: #788791;
  font-size: 18px;
  overflow: hidden;
  padding: 0;
}
.navbar-search input[type=search] ~ button.search-button > span.newton-search {
  display: inline-block;
}
.navbar-search input[type=search] ~ button.search-button > span {
  width: 30px;
  text-align: end;
}
.navbar-search input[type=search] ~ button.search-button > span::before {
  position: relative;
  right: 6px;
  top: -1px;
}
.navbar-search input[type=search] ~ button.search-button:hover {
  background: #788791;
  color: #fff;
}
.navbar-search input[type=search] ~ button.search-button:focus {
  outline: 0;
}

.navbar.navbar-light.fixed-top {
  border-bottom: 2px solid #dfe6ed;
}
.navbar.navbar-light.fixed-top .navbar-collapse .navbar-search-content {
  position: fixed;
  top: 54px;
  background-color: #fff;
  left: 0;
  width: 100%;
  height: calc(100vh - 57px);
}

@media (min-width: 576px) {
  .phone-collapse {
    display: flex;
    align-items: center;
    flex-flow: row;
  }

  .navbar.navbar-light.fixed-top .navbar-collapse .navbar-search-content {
    left: 33vw;
    width: 67%;
  }

  .navbar-nav.navbar-right > .nav-dropdown > .nav-dropdown-menu {
    width: 67vw;
  }

  .nav-mobile {
    width: 67%;
  }
}
@media (min-width: 992px) {
  #navbar {
    position: relative;
    padding-top: 0;
    align-self: flex-start;
  }

  .navbar {
    padding-bottom: 0;
  }

  .navbar-brand {
    padding-bottom: 0;
    margin-top: 0;
    margin-bottom: 2px;
  }
  .navbar-brand > .logo,
.navbar-brand .logo-claim {
    margin-left: 0;
    margin-right: auto;
    display: block;
  }
  .navbar-brand > .logo.logo-claim,
.navbar-brand .logo-claim.logo-claim {
    display: block;
  }

  ul.nav > li.nav-dropdown:hover > ul.nav-dropdown-menu, ul.sidebar > li.nav-dropdown:hover > ul.nav-dropdown-menu, ul.form-header > li.nav-dropdown:hover > ul.nav-dropdown-menu, ul.form-footer > li.nav-dropdown:hover > ul.nav-dropdown-menu {
    display: block;
  }

  .navbar-brand > img.logo-claim {
    display: inline-block;
  }

  main {
    margin-top: 120px;
  }

  .site-identifier {
    display: none;
  }

  .mobile-background {
    position: relative;
    height: auto;
    top: auto;
    background: none;
  }

  .nav-mobile {
    position: relative;
    background-color: inherit;
    width: auto;
  }

  .navbar-underline {
    display: block;
  }

  .navbar-nav.navbar-primary {
    flex-wrap: nowrap;
    padding: 0;
    visibility: visible;
    background: none;
  }
  .navbar-nav.navbar-primary .header-link-list {
    margin-bottom: -1px;
  }
  .navbar-nav.navbar-primary .nav-dropdown.open > .nav-dropdown-menu {
    min-height: 100%;
  }
  .navbar-nav.navbar-primary .nav-dropdown.open > .nav-dropdown-menu div[class*=col-] {
    padding: 0;
  }
  .navbar-nav.navbar-primary > .nav-item {
    display: block;
    border-top: 0;
    line-height: 18px;
    color: #788791;
    font-size: 18px;
    padding-top: 20px;
    padding-right: 20px;
    z-index: 2;
    margin-bottom: -4px;
    padding-bottom: 4px;
    border-bottom: 4px solid rgba(0, 0, 0, 0) !important;
  }
  .navbar-nav.navbar-primary > .nav-item:hover {
    background: none;
    text-decoration: none;
  }
  .navbar-nav.navbar-primary > .nav-item:last-child {
    border-bottom: 0;
  }
  .navbar-nav.navbar-primary > .nav-item > .nav-link {
    padding: 0 0 10px;
    color: inherit;
  }
  .navbar-nav.navbar-primary > .nav-item > .nav-link:hover {
    text-decoration: none;
  }
  .navbar-nav.navbar-primary > .nav-item > .nav-link.active {
    color: #3c464b;
  }
  .navbar-nav.navbar-primary > .nav-item .nav-dropdown-menu {
    padding: 20px;
    height: auto;
    background: #ebf0f5;
  }
  .navbar-nav.navbar-primary > .nav-item .nav-dropdown-menu .container {
    width: inherit;
  }
  .navbar-nav.navbar-primary > .nav-item .nav-dropdown-toggle::after {
    content: "";
  }
  .navbar-nav.navbar-primary > .nav-dropdown > .nav-dropdown-menu {
    position: fixed;
    top: 124px;
  }
  .navbar-nav.navbar-primary > .nav-dropdown > .nav-dropdown-menu .header-link-list {
    padding: 70px 0 50px;
  }
  .navbar-nav.navbar-right {
    height: 37px;
  }
  .navbar-nav.navbar-right > .nav-item i,
.navbar-nav.navbar-right > .nav-item em {
    font-size: 16px;
    margin: 0;
  }
  .navbar-nav.navbar-right > .nav-item > .nav-link {
    margin-left: 10px;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
  }
  .navbar-nav.navbar-right > .nav-item > .nav-link > span {
    display: inline-flex;
    margin-left: 4px;
    overflow: hidden;
  }
  .navbar-nav.navbar-right > .nav-item > .nav-link > .user-profile {
    margin-right: 0;
    margin-top: -3px;
  }
  .navbar-nav.navbar-right > .nav-item:hover > .nav-link, .navbar-nav.navbar-right > .nav-item.active > .nav-link {
    color: #50bed7;
    z-index: 10002;
  }
  .navbar-nav.navbar-right > .nav-dropdown:not(:last-child) > .nav-dropdown-menu {
    left: -20px;
    right: auto;
  }
  .navbar-nav.navbar-right > .nav-dropdown:last-child > .nav-dropdown-menu {
    left: auto;
    right: -30px;
  }
  .navbar-nav.navbar-right > .nav-dropdown > .nav-dropdown-menu {
    position: absolute;
    padding: 32px 15px 0;
    top: -5px;
    width: 260px;
    height: auto;
    border: 1px solid #cdd9e1;
  }
  .navbar-nav.navbar-right > .nav-dropdown > .nav-dropdown-menu.nav-dropdown-open-right {
    left: -20px;
    right: auto;
  }
  .navbar-nav.navbar-right > .nav-dropdown > .nav-dropdown-menu.nav-dropdown-open-left {
    left: auto;
    right: -35px;
  }
  .navbar-nav .application-title {
    display: inline-flex;
  }

  .navbar-search {
    top: 73px;
    width: 350px;
    right: 0;
    display: inline-block;
    position: absolute;
    margin-right: 15px;
  }
  .navbar-search .collapse {
    display: block;
  }
  .navbar-search input[type=search] {
    width: 350px;
    margin: auto;
  }
  .navbar-search input[type=search] ~ button.search-button {
    position: absolute;
    background: transparent;
    right: 5px;
    bottom: 2px;
  }

  .navbar.navbar-light.fixed-top {
    min-height: 120px;
    transition: min-height 0.3s linear 0.5s;
  }
  .navbar.navbar-light.fixed-top .navbar-collapse .navbar-search-content {
    position: relative;
    top: auto;
    left: 0;
    width: auto;
    height: auto;
  }
  .navbar.navbar-light.fixed-top.affix {
    min-height: 62px;
    transition: min-height 0.3s linear 0.1s;
  }
  .navbar.navbar-light.fixed-top.affix .navbar-brand .logo-claim {
    transition: opacity 0.3s linear;
    opacity: 0;
    position: absolute;
  }
  .navbar.navbar-light.fixed-top.affix .navbar-underline {
    opacity: 0;
  }
  .navbar.navbar-light.fixed-top.affix .navbar-primary {
    display: none;
    opacity: 0;
  }
  .navbar.navbar-light.fixed-top.affix .navbar-search-placeholder {
    transition: width 0.1s;
    width: 350px;
  }
  .navbar.navbar-light.fixed-top.affix .navbar-search {
    top: 5px;
  }
  .navbar.navbar-light.fixed-top.affix .navbar-search input[type=search] {
    margin-left: 0;
    transition: margin-left 0.3s linear 0.1s;
  }
  .navbar.navbar-light.fixed-top.affix ul.navbar-nav.navbar-right > .nav-item > .nav-link > span {
    max-width: 0;
    opacity: 0;
  }
  .navbar.navbar-light.fixed-top.intranet {
    min-height: 62px;
  }

  .nav-dropdown-right-divider {
    border: 0.5px solid #dfe6ed;
    margin-bottom: 20px;
    margin-top: 20px;
  }
}
.n9, ul.header-link-list > li > a {
  line-height: 20px;
  color: #2d373c;
}
.n9 :hover, ul.header-link-list > li > a :hover {
  color: #005578;
  text-decoration: underline;
}

.n12, .site-identifier {
  font-family: "Siemens Sans";
  color: #879baa;
}

ul.default-link-list {
  list-style: none;
  padding-left: 0;
}
ul.default-link-list > li {
  line-height: 16px;
  vertical-align: bottom;
}
ul.default-link-list > li > a {
  color: #697882;
  line-height: 20px;
  padding-right: 10px;
  text-decoration: none;
}
ul.default-link-list > li > a:hover {
  color: #005578;
  text-decoration: underline;
}
ul.default-link-list > li > a:active {
  color: #005578;
}
ul.default-link-list > li > a > span {
  text-decoration: none;
  border: 0;
}

.btn.btn-intranet {
  background: linear-gradient(to right, #50bebe 0%, #099 50%, #0099b0 83%, #0099cb 100%);
  color: #fff;
  margin-left: 20px;
  padding: 5px 10px;
}
.btn.btn-intranet::before {
  content: "";
  font-family: "simpl-icons-newton";
  vertical-align: inherit;
  padding-right: 10px;
}

header.dark .navbar.navbar-light.fixed-top {
  background: #3c464b;
}
header.dark .navbar.navbar-light.fixed-top .navbar-underline {
  border-top-color: #fff;
}
header.dark .navbar.navbar-light.fixed-top .nav-dropdown-menu {
  background: #3c464b;
}
@media (max-width: 1024px) {
  header.dark .navbar.navbar-light.fixed-top .navbar-primary {
    background: #3c464b;
  }
  header.dark .navbar.navbar-light.fixed-top ul.navbar-nav.navbar-right > li > a:hover {
    background-color: #cdd9e1;
    color: #697882;
  }
  header.dark .navbar.navbar-light.fixed-top ul.navbar-nav.navbar-right > li > button.navbar-search-button {
    border: 1px solid #fff;
  }
  header.dark .navbar.navbar-light.fixed-top ul.navbar-nav.navbar-right > li > button.navbar-search-button.collapsed {
    background-color: transparent;
    color: #fff;
    border: 0;
  }
  header.dark .navbar.navbar-light.fixed-top ul.navbar-nav.navbar-right > li > button.navbar-search-button.collapsed:hover {
    background-color: #cdd9e1;
    color: #697882;
  }
  header.dark .navbar.navbar-light.fixed-top ul.navbar-nav.navbar-right > li > button.navbar-toggler {
    background-color: transparent;
    border: 1px solid #fff;
  }
  header.dark .navbar.navbar-light.fixed-top ul.navbar-nav.navbar-right > li > button.collapsed {
    background-color: #fff;
    color: #3c464b;
  }
  header.dark .navbar.navbar-light.fixed-top ul.navbar-nav.navbar-right > li > button.collapsed .icon-bar {
    background-color: #3c464b;
  }
}
header.dark .navbar.navbar-light.fixed-top .navbar-nav .nav-item .nav-link {
  color: #fff;
}
header.dark .navbar.navbar-light.fixed-top .navbar-nav .nav-item:hover .nav-link, header.dark .navbar.navbar-light.fixed-top .navbar-nav .nav-item.active .nav-link {
  color: #cdd9e1;
}
header.dark .navbar.navbar-light.fixed-top .navbar-nav ul.dropdown-menu .nav-item .nav-link {
  color: #2d373c;
}
header.dark .navbar.navbar-light.fixed-top ul.navbar-nav.navbar-right > .nav-item:hover > .nav-link {
  border-bottom-color: #fff;
}
header.dark ul.header-link-list > li > a {
  color: #fff;
}
header.dark ul.header-link-list > li > a:hover {
  color: #cdd9e1;
}
header.dark .nav-dropdown-right-divider {
  border-color: #fff;
}
header.dark .navbar-light.fixed-top .navbar-collapse {
  box-shadow: none;
}
header.dark .navbar-nav .application-title {
  color: #fff;
  border-right-color: #fff;
}
header.dark .btn.btn-intranet {
  color: #3c464b;
  background: #fff;
}
@media (max-width: 991.98px) {
  header.dark .navbar-primary.navbar-nav .nav-item:hover, header.dark .navbar-primary.navbar-nav .nav-item.active {
    background: #3c464b;
  }
}
header.dark .navbar-primary.navbar-nav ul.header-link-list > li > a:hover, header.dark .navbar-primary.navbar-nav ul.header-link-list > li > a.active {
  background: #3c464b;
}

header.newton .navbar.navbar-light.fixed-top {
  background: linear-gradient(to right, #50bebe 0%, #099 50%, #0099b0 83%, #0099cb 100%);
}
header.newton .navbar.navbar-light.fixed-top .navbar-underline {
  border-top-color: #fff;
}
header.newton .navbar.navbar-light.fixed-top .nav-dropdown-menu {
  background: linear-gradient(to right, #50bebe 0%, #099 50%, #0099b0 83%, #0099cb 100%);
}
@media (max-width: 1024px) {
  header.newton .navbar.navbar-light.fixed-top .navbar-primary {
    background: linear-gradient(to right, #50bebe 0%, #099 50%, #0099b0 83%, #0099cb 100%);
  }
  header.newton .navbar.navbar-light.fixed-top ul.navbar-nav.navbar-right > li > a:hover {
    background-color: #fff;
    color: #099;
  }
  header.newton .navbar.navbar-light.fixed-top ul.navbar-nav.navbar-right > li > button.navbar-search-button {
    border: 1px solid #fff;
  }
  header.newton .navbar.navbar-light.fixed-top ul.navbar-nav.navbar-right > li > button.navbar-search-button.collapsed {
    background-color: transparent;
    color: #fff;
    border: 0;
  }
  header.newton .navbar.navbar-light.fixed-top ul.navbar-nav.navbar-right > li > button.navbar-search-button.collapsed:hover {
    background-color: #fff;
    color: #099;
  }
  header.newton .navbar.navbar-light.fixed-top ul.navbar-nav.navbar-right > li > button.navbar-toggler {
    background-color: transparent;
    border: 1px solid #fff;
  }
  header.newton .navbar.navbar-light.fixed-top ul.navbar-nav.navbar-right > li > button.collapsed {
    background-color: #fff;
    color: #3c464b;
  }
  header.newton .navbar.navbar-light.fixed-top ul.navbar-nav.navbar-right > li > button.collapsed .icon-bar {
    background-color: #099;
  }
}
header.newton .navbar.navbar-light.fixed-top .navbar-nav .nav-item .nav-link {
  color: #fff;
}
header.newton .navbar.navbar-light.fixed-top .navbar-nav .nav-item:hover .nav-link, header.newton .navbar.navbar-light.fixed-top .navbar-nav .nav-item.active .nav-link {
  color: #cdd9e1;
}
header.newton .navbar.navbar-light.fixed-top .navbar-nav ul.dropdown-menu .nav-item .nav-link {
  color: #2d373c;
}
header.newton .navbar.navbar-light.fixed-top ul.navbar-nav.navbar-right > .nav-item:hover > .nav-link {
  border-bottom-color: #fff;
}
header.newton ul.header-link-list > li > a {
  color: #fff;
}
header.newton ul.header-link-list > li > a:hover {
  color: #cdd9e1;
}
header.newton .nav-dropdown-right-divider {
  border-color: #fff;
}
header.newton .navbar-light.fixed-top .navbar-collapse {
  box-shadow: none;
}
header.newton .navbar-nav .application-title {
  color: #fff;
  border-right-color: #fff;
}
header.newton .btn.btn-intranet {
  color: #099;
  background: #fff;
}
@media (max-width: 991.98px) {
  header.newton .navbar-primary.navbar-nav .nav-item:hover, header.newton .navbar-primary.navbar-nav .nav-item.active {
    background: linear-gradient(to right, #50bebe 0%, #099 50%, #0099b0 83%, #0099cb 100%);
  }
}
header.newton .navbar-primary.navbar-nav ul.header-link-list > li > a:hover, header.newton .navbar-primary.navbar-nav ul.header-link-list > li > a.active {
  background: linear-gradient(to right, #50bebe 0%, #099 50%, #0099b0 83%, #0099cb 100%);
}

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0;
}
.card > hr {
  margin-right: 0;
  margin-left: 0;
}
.card > .list-group, .card > .filter-group {
  border-top: inherit;
  border-bottom: inherit;
}
.card > .list-group:first-child, .card > .filter-group:first-child {
  border-top-width: 0;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.card > .list-group:last-child, .card > .filter-group:last-child {
  border-bottom-width: 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.card > .card-header + .list-group, .card > .card-header + .filter-group,
.card > .list-group + .card-footer,
.card > .filter-group + .card-footer {
  border-top: 0;
}

.card-body {
  flex: 1 1 auto;
  min-height: 1px;
  padding: 1.25rem;
}

.card-title {
  margin-bottom: 0.75rem;
}

.card-subtitle {
  margin-top: -0.375rem;
  margin-bottom: 0;
}

.card-text:last-child {
  margin-bottom: 0;
}

.card-link:hover {
  text-decoration: none;
}
.card-link + .card-link {
  margin-left: 1.25rem;
}

.card-header {
  padding: 0.75rem 1.25rem;
  margin-bottom: 0;
  background-color: rgba(0, 0, 0, 0.03);
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
}
.card-header:first-child {
  border-radius: 0 0 0 0;
}

.card-footer {
  padding: 0.75rem 1.25rem;
  background-color: rgba(0, 0, 0, 0.03);
  border-top: 1px solid rgba(0, 0, 0, 0.125);
}
.card-footer:last-child {
  border-radius: 0 0 0 0;
}

.card-header-tabs {
  margin-right: -0.625rem;
  margin-bottom: -0.75rem;
  margin-left: -0.625rem;
  border-bottom: 0;
}

.card-header-pills {
  margin-right: -0.625rem;
  margin-left: -0.625rem;
}

.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1.25rem;
  border-radius: 0;
}

.card-img,
.card-img-top,
.card-img-bottom {
  flex-shrink: 0;
  width: 100%;
}

.card-img,
.card-img-top {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.card-img,
.card-img-bottom {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.card-deck .card {
  margin-bottom: 15px;
}
@media (min-width: 576px) {
  .card-deck {
    display: flex;
    flex-flow: row wrap;
    margin-right: -15px;
    margin-left: -15px;
  }
  .card-deck .card {
    flex: 1 0 0%;
    margin-right: 15px;
    margin-bottom: 0;
    margin-left: 15px;
  }
}

.card-group > .card {
  margin-bottom: 15px;
}
@media (min-width: 576px) {
  .card-group {
    display: flex;
    flex-flow: row wrap;
  }
  .card-group > .card {
    flex: 1 0 0%;
    margin-bottom: 0;
  }
  .card-group > .card + .card {
    margin-left: 0;
    border-left: 0;
  }
  .card-group > .card:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  .card-group > .card:not(:last-child) .card-img-top,
.card-group > .card:not(:last-child) .card-header {
    border-top-right-radius: 0;
  }
  .card-group > .card:not(:last-child) .card-img-bottom,
.card-group > .card:not(:last-child) .card-footer {
    border-bottom-right-radius: 0;
  }
  .card-group > .card:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  .card-group > .card:not(:first-child) .card-img-top,
.card-group > .card:not(:first-child) .card-header {
    border-top-left-radius: 0;
  }
  .card-group > .card:not(:first-child) .card-img-bottom,
.card-group > .card:not(:first-child) .card-footer {
    border-bottom-left-radius: 0;
  }
}

.card-columns .card {
  margin-bottom: 0.75rem;
}
@media (min-width: 576px) {
  .card-columns {
    column-count: 3;
    column-gap: 1.25rem;
    orphans: 1;
    widows: 1;
  }
  .card-columns .card {
    display: inline-block;
    width: 100%;
  }
}

.accordion {
  overflow-anchor: none;
}
.accordion > .card {
  overflow: hidden;
}
.accordion > .card:not(:last-of-type) {
  border-bottom: 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.accordion > .card:not(:first-of-type) {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.accordion > .card > .card-header {
  border-radius: 0;
  margin-bottom: -1px;
}

.card {
  border-radius: 0;
  border: 0;
  padding: 0;
  box-shadow: none;
  background-color: #ebf0f5;
}
.card img {
  border-radius: 0;
}
.card .caption > *:first-child {
  margin-top: 0;
  padding-top: 0;
  line-height: normal;
}
.card .card:first-child .card-header {
  margin-top: 0;
}
.card .card-body p, .card .card-body .p2, .card .card-body .p2d, .card .card-body .ps, .card .card-body .psd,
.card .card-body .p3,
.card .card-body .p3d, .card .card-body .p1, .card .card-body .p1d, .card .card-body .pd {
  margin: 0;
}

.accordion > div.card,
accordion > accordion-group > div.card {
  background-color: #fff;
}
.accordion > div.card > .card-header,
accordion > accordion-group > div.card > .card-header {
  color: #2d373c;
  background-color: #fff;
  font-weight: inherit;
  padding: 1.25rem 0.9375rem 1.25rem 0;
  border-bottom: initial;
  border-top: 1px solid rgba(45, 55, 60, 0.5);
  cursor: pointer;
}
.accordion > div.card > .card-header:hover,
accordion > accordion-group > div.card > .card-header:hover {
  color: #2d373c;
}
.accordion > div.card > .card-header .card-title,
accordion > accordion-group > div.card > .card-header .card-title {
  display: inline-block;
  margin: 0;
  position: relative;
  font-size: 20px;
  width: 100%;
  text-align: left;
  box-shadow: none;
  line-height: 26px;
  opacity: 0.8;
}
.accordion > div.card > .card-header .card-title:hover, .accordion > div.card > .card-header .card-title:active,
accordion > accordion-group > div.card > .card-header .card-title:hover,
accordion > accordion-group > div.card > .card-header .card-title:active {
  opacity: 1;
}
.accordion > div.card > .card-header .card-title::after,
accordion > accordion-group > div.card > .card-header .card-title::after {
  content: "";
  font-family: "simpl-icons-newton";
  position: absolute;
  transition: transform 0.2s ease-in 0s;
  will-change: transform;
  top: calc(40% - 10px);
  font-size: 1.125rem;
  right: 0;
  opacity: 0.8;
  transform: rotate(-45deg);
}
.accordion > div.card > .card-header.collapsed .card-title::after,
accordion > accordion-group > div.card > .card-header.collapsed .card-title::after {
  transform: rotate(0deg);
}
.accordion > div.card > .card-body,
accordion > accordion-group > div.card > .card-body {
  padding: 1.25rem 0 2.5rem;
}
.accordion accordion-group:last-child > div.card .card-header,
accordion > accordion-group accordion-group:last-child > div.card .card-header {
  border-bottom: 1px solid rgba(45, 55, 60, 0.5);
}
.accordion .active.card-header,
accordion > accordion-group .active.card-header {
  background-color: transparent;
}

.breadcrumb {
  display: flex;
  flex-wrap: wrap;
  padding: 20px 0;
  margin-bottom: 1rem;
  list-style: none;
  background-color: transparent;
  border-radius: 0;
}

.breadcrumb-item + .breadcrumb-item {
  padding-left: 0.5rem;
}
.breadcrumb-item + .breadcrumb-item::before {
  float: left;
  padding-right: 0.5rem;
  color: #6c757d;
  content: "/";
}
.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: underline;
}
.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: none;
}
.breadcrumb-item.active {
  color: #005578;
}

.breadcrumb {
  border-radius: 0;
}
.breadcrumb > .breadcrumb-item {
  display: inline-flex;
  align-items: flex-end;
}
.breadcrumb > .breadcrumb-item + .breadcrumb-item::before {
  content: "/";
  padding-left: 5px;
  padding-right: 5px;
}
.breadcrumb > .breadcrumb-item:last-child > a {
  color: #005578;
  font-weight: inherit;
}
.breadcrumb > .active {
  font-weight: inherit;
}

.breadcrumb > .breadcrumb-item > a {
  color: #96a0aa;
}
.breadcrumb > .breadcrumb-item > a:hover {
  color: #005578;
}
.breadcrumb > .breadcrumb-item > a .icon {
  font-size: inherit;
}

.breadcrumb {
  margin: 0 -5px;
}
.breadcrumb .newton-home {
  position: relative;
  top: -1px;
}
.breadcrumb > .breadcrumb-item {
  padding-left: 10px;
}
.breadcrumb > .breadcrumb-item > a {
  text-decoration: none;
}
.breadcrumb > .breadcrumb-item + .breadcrumb-item::before {
  content: none;
}
.breadcrumb > .dropdown > .dropdown-toggle::after {
  font-family: "simpl-icons-newton";
  content: "";
  position: relative;
  vertical-align: initial;
  top: 2px;
  left: 5px;
  border-top: 0;
}
.breadcrumb > .dropdown.open > .dropdown-toggle::after {
  font-family: "simpl-icons-newton";
  content: "";
}
.breadcrumb .dropdown-menu > li > a {
  padding: 3px 40px 3px 10px;
}
.breadcrumb .dropdown-menu > li > a:hover {
  background-color: #005578;
  cursor: pointer;
  color: #fff;
  padding: 2px 26px 2px 10px;
}
.breadcrumb .dropdown-menu > li > a:hover::before {
  font-family: "simpl-icons-newton";
  content: "";
  position: relative;
  top: 2px;
}
.breadcrumb .dropdown-menu > li.dropdown-header {
  padding: 10px;
}

.breadcrumb > .dropdown.dropdown-toggle::after {
  font-family: "simpl-icons-newton";
  content: "";
}

@media (max-width: 1024px) {
  .breadcrumb {
    margin: 0 -10px;
  }
}
.pagination {
  display: flex;
  padding-left: 0;
  list-style: none;
  border-radius: 0;
}

.page-link {
  position: relative;
  display: block;
  padding: 0 0;
  margin-left: -1px;
  line-height: 1.25;
  color: #005578;
  background-color: transparent;
  border: 1px solid 0;
}
.page-link:hover {
  z-index: 2;
  color: #fff;
  text-decoration: none;
  background-color: #005578;
  border-color: #dee2e6;
}
.page-link:focus {
  z-index: 3;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(250, 165, 10, 0.25);
}

.page-item:first-child .page-link {
  margin-left: 0;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.page-item:last-child .page-link {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.page-item.active .page-link {
  z-index: 3;
  color: #fff;
  background-color: #005578;
  border-color: #005578;
}
.page-item.disabled .page-link {
  color: #6c757d;
  pointer-events: none;
  cursor: auto;
  background-color: #fff;
  border-color: #dee2e6;
}

.pagination-lg .page-link {
  padding: 0.75rem 1.5rem;
  font-size: 1.09375rem;
  line-height: 1.5;
}
.pagination-lg .page-item:first-child .page-link {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.pagination-lg .page-item:last-child .page-link {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.pagination-sm .page-link {
  padding: 0.25rem 0.5rem;
  font-size: 0.765625rem;
  line-height: 1.5;
}
.pagination-sm .page-item:first-child .page-link {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.pagination-sm .page-item:last-child .page-link {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.pagination > li > a,
.pagination > li > span {
  padding: 0 0;
  width: 20px;
  height: 20px;
  text-align: center;
  border: 0;
  display: table-cell;
  text-decoration: none;
}
.pagination > li > a:hover, .pagination > li > a:focus,
.pagination > li > span:hover,
.pagination > li > span:focus {
  color: #fff;
  background-color: #005578;
  border-color: #dee2e6;
}
.pagination > .active > a, .pagination > .active > a:hover, .pagination > .active > a:focus,
.pagination > .active > span,
.pagination > .active > span:hover,
.pagination > .active > span:focus {
  color: #fff;
  background-color: #005578;
  border-color: #005578;
}

.badge {
  display: inline-block;
  padding: 0.25em 0.4em;
  font-size: 75%;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .badge {
    transition: none;
  }
}
a.badge:hover, a.badge:focus {
  text-decoration: none;
}

.badge:empty {
  display: none;
}

.btn .badge {
  position: relative;
  top: -1px;
}

.badge-pill {
  padding-right: 0.6em;
  padding-left: 0.6em;
  border-radius: 10rem;
}

.badge-primary {
  color: #fff;
  background-color: #faa50a;
}
a.badge-primary:hover, a.badge-primary:focus {
  color: #fff;
  background-color: #cd8604;
}
a.badge-primary:focus, a.badge-primary.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(250, 165, 10, 0.5);
}

.badge-secondary {
  color: #fff;
  background-color: #2387aa;
}
a.badge-secondary:hover, a.badge-secondary:focus {
  color: #fff;
  background-color: #1a6580;
}
a.badge-secondary:focus, a.badge-secondary.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(35, 135, 170, 0.5);
}

.badge-success {
  color: #fff;
  background-color: #099b00;
}
a.badge-success:hover, a.badge-success:focus {
  color: #fff;
  background-color: #066800;
}
a.badge-success:focus, a.badge-success.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(9, 155, 0, 0.5);
}

.badge-info {
  color: #fff;
  background-color: #697882;
}
a.badge-info:hover, a.badge-info:focus {
  color: #fff;
  background-color: #525e66;
}
a.badge-info:focus, a.badge-info.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(105, 120, 130, 0.5);
}

.badge-warning {
  color: #fff;
  background-color: #eb780a;
}
a.badge-warning:hover, a.badge-warning:focus {
  color: #fff;
  background-color: #ba5f08;
}
a.badge-warning:focus, a.badge-warning.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(235, 120, 10, 0.5);
}

.badge-danger {
  color: #fff;
  background-color: #dc0000;
}
a.badge-danger:hover, a.badge-danger:focus {
  color: #fff;
  background-color: #a90000;
}
a.badge-danger:focus, a.badge-danger.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(220, 0, 0, 0.5);
}

.badge-light {
  color: #fff;
  background-color: #ffb900;
}
a.badge-light:hover, a.badge-light:focus {
  color: #fff;
  background-color: #cc9400;
}
a.badge-light:focus, a.badge-light.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(255, 185, 0, 0.5);
}

.badge-dark {
  color: #fff;
  background-color: #eb780a;
}
a.badge-dark:hover, a.badge-dark:focus {
  color: #fff;
  background-color: #ba5f08;
}
a.badge-dark:focus, a.badge-dark.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(235, 120, 10, 0.5);
}

.badge-inactive {
  color: #fff;
  background-color: #8b8b95;
}
a.badge-inactive:hover, a.badge-inactive:focus {
  color: #fff;
  background-color: #71717c;
}
a.badge-inactive:focus, a.badge-inactive.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(139, 139, 149, 0.5);
}

.jumbotron {
  padding: 10px 5px;
  margin-bottom: 10px;
  color: #fff;
  background-color: #e9ecef;
  border-radius: 0;
}
@media (min-width: 576px) {
  .jumbotron {
    padding: 20px 10px;
  }
}

.jumbotron-fluid {
  padding-right: 0;
  padding-left: 0;
  border-radius: 0;
}

.jumbotron.jumbotron-landing {
  position: relative;
  height: 320px;
  padding: 0;
  background-image: url("~@simpl/newton-bootstrap/src/images/brand-banner.png");
  background-position: center;
}
.jumbotron.jumbotron-landing .navbar {
  border-width: 0;
  z-index: 1000;
}
.jumbotron.jumbotron-landing .navbar > .container,
.jumbotron.jumbotron-landing .navbar > .container-fluid,
.jumbotron.jumbotron-landing .navbar > .container-sm,
.jumbotron.jumbotron-landing .navbar > .container-md,
.jumbotron.jumbotron-landing .navbar > .container-lg,
.jumbotron.jumbotron-landing .navbar > .container-xl {
  padding: 0;
}
.jumbotron.jumbotron-landing .navbar .navbar-brand {
  margin: 0;
  height: 72px;
  padding: 0 0 0 44px;
}
.jumbotron.jumbotron-landing .navbar .navbar-landing {
  margin-top: 30px;
  margin-right: 30px;
}
@media (max-width: 991.98px) {
  .jumbotron.jumbotron-landing .navbar .navbar-landing {
    filter: alpha(opacity=100);
    opacity: 1;
    margin: 0;
    background-color: #333;
  }
}
.jumbotron.jumbotron-landing .navbar .dropdown {
  color: #fff;
}
.jumbotron.jumbotron-landing .navbar .dropdown > .btn {
  width: 180px;
  border: 1px solid #fff;
  background-color: transparent;
  text-align: right;
  color: #fff;
}
@media (max-width: 991.98px) {
  .jumbotron.jumbotron-landing .navbar .dropdown > .btn {
    border-width: 0;
    text-align: left;
    color: #fff;
  }
}
.jumbotron.jumbotron-landing .navbar .dropdown .dropdown-menu {
  margin-top: 0;
  border: 1px solid #fff;
  min-width: 180px;
  text-align: right;
  background-color: transparent;
  border-top-width: 0;
}
@media (max-width: 991.98px) {
  .jumbotron.jumbotron-landing .navbar .dropdown .dropdown-menu {
    text-align: left;
    border-width: 0;
  }
}
.jumbotron.jumbotron-landing .navbar .dropdown .dropdown-menu > li > a {
  color: #fff;
}
@media (max-width: 991.98px) {
  .jumbotron.jumbotron-landing .navbar .dropdown .dropdown-menu > li > a {
    color: #fff;
  }
}
.jumbotron.jumbotron-landing .navbar .dropdown .dropdown-menu > li > a:hover,
.jumbotron.jumbotron-landing .navbar .dropdown .dropdown-menu > li > a:focus {
  background-color: fade(#fff, 30%);
}
.jumbotron.jumbotron-landing .page-slogan,
.jumbotron.jumbotron-landing .page-header {
  margin: 0 0 40px;
  position: absolute;
  right: 0;
  bottom: 0;
  min-width: 50%;
  min-height: 110px;
  background-color: rgba(0, 0, 0, 0.5);
  padding: 30px 30px 25px 25px;
  border: 0;
}
.jumbotron.jumbotron-landing .page-slogan h1, .jumbotron.jumbotron-landing .page-slogan .h1d,
.jumbotron.jumbotron-landing .page-slogan h2,
.jumbotron.jumbotron-landing .page-slogan .h2d,
.jumbotron.jumbotron-landing .page-slogan h3,
.jumbotron.jumbotron-landing .page-slogan .h3d,
.jumbotron.jumbotron-landing .page-slogan > div,
.jumbotron.jumbotron-landing .page-header h1,
.jumbotron.jumbotron-landing .page-header .h1d,
.jumbotron.jumbotron-landing .page-header h2,
.jumbotron.jumbotron-landing .page-header .h2d,
.jumbotron.jumbotron-landing .page-header h3,
.jumbotron.jumbotron-landing .page-header .h3d,
.jumbotron.jumbotron-landing .page-header > div {
  margin: 0;
  color: #fff;
  font-size: 30px;
  font-family: "Siemens Sans";
  line-height: 1.1;
  font-weight: 500;
}
.jumbotron.jumbotron-landing .page-slogan h1 small, .jumbotron.jumbotron-landing .page-slogan .h1d small,
.jumbotron.jumbotron-landing .page-slogan h2 small,
.jumbotron.jumbotron-landing .page-slogan .h2d small,
.jumbotron.jumbotron-landing .page-slogan h3 small,
.jumbotron.jumbotron-landing .page-slogan .h3d small,
.jumbotron.jumbotron-landing .page-slogan > div small,
.jumbotron.jumbotron-landing .page-header h1 small,
.jumbotron.jumbotron-landing .page-header .h1d small,
.jumbotron.jumbotron-landing .page-header h2 small,
.jumbotron.jumbotron-landing .page-header .h2d small,
.jumbotron.jumbotron-landing .page-header h3 small,
.jumbotron.jumbotron-landing .page-header .h3d small,
.jumbotron.jumbotron-landing .page-header > div small {
  display: block;
  color: #fff;
  line-height: 1;
  font-weight: normal;
  font-family: "Siemens Sans";
  font-size: 16px;
  margin-top: 10px;
}

.jumbotron {
  background: linear-gradient(to right, #50bebe 0%, #099 50%, #0099b0 83%, #0099cb 100%);
  border-radius: 0;
}
.jumbotron > hr {
  border: 0;
}

.alert {
  position: relative;
  padding: 1.375rem 1.375rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0;
}

.alert-heading {
  color: inherit;
}

.alert-link {
  font-weight: 700;
}

.alert-dismissible {
  padding-right: 4.0625rem;
}
.alert-dismissible .close {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 2;
  padding: 1.375rem 1.375rem;
  color: inherit;
}

.alert-primary {
  color: #faa50a;
  background-color: #faa50a;
  border-color: #faa50a;
}
.alert-primary hr {
  border-top-color: #e69605;
}
.alert-primary .alert-link {
  color: #cd8604;
}

.alert-secondary {
  color: #2387aa;
  background-color: #2387aa;
  border-color: #2387aa;
}
.alert-secondary hr {
  border-top-color: #1f7695;
}
.alert-secondary .alert-link {
  color: #1a6580;
}

.alert-success {
  color: #099b00;
  background-color: #099b00;
  border-color: #099b00;
}
.alert-success hr {
  border-top-color: #088200;
}
.alert-success .alert-link {
  color: #066800;
}

.alert-info {
  color: #697882;
  background-color: #697882;
  border-color: #697882;
}
.alert-info hr {
  border-top-color: #5e6b74;
}
.alert-info .alert-link {
  color: #525e66;
}

.alert-warning {
  color: #eb780a;
  background-color: #eb780a;
  border-color: #eb780a;
}
.alert-warning hr {
  border-top-color: #d36c09;
}
.alert-warning .alert-link {
  color: #ba5f08;
}

.alert-danger {
  color: #dc0000;
  background-color: #dc0000;
  border-color: #dc0000;
}
.alert-danger hr {
  border-top-color: #c30000;
}
.alert-danger .alert-link {
  color: #a90000;
}

.alert-light {
  color: #ffb900;
  background-color: #ffb900;
  border-color: #ffb900;
}
.alert-light hr {
  border-top-color: #e6a700;
}
.alert-light .alert-link {
  color: #cc9400;
}

.alert-dark {
  color: #eb780a;
  background-color: #eb780a;
  border-color: #eb780a;
}
.alert-dark hr {
  border-top-color: #d36c09;
}
.alert-dark .alert-link {
  color: #ba5f08;
}

.alert-inactive {
  color: #8b8b95;
  background-color: #8b8b95;
  border-color: #8b8b95;
}
.alert-inactive hr {
  border-top-color: #7e7e89;
}
.alert-inactive .alert-link {
  color: #71717c;
}

.alert span[class*=newton-],
.alert-dismissible span[class*=newton-] {
  font-size: 30px;
  margin-right: 8px;
}

.alert-dismissible .close > * {
  display: none;
}
.alert-dismissible .close:hover {
  color: inherit;
}

@keyframes progress-bar-stripes {
  from {
    background-position: 1rem 0;
  }
  to {
    background-position: 0 0;
  }
}
.progress {
  display: flex;
  height: 1rem;
  overflow: hidden;
  line-height: 0;
  font-size: 0.65625rem;
  background-color: #e9ecef;
  border-radius: 0;
}

.progress-bar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  background-color: #faa50a;
  transition: width 0.6s ease;
}
@media (prefers-reduced-motion: reduce) {
  .progress-bar {
    transition: none;
  }
}

.progress-bar-striped {
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-size: 1rem 1rem;
}

.progress-bar-animated {
  animation: 1s linear infinite progress-bar-stripes;
}
@media (prefers-reduced-motion: reduce) {
  .progress-bar-animated {
    animation: none;
  }
}

input[type=range] {
  appearance: none;
  width: 100%;
  background: transparent;
  flex: 1;
  padding: 15px 0;
  margin: 0;
}
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  input[type=range] {
    /* IE10+ CSS hack */
    padding: 0;
  }
}
input[type=range]:focus {
  outline: none;
}
input[type=range]::-webkit-slider-runnable-track {
  width: 100%;
  height: 2px;
  cursor: pointer;
  background: #c8cdd2;
  border-radius: 0;
  border: 0 solid #c8cdd2;
}
input[type=range]::-webkit-slider-thumb {
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.3);
  border: 1px solid #c8cdd2;
  height: 24px;
  width: 24px;
  border-radius: 12px;
  background: linear-gradient(#fff, white);
  cursor: pointer;
  appearance: none;
  margin-top: -11px;
}
input[type=range]:focus::-webkit-slider-runnable-track {
  background: #c8cdd2;
}
input[type=range]::-moz-range-track {
  width: 100%;
  height: 2px;
  cursor: pointer;
  background: #c8cdd2;
  border-radius: 0;
  border: 0 solid #c8cdd2;
}
input[type=range]::-moz-range-thumb {
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.3);
  border: 1px solid #c8cdd2;
  height: 24px;
  width: 24px;
  border-radius: 12px;
  background: linear-gradient(#fff, white);
  cursor: pointer;
}
input[type=range]:focus::-moz-range-track {
  background: #c8cdd2;
}
input[type=range]::-ms-track {
  width: 100%;
  height: 2px;
  cursor: pointer;
  background: transparent;
  border-color: transparent;
  border-width: 24px 0;
  color: transparent;
}
input[type=range]::-ms-fill-lower {
  background: #c8cdd2;
  border: 0 solid #c8cdd2;
  border-radius: 0;
}
input[type=range]::-ms-fill-upper {
  background: #c8cdd2;
  border: 0 solid #c8cdd2;
  border-radius: 0;
}
input[type=range]::-ms-thumb {
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.3);
  border: 1px solid #c8cdd2;
  height: 24px;
  width: 24px;
  border-radius: 12px;
  background: linear-gradient(#fff, white);
  cursor: pointer;
}
input[type=range]:focus::-ms-fill-lower {
  background: #c8cdd2;
}
input[type=range]:focus::-ms-fill-upper {
  background: #c8cdd2;
}

.media {
  display: flex;
  align-items: flex-start;
}

.media-body {
  flex: 1;
}

.list-group, .filter-group {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  border-radius: 0;
}

.list-group-item-action {
  width: 100%;
  color: #495057;
  text-align: inherit;
}
.list-group-item-action:hover, .list-group-item-action:focus {
  z-index: 1;
  color: #495057;
  text-decoration: none;
  background-color: #f8f9fa;
}
.list-group-item-action:active {
  color: #2d373c;
  background-color: #e9ecef;
}

.list-group-item, .filter-group-item {
  position: relative;
  display: block;
  padding: 15px 15px;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.125);
}
.list-group-item:first-child, .filter-group-item:first-child {
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
}
.list-group-item:last-child, .filter-group-item:last-child {
  border-bottom-right-radius: inherit;
  border-bottom-left-radius: inherit;
}
.list-group-item.disabled, .disabled.filter-group-item, .list-group-item:disabled, .filter-group-item:disabled {
  color: #6c757d;
  pointer-events: none;
  background-color: #fff;
}
.list-group-item.active, .active.filter-group-item {
  z-index: 2;
  color: #fff;
  background-color: #faa50a;
  border-color: #faa50a;
}
.list-group-item + .list-group-item, .filter-group-item + .list-group-item, .list-group-item + .filter-group-item, .filter-group-item + .filter-group-item {
  border-top-width: 0;
}
.list-group-item + .list-group-item.active, .filter-group-item + .list-group-item.active, .list-group-item + .active.filter-group-item, .filter-group-item + .active.filter-group-item {
  margin-top: -1px;
  border-top-width: 1px;
}

.list-group-horizontal {
  flex-direction: row;
}
.list-group-horizontal > .list-group-item:first-child, .list-group-horizontal > .filter-group-item:first-child {
  border-bottom-left-radius: 0;
  border-top-right-radius: 0;
}
.list-group-horizontal > .list-group-item:last-child, .list-group-horizontal > .filter-group-item:last-child {
  border-top-right-radius: 0;
  border-bottom-left-radius: 0;
}
.list-group-horizontal > .list-group-item.active, .list-group-horizontal > .active.filter-group-item {
  margin-top: 0;
}
.list-group-horizontal > .list-group-item + .list-group-item, .list-group-horizontal > .filter-group-item + .list-group-item, .list-group-horizontal > .list-group-item + .filter-group-item, .list-group-horizontal > .filter-group-item + .filter-group-item {
  border-top-width: 1px;
  border-left-width: 0;
}
.list-group-horizontal > .list-group-item + .list-group-item.active, .list-group-horizontal > .filter-group-item + .list-group-item.active, .list-group-horizontal > .list-group-item + .active.filter-group-item, .list-group-horizontal > .filter-group-item + .active.filter-group-item {
  margin-left: -1px;
  border-left-width: 1px;
}

@media (min-width: 576px) {
  .list-group-horizontal-sm {
    flex-direction: row;
  }
  .list-group-horizontal-sm > .list-group-item:first-child, .list-group-horizontal-sm > .filter-group-item:first-child {
    border-bottom-left-radius: 0;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-sm > .list-group-item:last-child, .list-group-horizontal-sm > .filter-group-item:last-child {
    border-top-right-radius: 0;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-sm > .list-group-item.active, .list-group-horizontal-sm > .active.filter-group-item {
    margin-top: 0;
  }
  .list-group-horizontal-sm > .list-group-item + .list-group-item, .list-group-horizontal-sm > .filter-group-item + .list-group-item, .list-group-horizontal-sm > .list-group-item + .filter-group-item, .list-group-horizontal-sm > .filter-group-item + .filter-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-sm > .list-group-item + .list-group-item.active, .list-group-horizontal-sm > .filter-group-item + .list-group-item.active, .list-group-horizontal-sm > .list-group-item + .active.filter-group-item, .list-group-horizontal-sm > .filter-group-item + .active.filter-group-item {
    margin-left: -1px;
    border-left-width: 1px;
  }
}
@media (min-width: 768px) {
  .list-group-horizontal-md {
    flex-direction: row;
  }
  .list-group-horizontal-md > .list-group-item:first-child, .list-group-horizontal-md > .filter-group-item:first-child {
    border-bottom-left-radius: 0;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-md > .list-group-item:last-child, .list-group-horizontal-md > .filter-group-item:last-child {
    border-top-right-radius: 0;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-md > .list-group-item.active, .list-group-horizontal-md > .active.filter-group-item {
    margin-top: 0;
  }
  .list-group-horizontal-md > .list-group-item + .list-group-item, .list-group-horizontal-md > .filter-group-item + .list-group-item, .list-group-horizontal-md > .list-group-item + .filter-group-item, .list-group-horizontal-md > .filter-group-item + .filter-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-md > .list-group-item + .list-group-item.active, .list-group-horizontal-md > .filter-group-item + .list-group-item.active, .list-group-horizontal-md > .list-group-item + .active.filter-group-item, .list-group-horizontal-md > .filter-group-item + .active.filter-group-item {
    margin-left: -1px;
    border-left-width: 1px;
  }
}
@media (min-width: 992px) {
  .list-group-horizontal-lg {
    flex-direction: row;
  }
  .list-group-horizontal-lg > .list-group-item:first-child, .list-group-horizontal-lg > .filter-group-item:first-child {
    border-bottom-left-radius: 0;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-lg > .list-group-item:last-child, .list-group-horizontal-lg > .filter-group-item:last-child {
    border-top-right-radius: 0;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-lg > .list-group-item.active, .list-group-horizontal-lg > .active.filter-group-item {
    margin-top: 0;
  }
  .list-group-horizontal-lg > .list-group-item + .list-group-item, .list-group-horizontal-lg > .filter-group-item + .list-group-item, .list-group-horizontal-lg > .list-group-item + .filter-group-item, .list-group-horizontal-lg > .filter-group-item + .filter-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-lg > .list-group-item + .list-group-item.active, .list-group-horizontal-lg > .filter-group-item + .list-group-item.active, .list-group-horizontal-lg > .list-group-item + .active.filter-group-item, .list-group-horizontal-lg > .filter-group-item + .active.filter-group-item {
    margin-left: -1px;
    border-left-width: 1px;
  }
}
@media (min-width: 1200px) {
  .list-group-horizontal-xl {
    flex-direction: row;
  }
  .list-group-horizontal-xl > .list-group-item:first-child, .list-group-horizontal-xl > .filter-group-item:first-child {
    border-bottom-left-radius: 0;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-xl > .list-group-item:last-child, .list-group-horizontal-xl > .filter-group-item:last-child {
    border-top-right-radius: 0;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-xl > .list-group-item.active, .list-group-horizontal-xl > .active.filter-group-item {
    margin-top: 0;
  }
  .list-group-horizontal-xl > .list-group-item + .list-group-item, .list-group-horizontal-xl > .filter-group-item + .list-group-item, .list-group-horizontal-xl > .list-group-item + .filter-group-item, .list-group-horizontal-xl > .filter-group-item + .filter-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-xl > .list-group-item + .list-group-item.active, .list-group-horizontal-xl > .filter-group-item + .list-group-item.active, .list-group-horizontal-xl > .list-group-item + .active.filter-group-item, .list-group-horizontal-xl > .filter-group-item + .active.filter-group-item {
    margin-left: -1px;
    border-left-width: 1px;
  }
}
.list-group-flush {
  border-radius: 0;
}
.list-group-flush > .list-group-item, .list-group-flush > .filter-group-item {
  border-width: 0 0 1px;
}
.list-group-flush > .list-group-item:last-child, .list-group-flush > .filter-group-item:last-child {
  border-bottom-width: 0;
}

.list-group-item-primary {
  color: #825605;
  background-color: #fee6ba;
}
.list-group-item-primary.list-group-item-action:hover, .list-group-item-primary.list-group-item-action:focus {
  color: #825605;
  background-color: #fedda1;
}
.list-group-item-primary.list-group-item-action.active {
  color: #fff;
  background-color: #825605;
  border-color: #825605;
}

.list-group-item-secondary {
  color: #124658;
  background-color: #c1dde7;
}
.list-group-item-secondary.list-group-item-action:hover, .list-group-item-secondary.list-group-item-action:focus {
  color: #124658;
  background-color: #afd3e0;
}
.list-group-item-secondary.list-group-item-action.active {
  color: #fff;
  background-color: #124658;
  border-color: #124658;
}

.list-group-item-success {
  color: #055100;
  background-color: #bae3b8;
}
.list-group-item-success.list-group-item-action:hover, .list-group-item-success.list-group-item-action:focus {
  color: #055100;
  background-color: #a8dca6;
}
.list-group-item-success.list-group-item-action.active {
  color: #fff;
  background-color: #055100;
  border-color: #055100;
}

.list-group-item-info {
  color: #373e44;
  background-color: #d5d9dc;
}
.list-group-item-info.list-group-item-action:hover, .list-group-item-info.list-group-item-action:focus {
  color: #373e44;
  background-color: #c7ccd0;
}
.list-group-item-info.list-group-item-action.active {
  color: #fff;
  background-color: #373e44;
  border-color: #373e44;
}

.list-group-item-warning {
  color: #7a3e05;
  background-color: #f9d9ba;
}
.list-group-item-warning.list-group-item-action:hover, .list-group-item-warning.list-group-item-action:focus {
  color: #7a3e05;
  background-color: #f7cca3;
}
.list-group-item-warning.list-group-item-action.active {
  color: #fff;
  background-color: #7a3e05;
  border-color: #7a3e05;
}

.list-group-item-danger {
  color: #720000;
  background-color: #f5b8b8;
}
.list-group-item-danger.list-group-item-action:hover, .list-group-item-danger.list-group-item-action:focus {
  color: #720000;
  background-color: #f2a2a2;
}
.list-group-item-danger.list-group-item-action.active {
  color: #fff;
  background-color: #720000;
  border-color: #720000;
}

.list-group-item-light {
  color: #856000;
  background-color: #ffebb8;
}
.list-group-item-light.list-group-item-action:hover, .list-group-item-light.list-group-item-action:focus {
  color: #856000;
  background-color: #ffe49f;
}
.list-group-item-light.list-group-item-action.active {
  color: #fff;
  background-color: #856000;
  border-color: #856000;
}

.list-group-item-dark {
  color: #7a3e05;
  background-color: #f9d9ba;
}
.list-group-item-dark.list-group-item-action:hover, .list-group-item-dark.list-group-item-action:focus {
  color: #7a3e05;
  background-color: #f7cca3;
}
.list-group-item-dark.list-group-item-action.active {
  color: #fff;
  background-color: #7a3e05;
  border-color: #7a3e05;
}

.list-group-item-inactive {
  color: #48484d;
  background-color: #dfdfe1;
}
.list-group-item-inactive.list-group-item-action:hover, .list-group-item-inactive.list-group-item-action:focus {
  color: #48484d;
  background-color: #d2d2d5;
}
.list-group-item-inactive.list-group-item-action.active {
  color: #fff;
  background-color: #48484d;
  border-color: #48484d;
}

.filter-group-item {
  color: rgba(0, 0, 0, 0.125);
  background-color: #fff;
  border-color: rgba(0, 0, 0, 0.125);
}

.close {
  float: right;
  font-size: 1.3125rem;
  font-weight: 700;
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #fff;
  opacity: 0.5;
}
.close:hover {
  color: #000;
  text-decoration: none;
}
.close:not(:disabled):not(.disabled):hover, .close:not(:disabled):not(.disabled):focus {
  opacity: 0.75;
}

button.close {
  padding: 0;
  background-color: transparent;
  border: 0;
}

a.close.disabled {
  pointer-events: none;
}

.close {
  font-family: "simpl-icons-newton";
}
.close::after {
  content: "";
}

.toast {
  flex-basis: 350px;
  max-width: 350px;
  font-size: 0.875rem;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0 0.25rem 0.75rem rgba(0, 0, 0, 0.1);
  opacity: 0;
  border-radius: 0.25rem;
}
.toast:not(:last-child) {
  margin-bottom: 0.75rem;
}
.toast.showing {
  opacity: 1;
}
.toast.show {
  display: block;
  opacity: 1;
}
.toast.hide {
  display: none;
}

.toast-header {
  display: flex;
  align-items: center;
  padding: 0.25rem 0.75rem;
  color: #6c757d;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px);
}

.toast-body {
  padding: 0.75rem;
}

.modal-open {
  overflow: hidden;
}
.modal-open .modal {
  overflow-x: hidden;
  overflow-y: auto;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  display: none;
  width: 100%;
  height: 100%;
  overflow: hidden;
  outline: 0;
}

.modal-dialog {
  position: relative;
  width: auto;
  margin: 0.5rem;
  pointer-events: none;
}
.modal.fade .modal-dialog {
  transition: transform 0.3s ease-out;
  transform: translate(0, -50px);
}
@media (prefers-reduced-motion: reduce) {
  .modal.fade .modal-dialog {
    transition: none;
  }
}
.modal.show .modal-dialog {
  transform: none;
}
.modal.modal-static .modal-dialog {
  transform: scale(1.02);
}

.modal-dialog-scrollable {
  display: flex;
  max-height: calc(100% - 1rem);
}
.modal-dialog-scrollable .modal-content {
  max-height: calc(100vh - 1rem);
  overflow: hidden;
}
.modal-dialog-scrollable .modal-header,
.modal-dialog-scrollable .modal-footer {
  flex-shrink: 0;
}
.modal-dialog-scrollable .modal-body {
  overflow-y: auto;
}

.modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - 1rem);
}
.modal-dialog-centered::before {
  display: block;
  height: calc(100vh - 1rem);
  height: min-content;
  content: "";
}
.modal-dialog-centered.modal-dialog-scrollable {
  flex-direction: column;
  justify-content: center;
  height: 100%;
}
.modal-dialog-centered.modal-dialog-scrollable .modal-content {
  max-height: none;
}
.modal-dialog-centered.modal-dialog-scrollable::before {
  content: none;
}

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0;
  outline: 0;
}

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000;
}
.modal-backdrop.fade {
  opacity: 0;
}
.modal-backdrop.show {
  opacity: 0.5;
}

.modal-header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 1rem 1rem;
  border-bottom: 1px solid #dee2e6;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.modal-header .close {
  padding: 1rem 1rem;
  margin: -1rem -1rem -1rem auto;
}

.modal-title {
  margin-bottom: 0;
  line-height: 1.5;
}

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: 1rem;
}

.modal-footer {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
  padding: 0.75rem;
  border-top: 1px solid #dee2e6;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.modal-footer > * {
  margin: 0.25rem;
}

.modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll;
}

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 500px;
    margin: 1.75rem auto;
  }

  .modal-dialog-scrollable {
    max-height: calc(100% - 3.5rem);
  }
  .modal-dialog-scrollable .modal-content {
    max-height: calc(100vh - 3.5rem);
  }

  .modal-dialog-centered {
    min-height: calc(100% - 3.5rem);
  }
  .modal-dialog-centered::before {
    height: calc(100vh - 3.5rem);
    height: min-content;
  }

  .modal-sm {
    max-width: 300px;
  }
}
@media (min-width: 992px) {
  .modal-lg,
.modal-xl {
    max-width: 800px;
  }
}
@media (min-width: 1200px) {
  .modal-xl {
    max-width: 1140px;
  }
}
.modal-open {
  padding-right: 0 !important;
}

.modal-footer {
  background: #eaeaee;
  border-bottom-left-radius: -1px;
  border-bottom-right-radius: -1px;
}

.tooltip {
  position: absolute;
  z-index: 1070;
  display: block;
  margin: 0;
  font-family: "Siemens Sans";
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.765625rem;
  word-wrap: break-word;
  opacity: 0;
}
.tooltip.show {
  opacity: 0.9;
}
.tooltip .arrow {
  position: absolute;
  display: block;
  width: 0.8rem;
  height: 0.4rem;
}
.tooltip .arrow::before {
  position: absolute;
  content: "";
  border-color: transparent;
  border-style: solid;
}

.bs-tooltip-top, .bs-tooltip-auto[x-placement^=top] {
  padding: 0.4rem 0;
}
.bs-tooltip-top .arrow, .bs-tooltip-auto[x-placement^=top] .arrow {
  bottom: 0;
}
.bs-tooltip-top .arrow::before, .bs-tooltip-auto[x-placement^=top] .arrow::before {
  top: 0;
  border-width: 0.4rem 0.4rem 0;
  border-top-color: #000;
}

.bs-tooltip-right, .bs-tooltip-auto[x-placement^=right] {
  padding: 0 0.4rem;
}
.bs-tooltip-right .arrow, .bs-tooltip-auto[x-placement^=right] .arrow {
  left: 0;
  width: 0.4rem;
  height: 0.8rem;
}
.bs-tooltip-right .arrow::before, .bs-tooltip-auto[x-placement^=right] .arrow::before {
  right: 0;
  border-width: 0.4rem 0.4rem 0.4rem 0;
  border-right-color: #000;
}

.bs-tooltip-bottom, .bs-tooltip-auto[x-placement^=bottom] {
  padding: 0.4rem 0;
}
.bs-tooltip-bottom .arrow, .bs-tooltip-auto[x-placement^=bottom] .arrow {
  top: 0;
}
.bs-tooltip-bottom .arrow::before, .bs-tooltip-auto[x-placement^=bottom] .arrow::before {
  bottom: 0;
  border-width: 0 0.4rem 0.4rem;
  border-bottom-color: #000;
}

.bs-tooltip-left, .bs-tooltip-auto[x-placement^=left] {
  padding: 0 0.4rem;
}
.bs-tooltip-left .arrow, .bs-tooltip-auto[x-placement^=left] .arrow {
  right: 0;
  width: 0.4rem;
  height: 0.8rem;
}
.bs-tooltip-left .arrow::before, .bs-tooltip-auto[x-placement^=left] .arrow::before {
  left: 0;
  border-width: 0.4rem 0 0.4rem 0.4rem;
  border-left-color: #000;
}

.tooltip-inner {
  max-width: 200px;
  padding: 0.25rem 0.5rem;
  color: #fff;
  text-align: center;
  background-color: #000;
  border-radius: 0;
}

.popover {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1060;
  display: block;
  max-width: 276px;
  font-family: "Siemens Sans";
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.765625rem;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0;
}
.popover .arrow {
  position: absolute;
  display: block;
  width: 1rem;
  height: 0.5rem;
  margin: 0 0;
}
.popover .arrow::before, .popover .arrow::after {
  position: absolute;
  display: block;
  content: "";
  border-color: transparent;
  border-style: solid;
}

.bs-popover-top, .bs-popover-auto[x-placement^=top] {
  margin-bottom: 0.5rem;
}
.bs-popover-top > .arrow, .bs-popover-auto[x-placement^=top] > .arrow {
  bottom: calc(-0.5rem - 1px);
}
.bs-popover-top > .arrow::before, .bs-popover-auto[x-placement^=top] > .arrow::before {
  bottom: 0;
  border-width: 0.5rem 0.5rem 0;
  border-top-color: rgba(0, 0, 0, 0.25);
}
.bs-popover-top > .arrow::after, .bs-popover-auto[x-placement^=top] > .arrow::after {
  bottom: 1px;
  border-width: 0.5rem 0.5rem 0;
  border-top-color: #fff;
}

.bs-popover-right, .bs-popover-auto[x-placement^=right] {
  margin-left: 0.5rem;
}
.bs-popover-right > .arrow, .bs-popover-auto[x-placement^=right] > .arrow {
  left: calc(-0.5rem - 1px);
  width: 0.5rem;
  height: 1rem;
  margin: 0 0;
}
.bs-popover-right > .arrow::before, .bs-popover-auto[x-placement^=right] > .arrow::before {
  left: 0;
  border-width: 0.5rem 0.5rem 0.5rem 0;
  border-right-color: rgba(0, 0, 0, 0.25);
}
.bs-popover-right > .arrow::after, .bs-popover-auto[x-placement^=right] > .arrow::after {
  left: 1px;
  border-width: 0.5rem 0.5rem 0.5rem 0;
  border-right-color: #fff;
}

.bs-popover-bottom, .bs-popover-auto[x-placement^=bottom] {
  margin-top: 0.5rem;
}
.bs-popover-bottom > .arrow, .bs-popover-auto[x-placement^=bottom] > .arrow {
  top: calc(-0.5rem - 1px);
}
.bs-popover-bottom > .arrow::before, .bs-popover-auto[x-placement^=bottom] > .arrow::before {
  top: 0;
  border-width: 0 0.5rem 0.5rem 0.5rem;
  border-bottom-color: rgba(0, 0, 0, 0.25);
}
.bs-popover-bottom > .arrow::after, .bs-popover-auto[x-placement^=bottom] > .arrow::after {
  top: 1px;
  border-width: 0 0.5rem 0.5rem 0.5rem;
  border-bottom-color: #fff;
}
.bs-popover-bottom .popover-header::before, .bs-popover-auto[x-placement^=bottom] .popover-header::before {
  position: absolute;
  top: 0;
  left: 50%;
  display: block;
  width: 1rem;
  margin-left: -0.5rem;
  content: "";
  border-bottom: 1px solid #f7f7f7;
}

.bs-popover-left, .bs-popover-auto[x-placement^=left] {
  margin-right: 0.5rem;
}
.bs-popover-left > .arrow, .bs-popover-auto[x-placement^=left] > .arrow {
  right: calc(-0.5rem - 1px);
  width: 0.5rem;
  height: 1rem;
  margin: 0 0;
}
.bs-popover-left > .arrow::before, .bs-popover-auto[x-placement^=left] > .arrow::before {
  right: 0;
  border-width: 0.5rem 0 0.5rem 0.5rem;
  border-left-color: rgba(0, 0, 0, 0.25);
}
.bs-popover-left > .arrow::after, .bs-popover-auto[x-placement^=left] > .arrow::after {
  right: 1px;
  border-width: 0.5rem 0 0.5rem 0.5rem;
  border-left-color: #fff;
}

.popover-header {
  padding: 0.5rem 0.75rem;
  margin-bottom: 0;
  font-size: 0.875rem;
  color: #2d373c;
  background-color: #f7f7f7;
  border-bottom: 1px solid #ebebeb;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.popover-header:empty {
  display: none;
}

.popover-body {
  padding: 0.5rem 0.75rem;
  color: #2d373c;
}

.popover {
  border: 0;
  border-radius: 0;
  color: #eeeeee;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.3);
  text-align: center;
}

.popover-header {
  color: #2387aa;
  background-color: initial;
  border-bottom: 0;
}

.carousel {
  position: relative;
}

.carousel.pointer-event {
  touch-action: pan-y;
}

.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden;
}
.carousel-inner::after {
  display: block;
  clear: both;
  content: "";
}

.carousel-item {
  position: relative;
  display: none;
  float: left;
  width: 100%;
  margin-right: -100%;
  backface-visibility: hidden;
  transition: transform 0.6s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-item {
    transition: none;
  }
}

.carousel-item.active,
.carousel-item-next,
.carousel-item-prev {
  display: block;
}

.carousel-item-next:not(.carousel-item-left),
.active.carousel-item-right {
  transform: translateX(100%);
}

.carousel-item-prev:not(.carousel-item-right),
.active.carousel-item-left {
  transform: translateX(-100%);
}

.carousel-fade .carousel-item {
  opacity: 0;
  transition-property: opacity;
  transform: none;
}
.carousel-fade .carousel-item.active,
.carousel-fade .carousel-item-next.carousel-item-left,
.carousel-fade .carousel-item-prev.carousel-item-right {
  z-index: 1;
  opacity: 1;
}
.carousel-fade .active.carousel-item-left,
.carousel-fade .active.carousel-item-right {
  z-index: 0;
  opacity: 0;
  transition: opacity 0s 0.6s;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-fade .active.carousel-item-left,
.carousel-fade .active.carousel-item-right {
    transition: none;
  }
}

.carousel-control-prev,
.carousel-control-next {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15%;
  color: #fff;
  text-align: center;
  opacity: 0.5;
  transition: opacity 0.15s ease;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-control-prev,
.carousel-control-next {
    transition: none;
  }
}
.carousel-control-prev:hover, .carousel-control-prev:focus,
.carousel-control-next:hover,
.carousel-control-next:focus {
  color: #fff;
  text-decoration: none;
  outline: 0;
  opacity: 0.9;
}

.carousel-control-prev {
  left: 0;
}

.carousel-control-next {
  right: 0;
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
  display: inline-block;
  width: 20px;
  height: 20px;
  background: 50%/100% 100% no-repeat;
}

.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M5.25 0l-4 4 4 4 1.5-1.5L4.25 4l2.5-2.5L5.25 0z'/%3e%3c/svg%3e");
}

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M2.75 0l-1.5 1.5L3.75 4l-2.5 2.5L2.75 8l4-4-4-4z'/%3e%3c/svg%3e");
}

.carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 15;
  display: flex;
  justify-content: center;
  padding-left: 0;
  margin-right: 15%;
  margin-left: 15%;
  list-style: none;
}
.carousel-indicators li {
  box-sizing: content-box;
  flex: 0 1 auto;
  width: 30px;
  height: 3px;
  margin-right: 3px;
  margin-left: 3px;
  text-indent: -999px;
  cursor: pointer;
  background-color: #fff;
  background-clip: padding-box;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  opacity: 0.5;
  transition: opacity 0.6s ease;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-indicators li {
    transition: none;
  }
}
.carousel-indicators .active {
  opacity: 1;
}

.carousel-caption {
  position: absolute;
  right: 15%;
  bottom: 20px;
  left: 15%;
  z-index: 10;
  padding-top: 20px;
  padding-bottom: 20px;
  color: #fff;
  text-align: center;
}

@keyframes spinner-border {
  to {
    transform: rotate(360deg);
  }
}
.spinner-border {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: text-bottom;
  border: 0.25em solid currentColor;
  border-right-color: transparent;
  border-radius: 50%;
  animation: 0.75s linear infinite spinner-border;
}

.spinner-border-sm {
  width: 1rem;
  height: 1rem;
  border-width: 0.2em;
}

@keyframes spinner-grow {
  0% {
    transform: scale(0);
  }
  50% {
    opacity: 1;
    transform: none;
  }
}
.spinner-grow {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: text-bottom;
  background-color: currentColor;
  border-radius: 50%;
  opacity: 0;
  animation: 0.75s linear infinite spinner-grow;
}

.spinner-grow-sm {
  width: 1rem;
  height: 1rem;
}

@media (prefers-reduced-motion: reduce) {
  .spinner-border,
.spinner-grow {
    animation-duration: 1.5s;
  }
}
.align-baseline {
  vertical-align: baseline !important;
}

.align-top {
  vertical-align: top !important;
}

.align-middle {
  vertical-align: middle !important;
}

.align-bottom {
  vertical-align: bottom !important;
}

.align-text-bottom {
  vertical-align: text-bottom !important;
}

.align-text-top {
  vertical-align: text-top !important;
}

.bg-primary {
  background-color: #faa50a !important;
}

a.bg-primary:hover, a.bg-primary:focus,
button.bg-primary:hover,
button.bg-primary:focus {
  background-color: #cd8604 !important;
}

.bg-secondary {
  background-color: #2387aa !important;
}

a.bg-secondary:hover, a.bg-secondary:focus,
button.bg-secondary:hover,
button.bg-secondary:focus {
  background-color: #1a6580 !important;
}

.bg-success {
  background-color: #099b00 !important;
}

a.bg-success:hover, a.bg-success:focus,
button.bg-success:hover,
button.bg-success:focus {
  background-color: #066800 !important;
}

.bg-info {
  background-color: #697882 !important;
}

a.bg-info:hover, a.bg-info:focus,
button.bg-info:hover,
button.bg-info:focus {
  background-color: #525e66 !important;
}

.bg-warning {
  background-color: #eb780a !important;
}

a.bg-warning:hover, a.bg-warning:focus,
button.bg-warning:hover,
button.bg-warning:focus {
  background-color: #ba5f08 !important;
}

.bg-danger {
  background-color: #dc0000 !important;
}

a.bg-danger:hover, a.bg-danger:focus,
button.bg-danger:hover,
button.bg-danger:focus {
  background-color: #a90000 !important;
}

.bg-light {
  background-color: #ffb900 !important;
}

a.bg-light:hover, a.bg-light:focus,
button.bg-light:hover,
button.bg-light:focus {
  background-color: #cc9400 !important;
}

.bg-dark {
  background-color: #eb780a !important;
}

a.bg-dark:hover, a.bg-dark:focus,
button.bg-dark:hover,
button.bg-dark:focus {
  background-color: #ba5f08 !important;
}

.bg-inactive {
  background-color: #8b8b95 !important;
}

a.bg-inactive:hover, a.bg-inactive:focus,
button.bg-inactive:hover,
button.bg-inactive:focus {
  background-color: #71717c !important;
}

.bg-white {
  background-color: #fff !important;
}

.bg-transparent {
  background-color: transparent !important;
}

.border {
  border: 1px solid #dee2e6 !important;
}

.border-top {
  border-top: 1px solid #dee2e6 !important;
}

.border-right {
  border-right: 1px solid #dee2e6 !important;
}

.border-bottom {
  border-bottom: 1px solid #dee2e6 !important;
}

.border-left {
  border-left: 1px solid #dee2e6 !important;
}

.border-0 {
  border: 0 !important;
}

.border-top-0 {
  border-top: 0 !important;
}

.border-right-0 {
  border-right: 0 !important;
}

.border-bottom-0 {
  border-bottom: 0 !important;
}

.border-left-0 {
  border-left: 0 !important;
}

.border-primary {
  border-color: #faa50a !important;
}

.border-secondary {
  border-color: #2387aa !important;
}

.border-success {
  border-color: #099b00 !important;
}

.border-info {
  border-color: #697882 !important;
}

.border-warning {
  border-color: #eb780a !important;
}

.border-danger {
  border-color: #dc0000 !important;
}

.border-light {
  border-color: #ffb900 !important;
}

.border-dark {
  border-color: #eb780a !important;
}

.border-inactive {
  border-color: #8b8b95 !important;
}

.border-white {
  border-color: #fff !important;
}

.rounded-sm {
  border-radius: 0 !important;
}

.rounded {
  border-radius: 0 !important;
}

.rounded-top {
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important;
}

.rounded-right {
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

.rounded-bottom {
  border-bottom-right-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}

.rounded-left {
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}

.rounded-lg {
  border-radius: 0 !important;
}

.rounded-circle {
  border-radius: 50% !important;
}

.rounded-pill {
  border-radius: 50rem !important;
}

.rounded-0 {
  border-radius: 0 !important;
}

.clearfix::after {
  display: block;
  clear: both;
  content: "";
}

.d-none {
  display: none !important;
}

.d-inline {
  display: inline !important;
}

.d-inline-block {
  display: inline-block !important;
}

.d-block {
  display: block !important;
}

.d-table {
  display: table !important;
}

.d-table-row {
  display: table-row !important;
}

.d-table-cell {
  display: table-cell !important;
}

.d-flex {
  display: flex !important;
}

.d-inline-flex {
  display: inline-flex !important;
}

@media (min-width: 576px) {
  .d-sm-none {
    display: none !important;
  }

  .d-sm-inline {
    display: inline !important;
  }

  .d-sm-inline-block {
    display: inline-block !important;
  }

  .d-sm-block {
    display: block !important;
  }

  .d-sm-table {
    display: table !important;
  }

  .d-sm-table-row {
    display: table-row !important;
  }

  .d-sm-table-cell {
    display: table-cell !important;
  }

  .d-sm-flex {
    display: flex !important;
  }

  .d-sm-inline-flex {
    display: inline-flex !important;
  }
}
@media (min-width: 768px) {
  .d-md-none {
    display: none !important;
  }

  .d-md-inline {
    display: inline !important;
  }

  .d-md-inline-block {
    display: inline-block !important;
  }

  .d-md-block {
    display: block !important;
  }

  .d-md-table {
    display: table !important;
  }

  .d-md-table-row {
    display: table-row !important;
  }

  .d-md-table-cell {
    display: table-cell !important;
  }

  .d-md-flex {
    display: flex !important;
  }

  .d-md-inline-flex {
    display: inline-flex !important;
  }
}
@media (min-width: 992px) {
  .d-lg-none {
    display: none !important;
  }

  .d-lg-inline {
    display: inline !important;
  }

  .d-lg-inline-block {
    display: inline-block !important;
  }

  .d-lg-block {
    display: block !important;
  }

  .d-lg-table {
    display: table !important;
  }

  .d-lg-table-row {
    display: table-row !important;
  }

  .d-lg-table-cell {
    display: table-cell !important;
  }

  .d-lg-flex {
    display: flex !important;
  }

  .d-lg-inline-flex {
    display: inline-flex !important;
  }
}
@media (min-width: 1200px) {
  .d-xl-none {
    display: none !important;
  }

  .d-xl-inline {
    display: inline !important;
  }

  .d-xl-inline-block {
    display: inline-block !important;
  }

  .d-xl-block {
    display: block !important;
  }

  .d-xl-table {
    display: table !important;
  }

  .d-xl-table-row {
    display: table-row !important;
  }

  .d-xl-table-cell {
    display: table-cell !important;
  }

  .d-xl-flex {
    display: flex !important;
  }

  .d-xl-inline-flex {
    display: inline-flex !important;
  }
}
@media print {
  .d-print-none {
    display: none !important;
  }

  .d-print-inline {
    display: inline !important;
  }

  .d-print-inline-block {
    display: inline-block !important;
  }

  .d-print-block {
    display: block !important;
  }

  .d-print-table {
    display: table !important;
  }

  .d-print-table-row {
    display: table-row !important;
  }

  .d-print-table-cell {
    display: table-cell !important;
  }

  .d-print-flex {
    display: flex !important;
  }

  .d-print-inline-flex {
    display: inline-flex !important;
  }
}
.embed-responsive {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden;
}
.embed-responsive::before {
  display: block;
  content: "";
}
.embed-responsive .embed-responsive-item,
.embed-responsive iframe,
.embed-responsive embed,
.embed-responsive object,
.embed-responsive video {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
}

.embed-responsive-21by9::before {
  padding-top: 42.8571428571%;
}

.embed-responsive-16by9::before {
  padding-top: 56.25%;
}

.embed-responsive-4by3::before {
  padding-top: 75%;
}

.embed-responsive-1by1::before {
  padding-top: 100%;
}

.flex-row {
  flex-direction: row !important;
}

.flex-column {
  flex-direction: column !important;
}

.flex-row-reverse {
  flex-direction: row-reverse !important;
}

.flex-column-reverse {
  flex-direction: column-reverse !important;
}

.flex-wrap {
  flex-wrap: wrap !important;
}

.flex-nowrap {
  flex-wrap: nowrap !important;
}

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}

.flex-fill {
  flex: 1 1 auto !important;
}

.flex-grow-0 {
  flex-grow: 0 !important;
}

.flex-grow-1 {
  flex-grow: 1 !important;
}

.flex-shrink-0 {
  flex-shrink: 0 !important;
}

.flex-shrink-1 {
  flex-shrink: 1 !important;
}

.justify-content-start {
  justify-content: flex-start !important;
}

.justify-content-end {
  justify-content: flex-end !important;
}

.justify-content-center {
  justify-content: center !important;
}

.justify-content-between {
  justify-content: space-between !important;
}

.justify-content-around {
  justify-content: space-around !important;
}

.align-items-start {
  align-items: flex-start !important;
}

.align-items-end {
  align-items: flex-end !important;
}

.align-items-center {
  align-items: center !important;
}

.align-items-baseline {
  align-items: baseline !important;
}

.align-items-stretch {
  align-items: stretch !important;
}

.align-content-start {
  align-content: flex-start !important;
}

.align-content-end {
  align-content: flex-end !important;
}

.align-content-center {
  align-content: center !important;
}

.align-content-between {
  align-content: space-between !important;
}

.align-content-around {
  align-content: space-around !important;
}

.align-content-stretch {
  align-content: stretch !important;
}

.align-self-auto {
  align-self: auto !important;
}

.align-self-start {
  align-self: flex-start !important;
}

.align-self-end {
  align-self: flex-end !important;
}

.align-self-center {
  align-self: center !important;
}

.align-self-baseline {
  align-self: baseline !important;
}

.align-self-stretch {
  align-self: stretch !important;
}

@media (min-width: 576px) {
  .flex-sm-row {
    flex-direction: row !important;
  }

  .flex-sm-column {
    flex-direction: column !important;
  }

  .flex-sm-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-sm-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-sm-wrap {
    flex-wrap: wrap !important;
  }

  .flex-sm-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .flex-sm-fill {
    flex: 1 1 auto !important;
  }

  .flex-sm-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-sm-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-sm-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-sm-shrink-1 {
    flex-shrink: 1 !important;
  }

  .justify-content-sm-start {
    justify-content: flex-start !important;
  }

  .justify-content-sm-end {
    justify-content: flex-end !important;
  }

  .justify-content-sm-center {
    justify-content: center !important;
  }

  .justify-content-sm-between {
    justify-content: space-between !important;
  }

  .justify-content-sm-around {
    justify-content: space-around !important;
  }

  .align-items-sm-start {
    align-items: flex-start !important;
  }

  .align-items-sm-end {
    align-items: flex-end !important;
  }

  .align-items-sm-center {
    align-items: center !important;
  }

  .align-items-sm-baseline {
    align-items: baseline !important;
  }

  .align-items-sm-stretch {
    align-items: stretch !important;
  }

  .align-content-sm-start {
    align-content: flex-start !important;
  }

  .align-content-sm-end {
    align-content: flex-end !important;
  }

  .align-content-sm-center {
    align-content: center !important;
  }

  .align-content-sm-between {
    align-content: space-between !important;
  }

  .align-content-sm-around {
    align-content: space-around !important;
  }

  .align-content-sm-stretch {
    align-content: stretch !important;
  }

  .align-self-sm-auto {
    align-self: auto !important;
  }

  .align-self-sm-start {
    align-self: flex-start !important;
  }

  .align-self-sm-end {
    align-self: flex-end !important;
  }

  .align-self-sm-center {
    align-self: center !important;
  }

  .align-self-sm-baseline {
    align-self: baseline !important;
  }

  .align-self-sm-stretch {
    align-self: stretch !important;
  }
}
@media (min-width: 768px) {
  .flex-md-row {
    flex-direction: row !important;
  }

  .flex-md-column {
    flex-direction: column !important;
  }

  .flex-md-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-md-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-md-wrap {
    flex-wrap: wrap !important;
  }

  .flex-md-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .flex-md-fill {
    flex: 1 1 auto !important;
  }

  .flex-md-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-md-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-md-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-md-shrink-1 {
    flex-shrink: 1 !important;
  }

  .justify-content-md-start {
    justify-content: flex-start !important;
  }

  .justify-content-md-end {
    justify-content: flex-end !important;
  }

  .justify-content-md-center {
    justify-content: center !important;
  }

  .justify-content-md-between {
    justify-content: space-between !important;
  }

  .justify-content-md-around {
    justify-content: space-around !important;
  }

  .align-items-md-start {
    align-items: flex-start !important;
  }

  .align-items-md-end {
    align-items: flex-end !important;
  }

  .align-items-md-center {
    align-items: center !important;
  }

  .align-items-md-baseline {
    align-items: baseline !important;
  }

  .align-items-md-stretch {
    align-items: stretch !important;
  }

  .align-content-md-start {
    align-content: flex-start !important;
  }

  .align-content-md-end {
    align-content: flex-end !important;
  }

  .align-content-md-center {
    align-content: center !important;
  }

  .align-content-md-between {
    align-content: space-between !important;
  }

  .align-content-md-around {
    align-content: space-around !important;
  }

  .align-content-md-stretch {
    align-content: stretch !important;
  }

  .align-self-md-auto {
    align-self: auto !important;
  }

  .align-self-md-start {
    align-self: flex-start !important;
  }

  .align-self-md-end {
    align-self: flex-end !important;
  }

  .align-self-md-center {
    align-self: center !important;
  }

  .align-self-md-baseline {
    align-self: baseline !important;
  }

  .align-self-md-stretch {
    align-self: stretch !important;
  }
}
@media (min-width: 992px) {
  .flex-lg-row {
    flex-direction: row !important;
  }

  .flex-lg-column {
    flex-direction: column !important;
  }

  .flex-lg-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-lg-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-lg-wrap {
    flex-wrap: wrap !important;
  }

  .flex-lg-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .flex-lg-fill {
    flex: 1 1 auto !important;
  }

  .flex-lg-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-lg-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-lg-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-lg-shrink-1 {
    flex-shrink: 1 !important;
  }

  .justify-content-lg-start {
    justify-content: flex-start !important;
  }

  .justify-content-lg-end {
    justify-content: flex-end !important;
  }

  .justify-content-lg-center {
    justify-content: center !important;
  }

  .justify-content-lg-between {
    justify-content: space-between !important;
  }

  .justify-content-lg-around {
    justify-content: space-around !important;
  }

  .align-items-lg-start {
    align-items: flex-start !important;
  }

  .align-items-lg-end {
    align-items: flex-end !important;
  }

  .align-items-lg-center {
    align-items: center !important;
  }

  .align-items-lg-baseline {
    align-items: baseline !important;
  }

  .align-items-lg-stretch {
    align-items: stretch !important;
  }

  .align-content-lg-start {
    align-content: flex-start !important;
  }

  .align-content-lg-end {
    align-content: flex-end !important;
  }

  .align-content-lg-center {
    align-content: center !important;
  }

  .align-content-lg-between {
    align-content: space-between !important;
  }

  .align-content-lg-around {
    align-content: space-around !important;
  }

  .align-content-lg-stretch {
    align-content: stretch !important;
  }

  .align-self-lg-auto {
    align-self: auto !important;
  }

  .align-self-lg-start {
    align-self: flex-start !important;
  }

  .align-self-lg-end {
    align-self: flex-end !important;
  }

  .align-self-lg-center {
    align-self: center !important;
  }

  .align-self-lg-baseline {
    align-self: baseline !important;
  }

  .align-self-lg-stretch {
    align-self: stretch !important;
  }
}
@media (min-width: 1200px) {
  .flex-xl-row {
    flex-direction: row !important;
  }

  .flex-xl-column {
    flex-direction: column !important;
  }

  .flex-xl-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-xl-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-xl-wrap {
    flex-wrap: wrap !important;
  }

  .flex-xl-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .flex-xl-fill {
    flex: 1 1 auto !important;
  }

  .flex-xl-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-xl-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-xl-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-xl-shrink-1 {
    flex-shrink: 1 !important;
  }

  .justify-content-xl-start {
    justify-content: flex-start !important;
  }

  .justify-content-xl-end {
    justify-content: flex-end !important;
  }

  .justify-content-xl-center {
    justify-content: center !important;
  }

  .justify-content-xl-between {
    justify-content: space-between !important;
  }

  .justify-content-xl-around {
    justify-content: space-around !important;
  }

  .align-items-xl-start {
    align-items: flex-start !important;
  }

  .align-items-xl-end {
    align-items: flex-end !important;
  }

  .align-items-xl-center {
    align-items: center !important;
  }

  .align-items-xl-baseline {
    align-items: baseline !important;
  }

  .align-items-xl-stretch {
    align-items: stretch !important;
  }

  .align-content-xl-start {
    align-content: flex-start !important;
  }

  .align-content-xl-end {
    align-content: flex-end !important;
  }

  .align-content-xl-center {
    align-content: center !important;
  }

  .align-content-xl-between {
    align-content: space-between !important;
  }

  .align-content-xl-around {
    align-content: space-around !important;
  }

  .align-content-xl-stretch {
    align-content: stretch !important;
  }

  .align-self-xl-auto {
    align-self: auto !important;
  }

  .align-self-xl-start {
    align-self: flex-start !important;
  }

  .align-self-xl-end {
    align-self: flex-end !important;
  }

  .align-self-xl-center {
    align-self: center !important;
  }

  .align-self-xl-baseline {
    align-self: baseline !important;
  }

  .align-self-xl-stretch {
    align-self: stretch !important;
  }
}
.float-left {
  float: left !important;
}

.float-right {
  float: right !important;
}

.float-none {
  float: none !important;
}

@media (min-width: 576px) {
  .float-sm-left {
    float: left !important;
  }

  .float-sm-right {
    float: right !important;
  }

  .float-sm-none {
    float: none !important;
  }
}
@media (min-width: 768px) {
  .float-md-left {
    float: left !important;
  }

  .float-md-right {
    float: right !important;
  }

  .float-md-none {
    float: none !important;
  }
}
@media (min-width: 992px) {
  .float-lg-left {
    float: left !important;
  }

  .float-lg-right {
    float: right !important;
  }

  .float-lg-none {
    float: none !important;
  }
}
@media (min-width: 1200px) {
  .float-xl-left {
    float: left !important;
  }

  .float-xl-right {
    float: right !important;
  }

  .float-xl-none {
    float: none !important;
  }
}
.user-select-all {
  user-select: all !important;
}

.user-select-auto {
  user-select: auto !important;
}

.user-select-none {
  user-select: none !important;
}

.overflow-auto {
  overflow: auto !important;
}

.overflow-hidden {
  overflow: hidden !important;
}

.position-static {
  position: static !important;
}

.position-relative {
  position: relative !important;
}

.position-absolute {
  position: absolute !important;
}

.position-fixed {
  position: fixed !important;
}

.position-sticky {
  position: sticky !important;
}

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
}

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030;
}

@supports (position: sticky) {
  .sticky-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}

.sr-only-focusable:active, .sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto;
  white-space: normal;
}

.shadow-sm {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
}

.shadow {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
}

.shadow-lg {
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important;
}

.shadow-none {
  box-shadow: none !important;
}

.w-25 {
  width: 25% !important;
}

.w-50 {
  width: 50% !important;
}

.w-75 {
  width: 75% !important;
}

.w-100 {
  width: 100% !important;
}

.w-auto {
  width: auto !important;
}

.h-25 {
  height: 25% !important;
}

.h-50 {
  height: 50% !important;
}

.h-75 {
  height: 75% !important;
}

.h-100 {
  height: 100% !important;
}

.h-auto {
  height: auto !important;
}

.mw-100 {
  max-width: 100% !important;
}

.mh-100 {
  max-height: 100% !important;
}

.min-vw-100 {
  min-width: 100vw !important;
}

.min-vh-100 {
  min-height: 100vh !important;
}

.vw-100 {
  width: 100vw !important;
}

.vh-100 {
  height: 100vh !important;
}

.m-0 {
  margin: 0 !important;
}

.mt-0,
.my-0 {
  margin-top: 0 !important;
}

.mr-0,
.mx-0 {
  margin-right: 0 !important;
}

.mb-0,
.my-0 {
  margin-bottom: 0 !important;
}

.ml-0,
.mx-0 {
  margin-left: 0 !important;
}

.m-1 {
  margin: 0.25rem !important;
}

.mt-1,
.my-1 {
  margin-top: 0.25rem !important;
}

.mr-1,
.mx-1 {
  margin-right: 0.25rem !important;
}

.mb-1,
.my-1 {
  margin-bottom: 0.25rem !important;
}

.ml-1,
.mx-1 {
  margin-left: 0.25rem !important;
}

.m-2 {
  margin: 0.5rem !important;
}

.mt-2,
.my-2 {
  margin-top: 0.5rem !important;
}

.mr-2,
.mx-2 {
  margin-right: 0.5rem !important;
}

.mb-2,
.my-2 {
  margin-bottom: 0.5rem !important;
}

.ml-2,
.mx-2 {
  margin-left: 0.5rem !important;
}

.m-3 {
  margin: 1rem !important;
}

.mt-3,
.my-3 {
  margin-top: 1rem !important;
}

.mr-3,
.mx-3 {
  margin-right: 1rem !important;
}

.mb-3,
.my-3 {
  margin-bottom: 1rem !important;
}

.ml-3,
.mx-3 {
  margin-left: 1rem !important;
}

.m-4 {
  margin: 1.5rem !important;
}

.mt-4,
.my-4 {
  margin-top: 1.5rem !important;
}

.mr-4,
.mx-4 {
  margin-right: 1.5rem !important;
}

.mb-4,
.my-4 {
  margin-bottom: 1.5rem !important;
}

.ml-4,
.mx-4 {
  margin-left: 1.5rem !important;
}

.m-5 {
  margin: 3rem !important;
}

.mt-5,
.my-5 {
  margin-top: 3rem !important;
}

.mr-5,
.mx-5 {
  margin-right: 3rem !important;
}

.mb-5,
.my-5 {
  margin-bottom: 3rem !important;
}

.ml-5,
.mx-5 {
  margin-left: 3rem !important;
}

.p-0 {
  padding: 0 !important;
}

.pt-0,
.py-0 {
  padding-top: 0 !important;
}

.pr-0,
.px-0 {
  padding-right: 0 !important;
}

.pb-0,
.py-0 {
  padding-bottom: 0 !important;
}

.pl-0,
.px-0 {
  padding-left: 0 !important;
}

.p-1 {
  padding: 0.25rem !important;
}

.pt-1,
.py-1 {
  padding-top: 0.25rem !important;
}

.pr-1,
.px-1 {
  padding-right: 0.25rem !important;
}

.pb-1,
.py-1 {
  padding-bottom: 0.25rem !important;
}

.pl-1,
.px-1 {
  padding-left: 0.25rem !important;
}

.p-2 {
  padding: 0.5rem !important;
}

.pt-2,
.py-2 {
  padding-top: 0.5rem !important;
}

.pr-2,
.px-2 {
  padding-right: 0.5rem !important;
}

.pb-2,
.py-2 {
  padding-bottom: 0.5rem !important;
}

.pl-2,
.px-2 {
  padding-left: 0.5rem !important;
}

.p-3 {
  padding: 1rem !important;
}

.pt-3,
.py-3 {
  padding-top: 1rem !important;
}

.pr-3,
.px-3 {
  padding-right: 1rem !important;
}

.pb-3,
.py-3 {
  padding-bottom: 1rem !important;
}

.pl-3,
.px-3 {
  padding-left: 1rem !important;
}

.p-4 {
  padding: 1.5rem !important;
}

.pt-4,
.py-4 {
  padding-top: 1.5rem !important;
}

.pr-4,
.px-4 {
  padding-right: 1.5rem !important;
}

.pb-4,
.py-4 {
  padding-bottom: 1.5rem !important;
}

.pl-4,
.px-4 {
  padding-left: 1.5rem !important;
}

.p-5 {
  padding: 3rem !important;
}

.pt-5,
.py-5 {
  padding-top: 3rem !important;
}

.pr-5,
.px-5 {
  padding-right: 3rem !important;
}

.pb-5,
.py-5 {
  padding-bottom: 3rem !important;
}

.pl-5,
.px-5 {
  padding-left: 3rem !important;
}

.m-n1 {
  margin: -0.25rem !important;
}

.mt-n1,
.my-n1 {
  margin-top: -0.25rem !important;
}

.mr-n1,
.mx-n1 {
  margin-right: -0.25rem !important;
}

.mb-n1,
.my-n1 {
  margin-bottom: -0.25rem !important;
}

.ml-n1,
.mx-n1 {
  margin-left: -0.25rem !important;
}

.m-n2 {
  margin: -0.5rem !important;
}

.mt-n2,
.my-n2 {
  margin-top: -0.5rem !important;
}

.mr-n2,
.mx-n2 {
  margin-right: -0.5rem !important;
}

.mb-n2,
.my-n2 {
  margin-bottom: -0.5rem !important;
}

.ml-n2,
.mx-n2 {
  margin-left: -0.5rem !important;
}

.m-n3 {
  margin: -1rem !important;
}

.mt-n3,
.my-n3 {
  margin-top: -1rem !important;
}

.mr-n3,
.mx-n3 {
  margin-right: -1rem !important;
}

.mb-n3,
.my-n3 {
  margin-bottom: -1rem !important;
}

.ml-n3,
.mx-n3 {
  margin-left: -1rem !important;
}

.m-n4 {
  margin: -1.5rem !important;
}

.mt-n4,
.my-n4 {
  margin-top: -1.5rem !important;
}

.mr-n4,
.mx-n4 {
  margin-right: -1.5rem !important;
}

.mb-n4,
.my-n4 {
  margin-bottom: -1.5rem !important;
}

.ml-n4,
.mx-n4 {
  margin-left: -1.5rem !important;
}

.m-n5 {
  margin: -3rem !important;
}

.mt-n5,
.my-n5 {
  margin-top: -3rem !important;
}

.mr-n5,
.mx-n5 {
  margin-right: -3rem !important;
}

.mb-n5,
.my-n5 {
  margin-bottom: -3rem !important;
}

.ml-n5,
.mx-n5 {
  margin-left: -3rem !important;
}

.m-auto {
  margin: auto !important;
}

.mt-auto,
.my-auto {
  margin-top: auto !important;
}

.mr-auto,
.mx-auto {
  margin-right: auto !important;
}

.mb-auto,
.my-auto {
  margin-bottom: auto !important;
}

.ml-auto,
.mx-auto {
  margin-left: auto !important;
}

@media (min-width: 576px) {
  .m-sm-0 {
    margin: 0 !important;
  }

  .mt-sm-0,
.my-sm-0 {
    margin-top: 0 !important;
  }

  .mr-sm-0,
.mx-sm-0 {
    margin-right: 0 !important;
  }

  .mb-sm-0,
.my-sm-0 {
    margin-bottom: 0 !important;
  }

  .ml-sm-0,
.mx-sm-0 {
    margin-left: 0 !important;
  }

  .m-sm-1 {
    margin: 0.25rem !important;
  }

  .mt-sm-1,
.my-sm-1 {
    margin-top: 0.25rem !important;
  }

  .mr-sm-1,
.mx-sm-1 {
    margin-right: 0.25rem !important;
  }

  .mb-sm-1,
.my-sm-1 {
    margin-bottom: 0.25rem !important;
  }

  .ml-sm-1,
.mx-sm-1 {
    margin-left: 0.25rem !important;
  }

  .m-sm-2 {
    margin: 0.5rem !important;
  }

  .mt-sm-2,
.my-sm-2 {
    margin-top: 0.5rem !important;
  }

  .mr-sm-2,
.mx-sm-2 {
    margin-right: 0.5rem !important;
  }

  .mb-sm-2,
.my-sm-2 {
    margin-bottom: 0.5rem !important;
  }

  .ml-sm-2,
.mx-sm-2 {
    margin-left: 0.5rem !important;
  }

  .m-sm-3 {
    margin: 1rem !important;
  }

  .mt-sm-3,
.my-sm-3 {
    margin-top: 1rem !important;
  }

  .mr-sm-3,
.mx-sm-3 {
    margin-right: 1rem !important;
  }

  .mb-sm-3,
.my-sm-3 {
    margin-bottom: 1rem !important;
  }

  .ml-sm-3,
.mx-sm-3 {
    margin-left: 1rem !important;
  }

  .m-sm-4 {
    margin: 1.5rem !important;
  }

  .mt-sm-4,
.my-sm-4 {
    margin-top: 1.5rem !important;
  }

  .mr-sm-4,
.mx-sm-4 {
    margin-right: 1.5rem !important;
  }

  .mb-sm-4,
.my-sm-4 {
    margin-bottom: 1.5rem !important;
  }

  .ml-sm-4,
.mx-sm-4 {
    margin-left: 1.5rem !important;
  }

  .m-sm-5 {
    margin: 3rem !important;
  }

  .mt-sm-5,
.my-sm-5 {
    margin-top: 3rem !important;
  }

  .mr-sm-5,
.mx-sm-5 {
    margin-right: 3rem !important;
  }

  .mb-sm-5,
.my-sm-5 {
    margin-bottom: 3rem !important;
  }

  .ml-sm-5,
.mx-sm-5 {
    margin-left: 3rem !important;
  }

  .p-sm-0 {
    padding: 0 !important;
  }

  .pt-sm-0,
.py-sm-0 {
    padding-top: 0 !important;
  }

  .pr-sm-0,
.px-sm-0 {
    padding-right: 0 !important;
  }

  .pb-sm-0,
.py-sm-0 {
    padding-bottom: 0 !important;
  }

  .pl-sm-0,
.px-sm-0 {
    padding-left: 0 !important;
  }

  .p-sm-1 {
    padding: 0.25rem !important;
  }

  .pt-sm-1,
.py-sm-1 {
    padding-top: 0.25rem !important;
  }

  .pr-sm-1,
.px-sm-1 {
    padding-right: 0.25rem !important;
  }

  .pb-sm-1,
.py-sm-1 {
    padding-bottom: 0.25rem !important;
  }

  .pl-sm-1,
.px-sm-1 {
    padding-left: 0.25rem !important;
  }

  .p-sm-2 {
    padding: 0.5rem !important;
  }

  .pt-sm-2,
.py-sm-2 {
    padding-top: 0.5rem !important;
  }

  .pr-sm-2,
.px-sm-2 {
    padding-right: 0.5rem !important;
  }

  .pb-sm-2,
.py-sm-2 {
    padding-bottom: 0.5rem !important;
  }

  .pl-sm-2,
.px-sm-2 {
    padding-left: 0.5rem !important;
  }

  .p-sm-3 {
    padding: 1rem !important;
  }

  .pt-sm-3,
.py-sm-3 {
    padding-top: 1rem !important;
  }

  .pr-sm-3,
.px-sm-3 {
    padding-right: 1rem !important;
  }

  .pb-sm-3,
.py-sm-3 {
    padding-bottom: 1rem !important;
  }

  .pl-sm-3,
.px-sm-3 {
    padding-left: 1rem !important;
  }

  .p-sm-4 {
    padding: 1.5rem !important;
  }

  .pt-sm-4,
.py-sm-4 {
    padding-top: 1.5rem !important;
  }

  .pr-sm-4,
.px-sm-4 {
    padding-right: 1.5rem !important;
  }

  .pb-sm-4,
.py-sm-4 {
    padding-bottom: 1.5rem !important;
  }

  .pl-sm-4,
.px-sm-4 {
    padding-left: 1.5rem !important;
  }

  .p-sm-5 {
    padding: 3rem !important;
  }

  .pt-sm-5,
.py-sm-5 {
    padding-top: 3rem !important;
  }

  .pr-sm-5,
.px-sm-5 {
    padding-right: 3rem !important;
  }

  .pb-sm-5,
.py-sm-5 {
    padding-bottom: 3rem !important;
  }

  .pl-sm-5,
.px-sm-5 {
    padding-left: 3rem !important;
  }

  .m-sm-n1 {
    margin: -0.25rem !important;
  }

  .mt-sm-n1,
.my-sm-n1 {
    margin-top: -0.25rem !important;
  }

  .mr-sm-n1,
.mx-sm-n1 {
    margin-right: -0.25rem !important;
  }

  .mb-sm-n1,
.my-sm-n1 {
    margin-bottom: -0.25rem !important;
  }

  .ml-sm-n1,
.mx-sm-n1 {
    margin-left: -0.25rem !important;
  }

  .m-sm-n2 {
    margin: -0.5rem !important;
  }

  .mt-sm-n2,
.my-sm-n2 {
    margin-top: -0.5rem !important;
  }

  .mr-sm-n2,
.mx-sm-n2 {
    margin-right: -0.5rem !important;
  }

  .mb-sm-n2,
.my-sm-n2 {
    margin-bottom: -0.5rem !important;
  }

  .ml-sm-n2,
.mx-sm-n2 {
    margin-left: -0.5rem !important;
  }

  .m-sm-n3 {
    margin: -1rem !important;
  }

  .mt-sm-n3,
.my-sm-n3 {
    margin-top: -1rem !important;
  }

  .mr-sm-n3,
.mx-sm-n3 {
    margin-right: -1rem !important;
  }

  .mb-sm-n3,
.my-sm-n3 {
    margin-bottom: -1rem !important;
  }

  .ml-sm-n3,
.mx-sm-n3 {
    margin-left: -1rem !important;
  }

  .m-sm-n4 {
    margin: -1.5rem !important;
  }

  .mt-sm-n4,
.my-sm-n4 {
    margin-top: -1.5rem !important;
  }

  .mr-sm-n4,
.mx-sm-n4 {
    margin-right: -1.5rem !important;
  }

  .mb-sm-n4,
.my-sm-n4 {
    margin-bottom: -1.5rem !important;
  }

  .ml-sm-n4,
.mx-sm-n4 {
    margin-left: -1.5rem !important;
  }

  .m-sm-n5 {
    margin: -3rem !important;
  }

  .mt-sm-n5,
.my-sm-n5 {
    margin-top: -3rem !important;
  }

  .mr-sm-n5,
.mx-sm-n5 {
    margin-right: -3rem !important;
  }

  .mb-sm-n5,
.my-sm-n5 {
    margin-bottom: -3rem !important;
  }

  .ml-sm-n5,
.mx-sm-n5 {
    margin-left: -3rem !important;
  }

  .m-sm-auto {
    margin: auto !important;
  }

  .mt-sm-auto,
.my-sm-auto {
    margin-top: auto !important;
  }

  .mr-sm-auto,
.mx-sm-auto {
    margin-right: auto !important;
  }

  .mb-sm-auto,
.my-sm-auto {
    margin-bottom: auto !important;
  }

  .ml-sm-auto,
.mx-sm-auto {
    margin-left: auto !important;
  }
}
@media (min-width: 768px) {
  .m-md-0 {
    margin: 0 !important;
  }

  .mt-md-0,
.my-md-0 {
    margin-top: 0 !important;
  }

  .mr-md-0,
.mx-md-0 {
    margin-right: 0 !important;
  }

  .mb-md-0,
.my-md-0 {
    margin-bottom: 0 !important;
  }

  .ml-md-0,
.mx-md-0 {
    margin-left: 0 !important;
  }

  .m-md-1 {
    margin: 0.25rem !important;
  }

  .mt-md-1,
.my-md-1 {
    margin-top: 0.25rem !important;
  }

  .mr-md-1,
.mx-md-1 {
    margin-right: 0.25rem !important;
  }

  .mb-md-1,
.my-md-1 {
    margin-bottom: 0.25rem !important;
  }

  .ml-md-1,
.mx-md-1 {
    margin-left: 0.25rem !important;
  }

  .m-md-2 {
    margin: 0.5rem !important;
  }

  .mt-md-2,
.my-md-2 {
    margin-top: 0.5rem !important;
  }

  .mr-md-2,
.mx-md-2 {
    margin-right: 0.5rem !important;
  }

  .mb-md-2,
.my-md-2 {
    margin-bottom: 0.5rem !important;
  }

  .ml-md-2,
.mx-md-2 {
    margin-left: 0.5rem !important;
  }

  .m-md-3 {
    margin: 1rem !important;
  }

  .mt-md-3,
.my-md-3 {
    margin-top: 1rem !important;
  }

  .mr-md-3,
.mx-md-3 {
    margin-right: 1rem !important;
  }

  .mb-md-3,
.my-md-3 {
    margin-bottom: 1rem !important;
  }

  .ml-md-3,
.mx-md-3 {
    margin-left: 1rem !important;
  }

  .m-md-4 {
    margin: 1.5rem !important;
  }

  .mt-md-4,
.my-md-4 {
    margin-top: 1.5rem !important;
  }

  .mr-md-4,
.mx-md-4 {
    margin-right: 1.5rem !important;
  }

  .mb-md-4,
.my-md-4 {
    margin-bottom: 1.5rem !important;
  }

  .ml-md-4,
.mx-md-4 {
    margin-left: 1.5rem !important;
  }

  .m-md-5 {
    margin: 3rem !important;
  }

  .mt-md-5,
.my-md-5 {
    margin-top: 3rem !important;
  }

  .mr-md-5,
.mx-md-5 {
    margin-right: 3rem !important;
  }

  .mb-md-5,
.my-md-5 {
    margin-bottom: 3rem !important;
  }

  .ml-md-5,
.mx-md-5 {
    margin-left: 3rem !important;
  }

  .p-md-0 {
    padding: 0 !important;
  }

  .pt-md-0,
.py-md-0 {
    padding-top: 0 !important;
  }

  .pr-md-0,
.px-md-0 {
    padding-right: 0 !important;
  }

  .pb-md-0,
.py-md-0 {
    padding-bottom: 0 !important;
  }

  .pl-md-0,
.px-md-0 {
    padding-left: 0 !important;
  }

  .p-md-1 {
    padding: 0.25rem !important;
  }

  .pt-md-1,
.py-md-1 {
    padding-top: 0.25rem !important;
  }

  .pr-md-1,
.px-md-1 {
    padding-right: 0.25rem !important;
  }

  .pb-md-1,
.py-md-1 {
    padding-bottom: 0.25rem !important;
  }

  .pl-md-1,
.px-md-1 {
    padding-left: 0.25rem !important;
  }

  .p-md-2 {
    padding: 0.5rem !important;
  }

  .pt-md-2,
.py-md-2 {
    padding-top: 0.5rem !important;
  }

  .pr-md-2,
.px-md-2 {
    padding-right: 0.5rem !important;
  }

  .pb-md-2,
.py-md-2 {
    padding-bottom: 0.5rem !important;
  }

  .pl-md-2,
.px-md-2 {
    padding-left: 0.5rem !important;
  }

  .p-md-3 {
    padding: 1rem !important;
  }

  .pt-md-3,
.py-md-3 {
    padding-top: 1rem !important;
  }

  .pr-md-3,
.px-md-3 {
    padding-right: 1rem !important;
  }

  .pb-md-3,
.py-md-3 {
    padding-bottom: 1rem !important;
  }

  .pl-md-3,
.px-md-3 {
    padding-left: 1rem !important;
  }

  .p-md-4 {
    padding: 1.5rem !important;
  }

  .pt-md-4,
.py-md-4 {
    padding-top: 1.5rem !important;
  }

  .pr-md-4,
.px-md-4 {
    padding-right: 1.5rem !important;
  }

  .pb-md-4,
.py-md-4 {
    padding-bottom: 1.5rem !important;
  }

  .pl-md-4,
.px-md-4 {
    padding-left: 1.5rem !important;
  }

  .p-md-5 {
    padding: 3rem !important;
  }

  .pt-md-5,
.py-md-5 {
    padding-top: 3rem !important;
  }

  .pr-md-5,
.px-md-5 {
    padding-right: 3rem !important;
  }

  .pb-md-5,
.py-md-5 {
    padding-bottom: 3rem !important;
  }

  .pl-md-5,
.px-md-5 {
    padding-left: 3rem !important;
  }

  .m-md-n1 {
    margin: -0.25rem !important;
  }

  .mt-md-n1,
.my-md-n1 {
    margin-top: -0.25rem !important;
  }

  .mr-md-n1,
.mx-md-n1 {
    margin-right: -0.25rem !important;
  }

  .mb-md-n1,
.my-md-n1 {
    margin-bottom: -0.25rem !important;
  }

  .ml-md-n1,
.mx-md-n1 {
    margin-left: -0.25rem !important;
  }

  .m-md-n2 {
    margin: -0.5rem !important;
  }

  .mt-md-n2,
.my-md-n2 {
    margin-top: -0.5rem !important;
  }

  .mr-md-n2,
.mx-md-n2 {
    margin-right: -0.5rem !important;
  }

  .mb-md-n2,
.my-md-n2 {
    margin-bottom: -0.5rem !important;
  }

  .ml-md-n2,
.mx-md-n2 {
    margin-left: -0.5rem !important;
  }

  .m-md-n3 {
    margin: -1rem !important;
  }

  .mt-md-n3,
.my-md-n3 {
    margin-top: -1rem !important;
  }

  .mr-md-n3,
.mx-md-n3 {
    margin-right: -1rem !important;
  }

  .mb-md-n3,
.my-md-n3 {
    margin-bottom: -1rem !important;
  }

  .ml-md-n3,
.mx-md-n3 {
    margin-left: -1rem !important;
  }

  .m-md-n4 {
    margin: -1.5rem !important;
  }

  .mt-md-n4,
.my-md-n4 {
    margin-top: -1.5rem !important;
  }

  .mr-md-n4,
.mx-md-n4 {
    margin-right: -1.5rem !important;
  }

  .mb-md-n4,
.my-md-n4 {
    margin-bottom: -1.5rem !important;
  }

  .ml-md-n4,
.mx-md-n4 {
    margin-left: -1.5rem !important;
  }

  .m-md-n5 {
    margin: -3rem !important;
  }

  .mt-md-n5,
.my-md-n5 {
    margin-top: -3rem !important;
  }

  .mr-md-n5,
.mx-md-n5 {
    margin-right: -3rem !important;
  }

  .mb-md-n5,
.my-md-n5 {
    margin-bottom: -3rem !important;
  }

  .ml-md-n5,
.mx-md-n5 {
    margin-left: -3rem !important;
  }

  .m-md-auto {
    margin: auto !important;
  }

  .mt-md-auto,
.my-md-auto {
    margin-top: auto !important;
  }

  .mr-md-auto,
.mx-md-auto {
    margin-right: auto !important;
  }

  .mb-md-auto,
.my-md-auto {
    margin-bottom: auto !important;
  }

  .ml-md-auto,
.mx-md-auto {
    margin-left: auto !important;
  }
}
@media (min-width: 992px) {
  .m-lg-0 {
    margin: 0 !important;
  }

  .mt-lg-0,
.my-lg-0 {
    margin-top: 0 !important;
  }

  .mr-lg-0,
.mx-lg-0 {
    margin-right: 0 !important;
  }

  .mb-lg-0,
.my-lg-0 {
    margin-bottom: 0 !important;
  }

  .ml-lg-0,
.mx-lg-0 {
    margin-left: 0 !important;
  }

  .m-lg-1 {
    margin: 0.25rem !important;
  }

  .mt-lg-1,
.my-lg-1 {
    margin-top: 0.25rem !important;
  }

  .mr-lg-1,
.mx-lg-1 {
    margin-right: 0.25rem !important;
  }

  .mb-lg-1,
.my-lg-1 {
    margin-bottom: 0.25rem !important;
  }

  .ml-lg-1,
.mx-lg-1 {
    margin-left: 0.25rem !important;
  }

  .m-lg-2 {
    margin: 0.5rem !important;
  }

  .mt-lg-2,
.my-lg-2 {
    margin-top: 0.5rem !important;
  }

  .mr-lg-2,
.mx-lg-2 {
    margin-right: 0.5rem !important;
  }

  .mb-lg-2,
.my-lg-2 {
    margin-bottom: 0.5rem !important;
  }

  .ml-lg-2,
.mx-lg-2 {
    margin-left: 0.5rem !important;
  }

  .m-lg-3 {
    margin: 1rem !important;
  }

  .mt-lg-3,
.my-lg-3 {
    margin-top: 1rem !important;
  }

  .mr-lg-3,
.mx-lg-3 {
    margin-right: 1rem !important;
  }

  .mb-lg-3,
.my-lg-3 {
    margin-bottom: 1rem !important;
  }

  .ml-lg-3,
.mx-lg-3 {
    margin-left: 1rem !important;
  }

  .m-lg-4 {
    margin: 1.5rem !important;
  }

  .mt-lg-4,
.my-lg-4 {
    margin-top: 1.5rem !important;
  }

  .mr-lg-4,
.mx-lg-4 {
    margin-right: 1.5rem !important;
  }

  .mb-lg-4,
.my-lg-4 {
    margin-bottom: 1.5rem !important;
  }

  .ml-lg-4,
.mx-lg-4 {
    margin-left: 1.5rem !important;
  }

  .m-lg-5 {
    margin: 3rem !important;
  }

  .mt-lg-5,
.my-lg-5 {
    margin-top: 3rem !important;
  }

  .mr-lg-5,
.mx-lg-5 {
    margin-right: 3rem !important;
  }

  .mb-lg-5,
.my-lg-5 {
    margin-bottom: 3rem !important;
  }

  .ml-lg-5,
.mx-lg-5 {
    margin-left: 3rem !important;
  }

  .p-lg-0 {
    padding: 0 !important;
  }

  .pt-lg-0,
.py-lg-0 {
    padding-top: 0 !important;
  }

  .pr-lg-0,
.px-lg-0 {
    padding-right: 0 !important;
  }

  .pb-lg-0,
.py-lg-0 {
    padding-bottom: 0 !important;
  }

  .pl-lg-0,
.px-lg-0 {
    padding-left: 0 !important;
  }

  .p-lg-1 {
    padding: 0.25rem !important;
  }

  .pt-lg-1,
.py-lg-1 {
    padding-top: 0.25rem !important;
  }

  .pr-lg-1,
.px-lg-1 {
    padding-right: 0.25rem !important;
  }

  .pb-lg-1,
.py-lg-1 {
    padding-bottom: 0.25rem !important;
  }

  .pl-lg-1,
.px-lg-1 {
    padding-left: 0.25rem !important;
  }

  .p-lg-2 {
    padding: 0.5rem !important;
  }

  .pt-lg-2,
.py-lg-2 {
    padding-top: 0.5rem !important;
  }

  .pr-lg-2,
.px-lg-2 {
    padding-right: 0.5rem !important;
  }

  .pb-lg-2,
.py-lg-2 {
    padding-bottom: 0.5rem !important;
  }

  .pl-lg-2,
.px-lg-2 {
    padding-left: 0.5rem !important;
  }

  .p-lg-3 {
    padding: 1rem !important;
  }

  .pt-lg-3,
.py-lg-3 {
    padding-top: 1rem !important;
  }

  .pr-lg-3,
.px-lg-3 {
    padding-right: 1rem !important;
  }

  .pb-lg-3,
.py-lg-3 {
    padding-bottom: 1rem !important;
  }

  .pl-lg-3,
.px-lg-3 {
    padding-left: 1rem !important;
  }

  .p-lg-4 {
    padding: 1.5rem !important;
  }

  .pt-lg-4,
.py-lg-4 {
    padding-top: 1.5rem !important;
  }

  .pr-lg-4,
.px-lg-4 {
    padding-right: 1.5rem !important;
  }

  .pb-lg-4,
.py-lg-4 {
    padding-bottom: 1.5rem !important;
  }

  .pl-lg-4,
.px-lg-4 {
    padding-left: 1.5rem !important;
  }

  .p-lg-5 {
    padding: 3rem !important;
  }

  .pt-lg-5,
.py-lg-5 {
    padding-top: 3rem !important;
  }

  .pr-lg-5,
.px-lg-5 {
    padding-right: 3rem !important;
  }

  .pb-lg-5,
.py-lg-5 {
    padding-bottom: 3rem !important;
  }

  .pl-lg-5,
.px-lg-5 {
    padding-left: 3rem !important;
  }

  .m-lg-n1 {
    margin: -0.25rem !important;
  }

  .mt-lg-n1,
.my-lg-n1 {
    margin-top: -0.25rem !important;
  }

  .mr-lg-n1,
.mx-lg-n1 {
    margin-right: -0.25rem !important;
  }

  .mb-lg-n1,
.my-lg-n1 {
    margin-bottom: -0.25rem !important;
  }

  .ml-lg-n1,
.mx-lg-n1 {
    margin-left: -0.25rem !important;
  }

  .m-lg-n2 {
    margin: -0.5rem !important;
  }

  .mt-lg-n2,
.my-lg-n2 {
    margin-top: -0.5rem !important;
  }

  .mr-lg-n2,
.mx-lg-n2 {
    margin-right: -0.5rem !important;
  }

  .mb-lg-n2,
.my-lg-n2 {
    margin-bottom: -0.5rem !important;
  }

  .ml-lg-n2,
.mx-lg-n2 {
    margin-left: -0.5rem !important;
  }

  .m-lg-n3 {
    margin: -1rem !important;
  }

  .mt-lg-n3,
.my-lg-n3 {
    margin-top: -1rem !important;
  }

  .mr-lg-n3,
.mx-lg-n3 {
    margin-right: -1rem !important;
  }

  .mb-lg-n3,
.my-lg-n3 {
    margin-bottom: -1rem !important;
  }

  .ml-lg-n3,
.mx-lg-n3 {
    margin-left: -1rem !important;
  }

  .m-lg-n4 {
    margin: -1.5rem !important;
  }

  .mt-lg-n4,
.my-lg-n4 {
    margin-top: -1.5rem !important;
  }

  .mr-lg-n4,
.mx-lg-n4 {
    margin-right: -1.5rem !important;
  }

  .mb-lg-n4,
.my-lg-n4 {
    margin-bottom: -1.5rem !important;
  }

  .ml-lg-n4,
.mx-lg-n4 {
    margin-left: -1.5rem !important;
  }

  .m-lg-n5 {
    margin: -3rem !important;
  }

  .mt-lg-n5,
.my-lg-n5 {
    margin-top: -3rem !important;
  }

  .mr-lg-n5,
.mx-lg-n5 {
    margin-right: -3rem !important;
  }

  .mb-lg-n5,
.my-lg-n5 {
    margin-bottom: -3rem !important;
  }

  .ml-lg-n5,
.mx-lg-n5 {
    margin-left: -3rem !important;
  }

  .m-lg-auto {
    margin: auto !important;
  }

  .mt-lg-auto,
.my-lg-auto {
    margin-top: auto !important;
  }

  .mr-lg-auto,
.mx-lg-auto {
    margin-right: auto !important;
  }

  .mb-lg-auto,
.my-lg-auto {
    margin-bottom: auto !important;
  }

  .ml-lg-auto,
.mx-lg-auto {
    margin-left: auto !important;
  }
}
@media (min-width: 1200px) {
  .m-xl-0 {
    margin: 0 !important;
  }

  .mt-xl-0,
.my-xl-0 {
    margin-top: 0 !important;
  }

  .mr-xl-0,
.mx-xl-0 {
    margin-right: 0 !important;
  }

  .mb-xl-0,
.my-xl-0 {
    margin-bottom: 0 !important;
  }

  .ml-xl-0,
.mx-xl-0 {
    margin-left: 0 !important;
  }

  .m-xl-1 {
    margin: 0.25rem !important;
  }

  .mt-xl-1,
.my-xl-1 {
    margin-top: 0.25rem !important;
  }

  .mr-xl-1,
.mx-xl-1 {
    margin-right: 0.25rem !important;
  }

  .mb-xl-1,
.my-xl-1 {
    margin-bottom: 0.25rem !important;
  }

  .ml-xl-1,
.mx-xl-1 {
    margin-left: 0.25rem !important;
  }

  .m-xl-2 {
    margin: 0.5rem !important;
  }

  .mt-xl-2,
.my-xl-2 {
    margin-top: 0.5rem !important;
  }

  .mr-xl-2,
.mx-xl-2 {
    margin-right: 0.5rem !important;
  }

  .mb-xl-2,
.my-xl-2 {
    margin-bottom: 0.5rem !important;
  }

  .ml-xl-2,
.mx-xl-2 {
    margin-left: 0.5rem !important;
  }

  .m-xl-3 {
    margin: 1rem !important;
  }

  .mt-xl-3,
.my-xl-3 {
    margin-top: 1rem !important;
  }

  .mr-xl-3,
.mx-xl-3 {
    margin-right: 1rem !important;
  }

  .mb-xl-3,
.my-xl-3 {
    margin-bottom: 1rem !important;
  }

  .ml-xl-3,
.mx-xl-3 {
    margin-left: 1rem !important;
  }

  .m-xl-4 {
    margin: 1.5rem !important;
  }

  .mt-xl-4,
.my-xl-4 {
    margin-top: 1.5rem !important;
  }

  .mr-xl-4,
.mx-xl-4 {
    margin-right: 1.5rem !important;
  }

  .mb-xl-4,
.my-xl-4 {
    margin-bottom: 1.5rem !important;
  }

  .ml-xl-4,
.mx-xl-4 {
    margin-left: 1.5rem !important;
  }

  .m-xl-5 {
    margin: 3rem !important;
  }

  .mt-xl-5,
.my-xl-5 {
    margin-top: 3rem !important;
  }

  .mr-xl-5,
.mx-xl-5 {
    margin-right: 3rem !important;
  }

  .mb-xl-5,
.my-xl-5 {
    margin-bottom: 3rem !important;
  }

  .ml-xl-5,
.mx-xl-5 {
    margin-left: 3rem !important;
  }

  .p-xl-0 {
    padding: 0 !important;
  }

  .pt-xl-0,
.py-xl-0 {
    padding-top: 0 !important;
  }

  .pr-xl-0,
.px-xl-0 {
    padding-right: 0 !important;
  }

  .pb-xl-0,
.py-xl-0 {
    padding-bottom: 0 !important;
  }

  .pl-xl-0,
.px-xl-0 {
    padding-left: 0 !important;
  }

  .p-xl-1 {
    padding: 0.25rem !important;
  }

  .pt-xl-1,
.py-xl-1 {
    padding-top: 0.25rem !important;
  }

  .pr-xl-1,
.px-xl-1 {
    padding-right: 0.25rem !important;
  }

  .pb-xl-1,
.py-xl-1 {
    padding-bottom: 0.25rem !important;
  }

  .pl-xl-1,
.px-xl-1 {
    padding-left: 0.25rem !important;
  }

  .p-xl-2 {
    padding: 0.5rem !important;
  }

  .pt-xl-2,
.py-xl-2 {
    padding-top: 0.5rem !important;
  }

  .pr-xl-2,
.px-xl-2 {
    padding-right: 0.5rem !important;
  }

  .pb-xl-2,
.py-xl-2 {
    padding-bottom: 0.5rem !important;
  }

  .pl-xl-2,
.px-xl-2 {
    padding-left: 0.5rem !important;
  }

  .p-xl-3 {
    padding: 1rem !important;
  }

  .pt-xl-3,
.py-xl-3 {
    padding-top: 1rem !important;
  }

  .pr-xl-3,
.px-xl-3 {
    padding-right: 1rem !important;
  }

  .pb-xl-3,
.py-xl-3 {
    padding-bottom: 1rem !important;
  }

  .pl-xl-3,
.px-xl-3 {
    padding-left: 1rem !important;
  }

  .p-xl-4 {
    padding: 1.5rem !important;
  }

  .pt-xl-4,
.py-xl-4 {
    padding-top: 1.5rem !important;
  }

  .pr-xl-4,
.px-xl-4 {
    padding-right: 1.5rem !important;
  }

  .pb-xl-4,
.py-xl-4 {
    padding-bottom: 1.5rem !important;
  }

  .pl-xl-4,
.px-xl-4 {
    padding-left: 1.5rem !important;
  }

  .p-xl-5 {
    padding: 3rem !important;
  }

  .pt-xl-5,
.py-xl-5 {
    padding-top: 3rem !important;
  }

  .pr-xl-5,
.px-xl-5 {
    padding-right: 3rem !important;
  }

  .pb-xl-5,
.py-xl-5 {
    padding-bottom: 3rem !important;
  }

  .pl-xl-5,
.px-xl-5 {
    padding-left: 3rem !important;
  }

  .m-xl-n1 {
    margin: -0.25rem !important;
  }

  .mt-xl-n1,
.my-xl-n1 {
    margin-top: -0.25rem !important;
  }

  .mr-xl-n1,
.mx-xl-n1 {
    margin-right: -0.25rem !important;
  }

  .mb-xl-n1,
.my-xl-n1 {
    margin-bottom: -0.25rem !important;
  }

  .ml-xl-n1,
.mx-xl-n1 {
    margin-left: -0.25rem !important;
  }

  .m-xl-n2 {
    margin: -0.5rem !important;
  }

  .mt-xl-n2,
.my-xl-n2 {
    margin-top: -0.5rem !important;
  }

  .mr-xl-n2,
.mx-xl-n2 {
    margin-right: -0.5rem !important;
  }

  .mb-xl-n2,
.my-xl-n2 {
    margin-bottom: -0.5rem !important;
  }

  .ml-xl-n2,
.mx-xl-n2 {
    margin-left: -0.5rem !important;
  }

  .m-xl-n3 {
    margin: -1rem !important;
  }

  .mt-xl-n3,
.my-xl-n3 {
    margin-top: -1rem !important;
  }

  .mr-xl-n3,
.mx-xl-n3 {
    margin-right: -1rem !important;
  }

  .mb-xl-n3,
.my-xl-n3 {
    margin-bottom: -1rem !important;
  }

  .ml-xl-n3,
.mx-xl-n3 {
    margin-left: -1rem !important;
  }

  .m-xl-n4 {
    margin: -1.5rem !important;
  }

  .mt-xl-n4,
.my-xl-n4 {
    margin-top: -1.5rem !important;
  }

  .mr-xl-n4,
.mx-xl-n4 {
    margin-right: -1.5rem !important;
  }

  .mb-xl-n4,
.my-xl-n4 {
    margin-bottom: -1.5rem !important;
  }

  .ml-xl-n4,
.mx-xl-n4 {
    margin-left: -1.5rem !important;
  }

  .m-xl-n5 {
    margin: -3rem !important;
  }

  .mt-xl-n5,
.my-xl-n5 {
    margin-top: -3rem !important;
  }

  .mr-xl-n5,
.mx-xl-n5 {
    margin-right: -3rem !important;
  }

  .mb-xl-n5,
.my-xl-n5 {
    margin-bottom: -3rem !important;
  }

  .ml-xl-n5,
.mx-xl-n5 {
    margin-left: -3rem !important;
  }

  .m-xl-auto {
    margin: auto !important;
  }

  .mt-xl-auto,
.my-xl-auto {
    margin-top: auto !important;
  }

  .mr-xl-auto,
.mx-xl-auto {
    margin-right: auto !important;
  }

  .mb-xl-auto,
.my-xl-auto {
    margin-bottom: auto !important;
  }

  .ml-xl-auto,
.mx-xl-auto {
    margin-left: auto !important;
  }
}
.stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  pointer-events: auto;
  content: "";
  background-color: rgba(0, 0, 0, 0);
}

.text-monospace {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !important;
}

.text-justify {
  text-align: justify !important;
}

.text-wrap {
  white-space: normal !important;
}

.text-nowrap {
  white-space: nowrap !important;
}

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.text-left {
  text-align: left !important;
}

.text-right, .form-footer {
  text-align: right !important;
}

.text-center {
  text-align: center !important;
}

@media (min-width: 576px) {
  .text-sm-left {
    text-align: left !important;
  }

  .text-sm-right {
    text-align: right !important;
  }

  .text-sm-center {
    text-align: center !important;
  }
}
@media (min-width: 768px) {
  .text-md-left {
    text-align: left !important;
  }

  .text-md-right {
    text-align: right !important;
  }

  .text-md-center {
    text-align: center !important;
  }
}
@media (min-width: 992px) {
  .text-lg-left {
    text-align: left !important;
  }

  .text-lg-right {
    text-align: right !important;
  }

  .text-lg-center {
    text-align: center !important;
  }
}
@media (min-width: 1200px) {
  .text-xl-left {
    text-align: left !important;
  }

  .text-xl-right {
    text-align: right !important;
  }

  .text-xl-center {
    text-align: center !important;
  }
}
.text-lowercase {
  text-transform: lowercase !important;
}

.text-uppercase {
  text-transform: uppercase !important;
}

.text-capitalize {
  text-transform: capitalize !important;
}

.font-weight-light {
  font-weight: 300 !important;
}

.font-weight-lighter {
  font-weight: lighter !important;
}

.font-weight-normal {
  font-weight: 400 !important;
}

.font-weight-bold {
  font-weight: 700 !important;
}

.font-weight-bolder {
  font-weight: bolder !important;
}

.font-italic {
  font-style: italic !important;
}

.text-white {
  color: #fff !important;
}

.text-primary {
  color: #faa50a !important;
}

a.text-primary:hover, a.text-primary:focus {
  color: #b47504 !important;
}

.text-secondary {
  color: #2387aa !important;
}

a.text-secondary:hover, a.text-secondary:focus {
  color: #16556b !important;
}

.text-success {
  color: #099b00 !important;
}

a.text-success:hover, a.text-success:focus {
  color: #054f00 !important;
}

.text-info {
  color: #697882 !important;
}

a.text-info:hover, a.text-info:focus {
  color: #475158 !important;
}

.text-warning {
  color: #eb780a !important;
}

a.text-warning:hover, a.text-warning:focus {
  color: #a25307 !important;
}

.text-danger {
  color: #dc0000 !important;
}

a.text-danger:hover, a.text-danger:focus {
  color: #900000 !important;
}

.text-light {
  color: #ffb900 !important;
}

a.text-light:hover, a.text-light:focus {
  color: #b38200 !important;
}

.text-dark {
  color: #eb780a !important;
}

a.text-dark:hover, a.text-dark:focus {
  color: #a25307 !important;
}

.text-inactive {
  color: #8b8b95 !important;
}

a.text-inactive:hover, a.text-inactive:focus {
  color: #65656f !important;
}

.text-body {
  color: #2d373c !important;
}

.text-muted {
  color: #6c757d !important;
}

.text-black-50 {
  color: rgba(0, 0, 0, 0.5) !important;
}

.text-white-50 {
  color: rgba(255, 255, 255, 0.5) !important;
}

.text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0;
}

.text-decoration-none {
  text-decoration: none !important;
}

.text-break {
  word-break: break-word !important;
  word-wrap: break-word !important;
}

.text-reset {
  color: inherit !important;
}

.visible {
  visibility: visible !important;
}

.invisible {
  visibility: hidden !important;
}

.icon-left, .a6 ::before, .a3 > span, .footer ul > li > a > span, ul.header-link-list > li > a > span, ul.default-link-list > li > a > span {
  margin-right: 8px;
}

.icon-action:hover {
  color: #005578;
}

.link-extern::after {
  font-family: "simpl-icons-newton";
  content: "";
}

@media print {
  *,
*::before,
*::after {
    text-shadow: none !important;
    box-shadow: none !important;
  }

  a:not(.btn) {
    text-decoration: underline;
  }

  abbr[title]::after {
    content: " (" attr(title) ")";
  }

  pre {
    white-space: pre-wrap !important;
  }

  pre,
blockquote {
    border: 1px solid #adb5bd;
    page-break-inside: avoid;
  }

  thead {
    display: table-header-group;
  }

  tr,
img {
    page-break-inside: avoid;
  }

  p, .p2, .p2d, .ps, .psd,
.p3,
.p3d, .p1, .p1d, .pd,
h2,
.h2d,
h3,
.h3d {
    orphans: 3;
    widows: 3;
  }

  h2, .h2d,
h3,
.h3d {
    page-break-after: avoid;
  }

  @page {
    size: a3;
  }
  body {
    min-width: 992px !important;
  }

  .container {
    min-width: 992px !important;
  }

  .navbar {
    display: none;
  }

  .badge {
    border: 1px solid #000;
  }

  .table {
    border-collapse: collapse !important;
  }
  .table td,
.table th {
    background-color: #fff !important;
  }

  .table-bordered th,
.table-bordered td {
    border: 1px solid #dee2e6 !important;
  }

  .table-dark {
    color: inherit;
  }
  .table-dark th,
.table-dark td,
.table-dark thead th,
.table-dark tbody + tbody {
    border-color: #dee2e6;
  }

  .table .thead-dark th {
    color: inherit;
    border-color: #dee2e6;
  }
}
.sidebar {
  background-color: #c5d8e8;
  border-right: 1px solid #777777;
  font-weight: normal;
  width: 190px;
  height: 100%;
  overflow: hidden;
}
.sidebar > li > a {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 20px 15px;
}
.sidebar > li > a span:first-child {
  margin-right: 10px;
}
.sidebar > li > a:hover, .sidebar > li > a:focus {
  background-color: #e3ecf2;
}
.sidebar > li.active {
  background-color: #fff;
}
.sidebar > li.active > a {
  color: #00b1ff;
}
.sidebar > li.nav-alternative {
  background-color: #333c47;
}
.sidebar > li.nav-alternative > a {
  color: #fff;
}
.sidebar > li.nav-alternative > a:hover,
.sidebar > li.nav-alternative > a:focus {
  background-color: #333c47;
}
.sidebar.sidebar-inverse {
  background-color: #2b333d;
}
.sidebar.sidebar-inverse > li > a {
  color: #c5d0de;
}
.sidebar.sidebar-inverse > li > a:hover, .sidebar.sidebar-inverse > li > a:focus {
  background-color: #333c47;
}
.sidebar.sidebar-inverse > li.active {
  background-color: #333c47;
}
.sidebar.sidebar-inverse > li.active > a {
  color: #fff;
}
.sidebar.sidebar-offcanvas {
  max-width: 0;
  min-height: 100vh;
  transition: max-width 0.5s ease-in-out;
}
.sidebar.sidebar-offcanvas.open {
  max-width: 190px;
}
@media (min-width: 992px) {
  .sidebar.sidebar-offcanvas {
    max-width: 190px;
  }
}
@media (max-width: 768px) {
  .sidebar.sidebar-offcanvas + .page-frame {
    box-shadow: -5px 0 10px rgba(0, 0, 0, 0.75);
  }
}
.sidebar.sidebar-collapsible {
  transition: width 0.5s ease-in-out;
}
@media (max-width: 0) {
  .sidebar.sidebar-collapsible {
    width: 50px;
  }
  .sidebar.sidebar-collapsible > li a {
    padding: 15px;
  }
  .sidebar.sidebar-collapsible > li a .list-group-item-value {
    display: none;
  }
}

h1, .h1d, h2, .h2d, h3, .h3d, h4, .h4d, .alert,
.alert-dismissible, h5, .h5d, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  margin-bottom: 0.5rem;
  font-weight: normal;
  line-height: 1.2;
  color: #2d373c;
}

h1, .h1d, .h1 {
  font-size: 2.1875rem;
}

h2, .h2d, .h2 {
  font-size: 1.75rem;
}

h3, .h3d, .h3 {
  font-size: 1.53125rem;
}

h4, .h4d, .alert,
.alert-dismissible, .h4 {
  font-size: 1.3125rem;
}

h5, .h5d, .h5 {
  font-size: 1.09375rem;
}

h6, .h6 {
  font-size: 0.875rem;
}

.lead {
  font-size: 1.09375rem;
  font-weight: 300;
}

.display-1 {
  font-size: 6rem;
  font-weight: 300;
  line-height: 1.2;
}

.display-2 {
  font-size: 5.5rem;
  font-weight: 300;
  line-height: 1.2;
}

.display-3 {
  font-size: 4.5rem;
  font-weight: 300;
  line-height: 1.2;
}

.display-4 {
  font-size: 3.5rem;
  font-weight: 300;
  line-height: 1.2;
}

hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}

small,
.small {
  font-size: 80%;
  font-weight: 400;
}

mark,
.mark {
  padding: 0.2em;
  background-color: #fcf8e3;
}

.list-unstyled {
  padding-left: 0;
  list-style: none;
}

.list-inline {
  padding-left: 0;
  list-style: none;
}

.list-inline-item {
  display: inline-block;
}
.list-inline-item:not(:last-child) {
  margin-right: 0.5rem;
}

.initialism {
  font-size: 90%;
  text-transform: uppercase;
}

.blockquote {
  margin-bottom: 1rem;
  font-size: 1.09375rem;
}

.blockquote-footer {
  display: block;
  font-size: 80%;
  color: #6c757d;
}
.blockquote-footer::before {
  content: "— ";
}

.h1d,
h1 {
  color: inherit;
  font-size: 48px;
  line-height: 1.125;
  font-weight: normal;
  margin-bottom: 40px;
}

.h2d,
h2 {
  color: inherit;
  font-size: 32px;
  line-height: 1.1875;
  font-weight: bold;
  margin-bottom: 30px;
}

.h3d,
h3 {
  color: inherit;
  font-size: 26px;
  line-height: 1.1875;
  font-weight: bold;
  margin-bottom: 30px;
}

.h4d, .alert,
.alert-dismissible,
h4 {
  color: inherit;
  font-size: 20px;
  line-height: 1.3;
  font-weight: bold;
  margin-bottom: 20px;
}

.h5d,
h5 {
  color: inherit;
  font-size: 16px;
  line-height: 1.375;
  font-weight: bold;
  margin-bottom: 15px;
}

.h1d,
.h2d,
.h3d,
.h4d,
.alert,
.alert-dismissible,
.h5d {
  color: #fff;
}

p, .p2, .p2d, .ps, .psd,
.p3,
.p3d, .p1, .p1d, .pd {
  font-size: 1rem;
}

.pd {
  color: #fff;
}

.p1, .p1d {
  font-size: 0.875rem;
  line-height: 1.4;
  color: #788791;
}

.p1d {
  color: #fff;
}

.p2, .p2d {
  font-size: 18px;
  line-height: 24px;
  color: #697882;
}

.ps, .psd,
.p2,
.p2d {
  font-weight: bold;
}

.p3, .p3d {
  color: #788791;
}

.psd {
  color: #fff;
}

.p2d {
  color: #cdd9e1;
}

.p3d {
  color: #cdd9e1;
}

.a3 {
  font-family: "Siemens Sans";
  color: #697882;
  line-height: 20px;
  padding-right: 10px;
  text-decoration: none;
}
.a3 :hover {
  color: #005578;
  text-decoration: underline;
}
.a3 :active {
  color: #005578;
}
.a3 :visited {
  color: #697882;
}
.a3 > span {
  border: 0;
  text-decoration: none;
}

.a6 {
  font-family: "Siemens Sans";
  font-weight: 500;
  color: #2d373c;
  font-size: 18px;
  line-height: 24px;
  padding-right: 10px;
}
.a6 :active,
.a6 :hover {
  color: #005578;
  text-decoration: underline;
}
.a6 :visited {
  color: #2d373c;
}
.a6 ::before {
  font-family: "simpl-icons-newton";
  content: "";
  vertical-align: bottom;
  border: 0;
  text-decoration: none;
}

.d {
  color: #2387aa;
  font-size: 14px;
  line-height: 20px;
}
.d :active,
.d :hover {
  color: #005578;
}

.navbar-statusbar {
  background-color: rgba(255, 255, 255, 0.92);
  min-height: 3.571rem;
}
@media (min-width: 992px) {
  .navbar-statusbar .navbar-nav > li > a {
    padding-top: 4px;
    padding-bottom: 1.2855rem;
  }
}
.navbar-statusbar .navbar-brand {
  padding: 1.2855rem 20px;
  height: 3.571rem;
  font-family: "Siemens Serif";
  font-size: 0;
}
.navbar-statusbar .navbar-logo {
  padding-left: 20px;
}
.navbar-statusbar .navbar-toggler {
  margin-top: 0.6995625rem;
  margin-bottom: 0.6995625rem;
  padding-top: -0.257125rem;
  padding-bottom: 0;
}
.navbar-statusbar .navbar-form {
  margin-top: 0.6995625rem;
  margin-bottom: 0.6995625rem;
  padding-top: 0.296875rem;
  padding-bottom: 0;
}
.navbar-statusbar .navbar-btn {
  margin-top: 0.6995625rem;
  margin-bottom: 0.6995625rem;
  padding-top: 0.296875rem;
  padding-bottom: 0;
}
.navbar-statusbar .navbar-btn.btn-sm, .navbar-statusbar .btn-group-sm > .navbar-btn.btn {
  margin-top: 0.6995625rem;
  margin-bottom: 0.6995625rem;
  padding-top: 2.171875rem-calc(1.5em + 0.5rem + 2px);
  padding-bottom: 0;
}
.navbar-statusbar .navbar-btn.btn-xs {
  margin-top: 0.6995625rem;
  margin-bottom: 0.6995625rem;
  padding-top: -19.828125rem;
  padding-bottom: 0;
}
.navbar-statusbar .navbar-text,
.navbar-statusbar .form-header h2,
.form-header .navbar-statusbar h2, .navbar-statusbar .form-header .h2d, .form-header .navbar-statusbar .h2d {
  margin-top: 0.6995625rem;
  margin-bottom: 0.6995625rem;
  padding-top: 1.171875rem;
  padding-bottom: 0;
}

.navbar-status {
  margin-top: 0.6995625rem;
  margin-bottom: 0.6995625rem;
  padding-top: 0rem;
  padding-bottom: 0;
  line-height: 0;
  border-right: 1px solid transparent;
  padding-left: 20px;
  padding-right: 20px;
  text-align: center;
  min-width: 50px;
  font-weight: inherit;
}
.navbar-status p::before, .navbar-status .pd::before, .navbar-status .p1::before, .navbar-status .p1d::before, .navbar-status .ps::before,
.navbar-status .p3::before, .navbar-status .p2::before, .navbar-status .psd::before, .navbar-status .p2d::before,
.navbar-status .p3d::before,
.navbar-status span::before {
  content: "​";
}
.navbar-status .item-title {
  text-transform: uppercase;
  color: #788791;
  font-size: 0.765625rem;
  line-height: 1;
  display: inline-block;
  margin-top: 0.3125rem;
}
.navbar-status .item-value {
  margin: 0;
  color: #2d373c;
  font-size: 1.09375rem;
  line-height: 1;
}

.navbar-status-success .item-value {
  color: #2387aa;
}

.navbar-status-info .item-value {
  color: #697882;
}

.navbar-status-warning .item-value {
  color: #dc0000;
}

.navbar-status-danger .item-value {
  color: #dc0000;
}

.navbar-status-inactive .item-value {
  color: #8b8b95;
}

.navbar-breadcrumb {
  min-height: 5rem;
}

.navbar-breadcrumb .breadcrumb {
  padding-top: 0;
  padding-bottom: 0;
  margin-bottom: 0;
}
.navbar-breadcrumb .breadcrumb > li {
  margin-top: 1.92855rem;
  margin-bottom: 1.92855rem;
}
.navbar-breadcrumb .breadcrumb > li a {
  padding-top: 0;
  padding-bottom: 0;
}

section {
  width: 100%;
  background: #fff;
  padding: 40px 0 60px;
}
section.secondary {
  background: #ebf0f5;
}
section.secondary .card {
  background-color: #fff;
}
section.secondary .accordion .card .card-header {
  background-color: #dfe6ed;
}
section.secondary .accordion .card .card-header:hover {
  background-color: #cdd9e1;
}
section.secondary .accordion .card .card-body {
  background-color: #ebf0f5;
}
section.secondary .accordion .card .active .card-header {
  background-color: transparent;
}
section.secondary .table > thead > tr > th,
section.secondary .table > thead > tr > td {
  background-image: linear-gradient(to bottom, #dfe6ed 0%, #dfe6ed 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#FFDFE6ED", endColorstr="#FFDFE6ED", GradientType=0);
  background-repeat: repeat-x;
  background-color: #dfe6ed;
  border-color: #ebf0f5;
}
section.secondary .table > caption + thead > tr:first-child > th,
section.secondary .table > caption + thead > tr:first-child > td,
section.secondary .table > colgroup + thead > tr:first-child > th,
section.secondary .table > colgroup + thead > tr:first-child > td,
section.secondary .table > thead:first-child > tr:first-child > th,
section.secondary .table > thead:first-child > tr:first-child > td {
  border-top: 1px solid #ebf0f5;
}
section.secondary .table > tbody > tr {
  background-color: #fff;
}
section.secondary .table > tbody > tr:first-child > td {
  border-top: #cdd9e1;
}
section.secondary .table-hover > tbody > tr:hover {
  background-color: #ebf0f5;
}
section.shoutbox {
  background: #006487;
  color: #fff;
}
section.shoutbox .btn-secondary:not(:disabled):not(.disabled):hover {
  background: #50bed7;
  border-color: #50bed7;
}
section.shoutbox .btn-primary:not(:disabled):not(.disabled):hover {
  background: #ffb900;
  border-color: #ffb900;
}
section.shoutbox .btn-default {
  color: #fff;
  border-color: #fff;
}
section.shoutbox .btn-default:hover:not(.disabled):not(:disabled) {
  background: #fff;
  border-color: #fff;
  color: #005578;
}
section.shoutbox a:not(.dropdown-item) {
  color: #fff;
}
section.shoutbox .btn.btn-link {
  color: #fff;
}
section.shoutbox .pagination > li > a,
section.shoutbox .pagination > li > span {
  color: #fff;
  background-color: transparent;
  border: 1px solid 0;
}
section.shoutbox .pagination > li > a:hover, section.shoutbox .pagination > li > a:focus,
section.shoutbox .pagination > li > span:hover,
section.shoutbox .pagination > li > span:focus {
  color: #006487;
  background-color: #fff;
  border-color: #dee2e6;
}
section.shoutbox .pagination > .active > a, section.shoutbox .pagination > .active > a:hover, section.shoutbox .pagination > .active > a:focus,
section.shoutbox .pagination > .active > span,
section.shoutbox .pagination > .active > span:hover,
section.shoutbox .pagination > .active > span:focus {
  color: #006487;
  background-color: #fff;
  border-color: #005578;
}
section.shoutbox .card {
  color: #2d373c;
}
section.related-content {
  background: #3c464b;
  color: #fff;
}
section.related-content .btn-secondary:not(:disabled):not(.disabled):hover {
  background: #50bed7;
  border-color: #50bed7;
}
section.related-content .btn-primary:not(:disabled):not(.disabled):hover {
  background: #ffb900;
  border-color: #ffb900;
}
section.related-content .btn-default {
  color: #fff;
  border-color: #fff;
}
section.related-content .btn-default:hover:not(.disabled):not(:disabled) {
  background: #fff;
  border-color: #fff;
  color: #005578;
}
section.related-content a:not(.dropdown-item) {
  color: #fff;
}
section.related-content .btn.btn-link {
  color: #fff;
}
section.related-content .pagination > li > a,
section.related-content .pagination > li > span {
  color: #fff;
  background-color: transparent;
  border: 1px solid 0;
}
section.related-content .pagination > li > a:hover, section.related-content .pagination > li > a:focus,
section.related-content .pagination > li > span:hover,
section.related-content .pagination > li > span:focus {
  color: #3c464b;
  background-color: #fff;
  border-color: #dee2e6;
}
section.related-content .pagination > .active > a, section.related-content .pagination > .active > a:hover, section.related-content .pagination > .active > a:focus,
section.related-content .pagination > .active > span,
section.related-content .pagination > .active > span:hover,
section.related-content .pagination > .active > span:focus {
  color: #3c464b;
  background-color: #fff;
  border-color: #005578;
}
section.related-content .card {
  color: #2d373c;
}
section.related-content .card {
  background-color: #fff;
}
section.related-content .card h1, section.related-content .card .h1d,
section.related-content .card h2,
section.related-content .card .h2d,
section.related-content .card h3,
section.related-content .card .h3d,
section.related-content .card h4,
section.related-content .card .h4d,
section.related-content .card .alert,
section.related-content .card .alert-dismissible,
section.related-content .card h5,
section.related-content .card .h5d,
section.related-content .card h6 {
  color: #2d373c;
}
section.tabs {
  background: #ebf0f5;
  padding: 0;
}

section {
  width: 100%;
  background: #fff;
  padding: 40px 0 50px;
}
section.secondary {
  background: #ebf0f5;
}
section.shoutbox {
  background: #006487;
}
section.related-content {
  background: #3c464b;
}
section.tabs {
  background: #ebf0f5;
  padding: 0;
}

main > section:first-of-type {
  padding-top: 0;
}

@font-face {
  font-family: "simpl-icons-newton";
  src: url("~@simpl/newton-icons/dist/fonts//simpl-icons-newton.woff2") format("woff2"), url("~@simpl/newton-icons/dist/fonts//simpl-icons-newton.woff") format("woff"), url("~@simpl/newton-icons/dist/fonts//simpl-icons-newton.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}
[class^=newton-],
[class*=" newton-"] {
  display: inline-block;
  font-family: "simpl-icons-newton";
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  vertical-align: middle;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.newton-360-feature::before {
  content: "";
}

.newton-add-to-download-basket::before {
  content: "";
}

.newton-android-app-store::before {
  content: "";
}

.newton-aparat-negative::before {
  content: "";
}

.newton-aparat::before {
  content: "";
}

.newton-apple-mac-app-store::before {
  content: "";
}

.newton-apps::before {
  content: "";
}

.newton-arrow-down-large::before {
  content: "";
}

.newton-arrow-down-small::before {
  content: "";
}

.newton-arrow-left-large::before {
  content: "";
}

.newton-arrow-left-small::before {
  content: "";
}

.newton-arrow-right-large::before {
  content: "";
}

.newton-arrow-right-small::before {
  content: "";
}

.newton-arrow-to-top::before {
  content: "";
}

.newton-arrow-up-large::before {
  content: "";
}

.newton-arrow-up-small::before {
  content: "";
}

.newton-at::before {
  content: "";
}

.newton-audio-down::before {
  content: "";
}

.newton-audio-off::before {
  content: "";
}

.newton-audio-on::before {
  content: "";
}

.newton-audio-up::before {
  content: "";
}

.newton-audio::before {
  content: "";
}

.newton-avi::before {
  content: "";
}

.newton-biography::before {
  content: "";
}

.newton-bookmark::before {
  content: "";
}

.newton-calculate::before {
  content: "";
}

.newton-calendar::before {
  content: "";
}

.newton-career::before {
  content: "";
}

.newton-chat::before {
  content: "";
}

.newton-check-mark::before {
  content: "";
}

.newton-circle::before {
  content: "";
}

.newton-clock::before {
  content: "";
}

.newton-close::before {
  content: "";
}

.newton-conference::before {
  content: "";
}

.newton-control::before {
  content: "";
}

.newton-delete::before {
  content: "";
}

.newton-doc::before {
  content: "";
}

.newton-double-arrow-backward::before {
  content: "";
}

.newton-double-arrow-down::before {
  content: "";
}

.newton-double-arrow-forward::before {
  content: "";
}

.newton-double-arrow-up::before {
  content: "";
}

.newton-download-basket::before {
  content: "";
}

.newton-download::before {
  content: "";
}

.newton-drag::before {
  content: "";
}

.newton-edit::before {
  content: "";
}

.newton-email::before {
  content: "";
}

.newton-embed::before {
  content: "";
}

.newton-eps::before {
  content: "";
}

.newton-ext::before {
  content: "";
}

.newton-facebook-negative::before {
  content: "";
}

.newton-facebook::before {
  content: "";
}

.newton-facts-and-forecasts::before {
  content: "";
}

.newton-fast-backward::before {
  content: "";
}

.newton-fast-forward::before {
  content: "";
}

.newton-fax::before {
  content: "";
}

.newton-feature::before {
  content: "";
}

.newton-file::before {
  content: "";
}

.newton-files::before {
  content: "";
}

.newton-filter::before {
  content: "";
}

.newton-footage::before {
  content: "";
}

.newton-forum::before {
  content: "";
}

.newton-fullscreen::before {
  content: "";
}

.newton-gif::before {
  content: "";
}

.newton-glassdoor-negative::before {
  content: "";
}

.newton-glassdoor::before {
  content: "";
}

.newton-globe::before {
  content: "";
}

.newton-glossary::before {
  content: "";
}

.newton-google-negative::before {
  content: "";
}

.newton-google::before {
  content: "";
}

.newton-home::before {
  content: "";
}

.newton-infographic::before {
  content: "";
}

.newton-information-package::before {
  content: "";
}

.newton-information::before {
  content: "";
}

.newton-instagram-negative::before {
  content: "";
}

.newton-instagram::before {
  content: "";
}

.newton-investor-relations::before {
  content: "";
}

.newton-jpg::before {
  content: "";
}

.newton-legal-notice::before {
  content: "";
}

.newton-level-up::before {
  content: "";
}

.newton-linkedin-negative::before {
  content: "";
}

.newton-linkedin::before {
  content: "";
}

.newton-list-view::before {
  content: "";
}

.newton-location-pin::before {
  content: "";
}

.newton-log-in::before {
  content: "";
}

.newton-log-out::before {
  content: "";
}

.newton-menu::before {
  content: "";
}

.newton-minus::before {
  content: "";
}

.newton-more::before {
  content: "";
}

.newton-mov::before {
  content: "";
}

.newton-mp3::before {
  content: "";
}

.newton-mp4::before {
  content: "";
}

.newton-navigation-gyroscope::before {
  content: "";
}

.newton-navigation-mouse::before {
  content: "";
}

.newton-outline::before {
  content: "";
}

.newton-pause::before {
  content: "";
}

.newton-pdf::before {
  content: "";
}

.newton-phone::before {
  content: "";
}

.newton-picture-set::before {
  content: "";
}

.newton-picture::before {
  content: "";
}

.newton-play::before {
  content: "";
}

.newton-plus::before {
  content: "";
}

.newton-png::before {
  content: "";
}

.newton-ppt::before {
  content: "";
}

.newton-presentation::before {
  content: "";
}

.newton-press-release::before {
  content: "";
}

.newton-press::before {
  content: "";
}

.newton-print::before {
  content: "";
}

.newton-question::before {
  content: "";
}

.newton-quotation::before {
  content: "";
}

.newton-rating::before {
  content: "";
}

.newton-rectification::before {
  content: "";
}

.newton-region::before {
  content: "";
}

.newton-renren-negative::before {
  content: "";
}

.newton-renren::before {
  content: "";
}

.newton-replay::before {
  content: "";
}

.newton-rss::before {
  content: "";
}

.newton-scaling::before {
  content: "";
}

.newton-search::before {
  content: "";
}

.newton-service::before {
  content: "";
}

.newton-settings::before {
  content: "";
}

.newton-share-ios::before {
  content: "";
}

.newton-share-newton-android::before {
  content: "";
}

.newton-shopping-cart::before {
  content: "";
}

.newton-show-location::before {
  content: "";
}

.newton-shuffle::before {
  content: "";
}

.newton-single-arrow-backward::before {
  content: "";
}

.newton-single-arrow-down::before {
  content: "";
}

.newton-single-arrow-forward::before {
  content: "";
}

.newton-single-arrow-up::before {
  content: "";
}

.newton-skip-backward::before {
  content: "";
}

.newton-skip-forward::before {
  content: "";
}

.newton-slider::before {
  content: "";
}

.newton-slideshow::before {
  content: "";
}

.newton-social-feed::before {
  content: "";
}

.newton-stop::before {
  content: "";
}

.newton-subscribe::before {
  content: "";
}

.newton-support::before {
  content: "";
}

.newton-theenergyblog-negative::before {
  content: "";
}

.newton-theenergyblog::before {
  content: "";
}

.newton-tickets::before {
  content: "";
}

.newton-tif::before {
  content: "";
}

.newton-tile-view::before {
  content: "";
}

.newton-trade-fair::before {
  content: "";
}

.newton-tumblr-negative::before {
  content: "";
}

.newton-tumblr::before {
  content: "";
}

.newton-twitter-negative::before {
  content: "";
}

.newton-twitter::before {
  content: "";
}

.newton-user-account::before {
  content: "";
}

.newton-vcf::before {
  content: "";
}

.newton-video-chat::before {
  content: "";
}

.newton-videos::before {
  content: "";
}

.newton-warning::before {
  content: "";
}

.newton-webcast::before {
  content: "";
}

.newton-webinar::before {
  content: "";
}

.newton-weibo-negative::before {
  content: "";
}

.newton-weibo::before {
  content: "";
}

.newton-weixin-negative::before {
  content: "";
}

.newton-weixin::before {
  content: "";
}

.newton-windows-app-store::before {
  content: "";
}

.newton-xls::before {
  content: "";
}

.newton-youku-negative::before {
  content: "";
}

.newton-youku::before {
  content: "";
}

.newton-youtube-negative::before {
  content: "";
}

.newton-youtube::before {
  content: "";
}

.newton-zip::before {
  content: "";
}

.newton-zoom::before {
  content: "";
}

/* © Siemens AG - 4.0.0 - DIRTY */
/* Copyright Siemens AG, 2018 */
/* Licensed under the Siemens Inner Source License 1.1 */
/* .bs-datepicker */
.button-action {
  border: 0;
  border-color: transparent;
  background: none;
  box-shadow: none;
  outline: 0;
  position: absolute;
  right: 17px;
  top: 17px;
}
.button-action:focus, .button-action:active {
  border: 0;
  border-color: transparent;
  background: none;
  box-shadow: none;
  outline: 0;
  position: absolute;
  right: 17px;
  top: 17px;
}
.button-action::before {
  content: "";
  font-family: "simpl-icons-newton";
  font-size: 12px;
}

.icon-action.button-action,
label {
  cursor: pointer;
}

.bs-datepicker {
  display: flex;
  align-items: stretch;
  flex-flow: row wrap;
  background: #fff;
  box-shadow: 0 0 10px 0 #aaa;
  position: relative;
  z-index: 1051;
  /* button */
  /* .bs-datepicker-head */
  /* .bs-datepicker-body */
  /* .current-timedate */
  /* .bs-datepicker-multiple */
  /* .bs-datepicker-btns */
  /*.bs-datepicker-custom-range */
  /* .bs-datepicker-predefined-btns */
  /* .is-other-month */
  /* .bs-datepicker-buttons */
}
.bs-datepicker::after {
  clear: both;
  content: "";
  display: block;
}
.bs-datepicker bs-day-picker {
  float: left;
}
.bs-datepicker button:hover,
.bs-datepicker button:focus,
.bs-datepicker button:active,
.bs-datepicker input:hover,
.bs-datepicker input:focus,
.bs-datepicker input:active, .bs-datepicker-btns button:hover, .bs-datepicker-btns button:focus, .bs-datepicker-btns button:active, .bs-datepicker-predefined-btns button:active, .bs-datepicker-predefined-btns button:focus {
  outline: none;
}
.bs-datepicker-head {
  min-width: 270px;
  height: 50px;
  padding: 10px;
  border-radius: 0;
  text-align: justify;
  /* .bs-datepicker-head button */
}
.bs-datepicker-head::after {
  content: "";
  display: inline-block;
  vertical-align: top;
  width: 100%;
}
.bs-datepicker-head button {
  display: inline-block;
  vertical-align: top;
  padding: 0;
  height: 30px;
  line-height: 30px;
  border: 0;
  background: transparent;
  text-align: center;
  cursor: pointer;
  color: #fff;
  transition: 0.3s;
}
.bs-datepicker-head button[disabled], .bs-datepicker-head button[disabled]:hover, .bs-datepicker-head button[disabled]:active {
  background: rgba(221, 221, 221, 0.3);
  color: #f5f5f5;
  cursor: not-allowed;
}
.bs-datepicker-head button.next, .bs-datepicker-head button.previous {
  width: 30px;
  height: 30px;
}
.bs-datepicker-head button.next span, .bs-datepicker-head button.previous span {
  font-size: 28px;
  line-height: 1;
  display: inline-block;
  position: relative;
  height: 100%;
  width: 100%;
}
.bs-datepicker-head button.current {
  max-width: 155px;
  padding: 0 13px;
}
.bs-datepicker-head button:hover {
  background-color: rgba(0, 0, 0, 0.1);
}
.bs-datepicker-head button:active {
  background-color: rgba(0, 0, 0, 0.2);
}
.bs-datepicker-body {
  padding: 10px;
  min-height: 232px;
  min-width: 278px;
  border: 1px solid #dfe6ed;
  /* .bs-datepicker-body table */
}
.bs-datepicker-body .days.weeks {
  position: relative;
  z-index: 1;
}
.bs-datepicker-body table {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0;
  /* .bs-datepicker-body table.days */
  /* .bs-datepicker-body table.weeks */
}
.bs-datepicker-body table th {
  font-size: 13px;
  color: #9aaec1;
  font-weight: 400;
  text-align: center;
}
.bs-datepicker-body table td {
  color: #2d373c;
  text-align: center;
  position: relative;
  padding: 0;
}
.bs-datepicker-body table td span {
  display: block;
  margin: 0 auto;
  font-size: 13px;
  position: relative;
  /*z-index: 1;*/
  user-select: none;
}
.bs-datepicker-body table td:not(.disabled) span {
  cursor: pointer;
}
.bs-datepicker-body table td.is-highlighted:not(.disabled):not(.selected) span,
.bs-datepicker-body table td span.is-highlighted:not(.disabled):not(.selected) {
  background-color: #dfe6ed;
  transition: 0s;
}
.bs-datepicker-body table td span.disabled, .bs-datepicker-body table td.disabled span {
  color: #9aaec1;
}
.bs-datepicker-body table td span.selected, .bs-datepicker-body table td.selected span {
  color: #fff;
}
.bs-datepicker-body table td.active {
  position: relative;
}
.bs-datepicker-body table td.active.select-start::before {
  left: 35%;
}
.bs-datepicker-body table td.active.select-end::before {
  left: -85%;
}
.bs-datepicker-body table td span.active.select-start::after,
.bs-datepicker-body table td span.active.select-end::after, .bs-datepicker-body table td.active.select-start span::after, .bs-datepicker-body table td.active.select-end span::after {
  content: "";
  display: block;
  position: absolute;
  z-index: -1;
  width: 100%;
  height: 100%;
  transition: 0.3s;
  top: 0;
}
.bs-datepicker-body table td::before,
.bs-datepicker-body table td span::before {
  content: "";
  display: block;
  position: absolute;
  z-index: -1;
  top: 6px;
  bottom: 6px;
  left: -2px;
  right: -2px;
  box-sizing: content-box;
  background: transparent;
}
.bs-datepicker-body table td.active.select-start + td.active::before {
  left: -20%;
}
.bs-datepicker-body table td:last-child.active::before {
  border-radius: 0;
  width: 125%;
  left: -25%;
}
.bs-datepicker-body table td span[class*=select-], .bs-datepicker-body table td[class*=select-] span {
  color: #fff;
}
.bs-datepicker-body table.days td.active:not(.select-start)::before, .bs-datepicker-body table.days td.in-range:not(.select-start)::before,
.bs-datepicker-body table.days span.active:not(.select-start)::before,
.bs-datepicker-body table.days span.in-range:not(.select-start)::before {
  background: #dfe6ed;
}
.bs-datepicker-body table.days span {
  width: 32px;
  height: 32px;
  line-height: 32px;
}
.bs-datepicker-body table.days span.select-start {
  z-index: 2;
}
.bs-datepicker-body table.days span.is-highlighted.in-range::before, .bs-datepicker-body table.days span.in-range.select-end::before {
  background: none;
  right: 0;
  left: 0;
}
.bs-datepicker-body table.days td.select-start + td.select-end::before, .bs-datepicker-body table.days td.select-start + td.is-highlighted::before, .bs-datepicker-body table.days td.active + td.is-highlighted::before, .bs-datepicker-body table.days td.active + td.select-end::before, .bs-datepicker-body table.days td.in-range + td.is-highlighted::before, .bs-datepicker-body table.days td.in-range + td.select-end::before {
  background: #dfe6ed;
  width: 100%;
}
.bs-datepicker-body table.weeks tr td:nth-child(2).active::before {
  border-radius: 0;
  left: 0;
  width: 100%;
}
.bs-datepicker-body table:not(.weeks) tr td:first-child::before {
  border-radius: 0;
}
.bs-datepicker-body table.years td span {
  width: 46px;
  height: 46px;
  line-height: 45px;
  margin: 0 auto;
}
.bs-datepicker-body table.years tr:not(:last-child) td span {
  margin-bottom: 8px;
}
.bs-datepicker-body table.months td {
  height: 52px;
}
.bs-datepicker-body table.months td span {
  padding: 6px;
}
.bs-datepicker .current-timedate {
  color: #2d373c;
  font-size: 15px;
  text-align: center;
  height: 30px;
  line-height: 30px;
  border-radius: 0;
  border: 1px solid #dfe6ed;
  margin-bottom: 10px;
  cursor: pointer;
  text-transform: uppercase;
  user-select: none;
}
.bs-datepicker .current-timedate span:not(:empty)::before {
  content: "";
  width: 15px;
  height: 16px;
  display: inline-block;
  margin-right: 4px;
  vertical-align: text-bottom;
  background: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA8AAAAQCAYAAADJViUEAAABMklEQVQoU9VTwW3CQBCcOUgBtEBKSAukAnBKME+wFCAlYIhk8sQlxFABtJAScAsuAPBEewYcxCP8ouxrPDsza61uiVN1o6RNHD4htSCmq49RfO71BvMJqBBkITRf1kmUW49nQRC9h1I5AZlBClaL8aP1fKgOOxCx8aSLs+Q19eZuNO8QmPqJRtDFguy7OAcDbJPs+/BKVPDIPrvD2ZJgWAmVe7O0rI0Vqs1seyWUXpuJoppYCa5L+U++NpNPkr5OE2oMdARsb3gykJT5ydZcL8Z9Ww60nxg2LhjON9li9OwXZzo+xLbp3nC2s9CL2RrueGyVrgwNm8HpsCzZ9EEW6kqXlo1GQe03FzP/7W8Hl0dBtu7Bf7zt6mIwvX1RvzDCm7+q3mAW0Dl/GPdUCeXrZLT9BrDrGkm4qlPvAAAAAElFTkSuQmCC");
}
.bs-datepicker-multiple {
  display: inline-block;
  border-radius: 0;
}
.bs-datepicker-multiple + .bs-datepicker-multiple {
  margin-left: 10px;
}
.bs-datepicker-multiple .bs-datepicker {
  box-shadow: none;
  position: relative;
}
.bs-datepicker-multiple .bs-datepicker:not(:last-child) {
  padding-right: 10px;
}
.bs-datepicker-multiple .bs-datepicker + .bs-datepicker::after {
  content: "";
  display: block;
  width: 14px;
  height: 10px;
  background: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA8AAAAKCAYAAABrGwT5AAAA1ElEQVQoU42RsQrCUAxF77VuDu7O4oMWW//BURBBpZvgKk4uIrjoqKOTf+DopIO4uYggtFTfw3+pkQqCW1/G5J7kJiFy4m5MxUlxAzgIPHX+lzMPzupRYlYgxiR7vqsOP8YKzsTx0yxFMCUZ+q7aZzlr+OvgoWcAFyAHgat2jLWu48252DdqAihDJGSSJNUUxYmQjs3+hPQBlAh2rG2LCOPnaw3IiGDX99TRCs7ASJsNhUOA7d/LcuHvRG22FIZvsNXw1MX6VZExCilOQKEfeLXr/10+aC9Ho7arh7oAAAAASUVORK5CYII=");
  position: absolute;
  top: 25px;
  left: -8px;
}
.bs-datepicker-multiple .bs-datepicker .left {
  float: left;
}
.bs-datepicker-multiple .bs-datepicker .right {
  float: right;
}
.bs-datepicker-container {
  padding: 0;
}
.bs-datepicker-custom-range {
  padding: 15px;
  background: #eee;
}
.bs-datepicker-predefined-btns button {
  width: 100%;
  display: block;
  height: 30px;
  background-color: #9aaec1;
  border-radius: 0;
  color: #fff;
  border: 0;
  margin-bottom: 10px;
  padding: 0 18px;
  text-align: left;
  transition: 0.3s;
}
.bs-datepicker-predefined-btns button:active, .bs-datepicker-predefined-btns button:hover {
  background-color: #54708b;
}
.bs-datepicker .is-other-month {
  color: rgba(0, 0, 0, 0.25);
}
.bs-datepicker-buttons {
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-end;
  padding-top: 10px;
  border-top: 1px solid #dfe6ed;
}
.bs-datepicker-buttons .btn-secondary {
  margin-left: 10px;
}

/* .bs-timepicker */
.bs-timepicker-container {
  padding: 10px 0;
}
.bs-timepicker-label {
  color: #2d373c;
  margin-bottom: 10px;
}
.bs-timepicker-controls {
  display: inline-block;
  vertical-align: top;
  margin-right: 10px;
}
.bs-timepicker-controls button {
  width: 20px;
  height: 20px;
  border: 0;
  background-color: #dfe6ed;
  color: #2d373c;
  font-size: 16px;
  font-weight: 700;
  vertical-align: middle;
  line-height: 0;
  padding: 0;
  transition: 0.3s;
}
.bs-timepicker-controls button:hover {
  background-color: #dfe6ed;
}
.bs-timepicker-controls input {
  width: 35px;
  height: 25px;
  border-radius: 0;
  text-align: center;
  border: 1px solid #dfe6ed;
}
.bs-timepicker .switch-time-format {
  text-transform: uppercase;
  min-width: 54px;
  height: 25px;
  border-radius: 0;
  border: 1px solid #dfe6ed;
  background: #fff;
  color: #2d373c;
  font-size: 13px;
}
.bs-timepicker .switch-time-format img {
  vertical-align: initial;
  margin-left: 4px;
}

/* screen size < 1024px */
@media (max-width: 768px) {
  bs-datepicker-container,
bs-daterangepicker-container {
    position: fixed !important;
    left: 50% !important;
    top: 50% !important;
    transform: translate(-50%, -50%) !important;
    z-index: 1051;
  }

  .bs-datepicker-multiple {
    display: flex;
  }
  .bs-datepicker-multiple + .bs-datepicker-multiple {
    margin-top: 10px;
    margin-left: 0;
  }
}
/* theming */
.theme-default .bs-datepicker-head {
  background-color: #777;
}
.theme-default .bs-datepicker-body table td span.selected, .theme-default .bs-datepicker-body table td.selected span,
.theme-default .bs-datepicker-body table td span[class*=select-]::after, .theme-default .bs-datepicker-body table td[class*=select-] span::after {
  background-color: #777;
}
.theme-default .bs-datepicker-body table td.week span {
  color: #777;
}

.theme-green .bs-datepicker-head {
  background-color: #5cb85c;
}
.theme-green .bs-datepicker-body table td span.selected, .theme-green .bs-datepicker-body table td.selected span,
.theme-green .bs-datepicker-body table td span[class*=select-]::after, .theme-green .bs-datepicker-body table td[class*=select-] span::after {
  background-color: #5cb85c;
}
.theme-green .bs-datepicker-body table td.week span {
  color: #5cb85c;
}

.theme-blue .bs-datepicker-head {
  background-color: #5bc0de;
}
.theme-blue .bs-datepicker-body table td span.selected, .theme-blue .bs-datepicker-body table td.selected span,
.theme-blue .bs-datepicker-body table td span[class*=select-]::after, .theme-blue .bs-datepicker-body table td[class*=select-] span::after {
  background-color: #5bc0de;
}
.theme-blue .bs-datepicker-body table td.week span {
  color: #5bc0de;
}

.theme-dark-blue .bs-datepicker-head {
  background-color: #006487;
}
.theme-dark-blue .bs-datepicker-body table td span.selected, .theme-dark-blue .bs-datepicker-body table td.selected span,
.theme-dark-blue .bs-datepicker-body table td span[class*=select-]::after, .theme-dark-blue .bs-datepicker-body table td[class*=select-] span::after {
  background-color: #006487;
}
.theme-dark-blue .bs-datepicker-body table td.week span {
  color: #006487;
}

.theme-red .bs-datepicker-head {
  background-color: #d9534f;
}
.theme-red .bs-datepicker-body table td span.selected, .theme-red .bs-datepicker-body table td.selected span,
.theme-red .bs-datepicker-body table td span[class*=select-]::after, .theme-red .bs-datepicker-body table td[class*=select-] span::after {
  background-color: #d9534f;
}
.theme-red .bs-datepicker-body table td.week span {
  color: #d9534f;
}

.theme-orange .bs-datepicker-head {
  background-color: #faa50a;
}
.theme-orange .bs-datepicker-body table td span.selected, .theme-orange .bs-datepicker-body table td.selected span,
.theme-orange .bs-datepicker-body table td span[class*=select-]::after, .theme-orange .bs-datepicker-body table td[class*=select-] span::after {
  background-color: #faa50a;
}
.theme-orange .bs-datepicker-body table td.week span {
  color: #faa50a;
}

.navbar a.disabled {
  pointer-events: none;
  cursor: default;
}

#navbar.in [siNewtonHeaderTopRightMenu] {
  display: block;
}

li[siNewtonHeaderMenuItem] > .nav-dropdown-menu {
  display: block !important;
}

@media (max-width: 599px) {
  si-newton-header ul[siNewtonHeaderTopRightMenu] {
    display: none;
  }
  si-newton-header.with-mobile-nav ul[siNewtonHeaderTopRightMenu] {
    display: flex;
  }
  si-newton-header.with-mobile-nav .navbar-header {
    display: none !important;
  }
}
li[siNewtonHeaderMenuItem].nav-dropdown a {
  cursor: pointer;
}

.navbar-header {
  align-self: flex-start;
}

typeahead-container {
  right: 0;
}
typeahead-container .dropdown-menu > li.active > a {
  background-color: #dbe8f0;
  color: #000;
}

/**
* @license
* Copyright Siemens AG, 2017
* Licensed under the Siemens Inner Source License 1.1
*/
/* © Siemens AG - 4.0.0 - DIRTY */
/* Copyright Siemens AG, 2018 */
/* Licensed under the Siemens Inner Source License 1.1 */
/* stylelint-disable scss/dollar-variable-pattern */
/* stylelint-enable scss/dollar-variable-pattern */
/* © Siemens AG - 4.0.0 - DIRTY */
/* Copyright Siemens AG, 2018 */
/* Licensed under the Siemens Inner Source License 1.1 */
/* the above colors will be used for button gradients, but if the following
is not null the button will get a plain background colors */
accordion accordion-group.panel .panel-title {
  width: initial;
}
accordion accordion-group.panel .panel-title .accordion-toggle button {
  padding: 0;
  position: relative;
  font-size: 20px;
  width: 100%;
  text-align: left;
  box-shadow: none;
  line-height: 26px;
  opacity: 0.8;
}
accordion accordion-group.panel .panel-title .accordion-toggle button.btn-link {
  color: inherit;
}
accordion accordion-group.panel .panel-title .accordion-toggle button::after {
  content: "";
  font-family: "simpl-icons-newton";
  position: absolute;
  transition: transform 0.2s ease-in 0s;
  will-change: transform;
  top: calc(40% - 10px);
  font-size: 1.125rem;
  right: 0;
  opacity: 0.8;
}
accordion accordion-group.panel .panel-title .accordion-toggle button:hover, accordion accordion-group.panel .panel-title .accordion-toggle button:focus {
  text-decoration: none;
  opacity: 1;
}
accordion accordion-group.panel.panel-open .panel-title .accordion-toggle button {
  opacity: 1;
}
accordion accordion-group.panel.panel-open .panel-title .accordion-toggle button::after {
  content: "";
  opacity: 1;
  transform: rotate(-45deg);
}

accordion accordion-group[isDisabled=true] .panel-heading {
  cursor: not-allowed;
  opacity: 0.5;
}
accordion accordion-group[isDisabled=true] .panel-heading:hover, accordion accordion-group[isDisabled=true] .panel-heading:focus {
  background-color: #ebf0f5;
  cursor: not-allowed;
}
accordion accordion-group[isDisabled=true] .text-muted {
  color: #697882;
  /* stylelint-disable-next-line declaration-no-important */
  cursor: not-allowed !important;
}
accordion accordion-group[isDisabled=true] .text-muted:hover, accordion accordion-group[isDisabled=true] .text-muted:focus {
  text-decoration: none;
  color: #697882;
}

/**
* @license
* Copyright Siemens AG, 2017
* Licensed under the Siemens Inner Source License 1.1
*/
.ngx-datatable.newton {
  background: transparent;
}
.ngx-datatable.newton.striped .datatable-row-odd {
  background: #eee;
}
.ngx-datatable.newton.multi-click-selection .datatable-body-row.active, .ngx-datatable.newton.multi-click-selection .datatable-body-row.active .datatable-row-group, .ngx-datatable.newton.multi-selection .datatable-body-row.active, .ngx-datatable.newton.multi-selection .datatable-body-row.active .datatable-row-group, .ngx-datatable.newton.single-selection .datatable-body-row.active, .ngx-datatable.newton.single-selection .datatable-body-row.active .datatable-row-group {
  background-color: #304ffe;
  color: #fff;
}
.ngx-datatable.newton.multi-click-selection .datatable-body-row.active:hover, .ngx-datatable.newton.multi-click-selection .datatable-body-row.active:hover .datatable-row-group, .ngx-datatable.newton.multi-selection .datatable-body-row.active:hover, .ngx-datatable.newton.multi-selection .datatable-body-row.active:hover .datatable-row-group, .ngx-datatable.newton.single-selection .datatable-body-row.active:hover, .ngx-datatable.newton.single-selection .datatable-body-row.active:hover .datatable-row-group {
  background-color: #193ae4;
  color: #fff;
}
.ngx-datatable.newton.multi-click-selection .datatable-body-row.active:focus, .ngx-datatable.newton.multi-click-selection .datatable-body-row.active:focus .datatable-row-group, .ngx-datatable.newton.multi-selection .datatable-body-row.active:focus, .ngx-datatable.newton.multi-selection .datatable-body-row.active:focus .datatable-row-group, .ngx-datatable.newton.single-selection .datatable-body-row.active:focus, .ngx-datatable.newton.single-selection .datatable-body-row.active:focus .datatable-row-group {
  background-color: #2041ef;
  color: #fff;
}
.ngx-datatable.newton:not(.cell-selection) .datatable-body-row:hover, .ngx-datatable.newton:not(.cell-selection) .datatable-body-row:hover .datatable-row-group {
  background-color: #eee;
  transition-property: background;
  transition-duration: 0.3s;
  transition-timing-function: linear;
}
.ngx-datatable.newton:not(.cell-selection) .datatable-body-row:focus, .ngx-datatable.newton:not(.cell-selection) .datatable-body-row:focus .datatable-row-group {
  background-color: #ddd;
}
.ngx-datatable.newton.cell-selection .datatable-body-cell:hover, .ngx-datatable.newton.cell-selection .datatable-body-cell:hover .datatable-row-group {
  background-color: #eee;
  transition-property: background;
  transition-duration: 0.3s;
  transition-timing-function: linear;
}
.ngx-datatable.newton.cell-selection .datatable-body-cell:focus, .ngx-datatable.newton.cell-selection .datatable-body-cell:focus .datatable-row-group {
  background-color: #ddd;
}
.ngx-datatable.newton.cell-selection .datatable-body-cell.active, .ngx-datatable.newton.cell-selection .datatable-body-cell.active .datatable-row-group {
  background-color: #304ffe;
  color: #fff;
}
.ngx-datatable.newton.cell-selection .datatable-body-cell.active:hover, .ngx-datatable.newton.cell-selection .datatable-body-cell.active:hover .datatable-row-group {
  background-color: #193ae4;
  color: #fff;
}
.ngx-datatable.newton.cell-selection .datatable-body-cell.active:focus, .ngx-datatable.newton.cell-selection .datatable-body-cell.active:focus .datatable-row-group {
  background-color: #2041ef;
  color: #fff;
}
.ngx-datatable.newton .empty-row {
  height: 50px;
  text-align: left;
  padding: 0.5rem 1.2rem;
  vertical-align: top;
  border-top: 0;
}
.ngx-datatable.newton .loading-row {
  text-align: left;
  padding: 0.5rem 1.2rem;
  vertical-align: top;
  border-top: 0;
}
.ngx-datatable.newton .datatable-body .datatable-row-left,
.ngx-datatable.newton .datatable-header .datatable-row-left {
  background-color: #fff;
  background-position: 100% 0;
  background-repeat: repeat-y;
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAQAAAABCAYAAAD5PA/NAAAAFklEQVQIHWPSkNeSBmJhTQVtbiDNCgASagIIuJX8OgAAAABJRU5ErkJggg==");
}
.ngx-datatable.newton .datatable-body .datatable-row-right,
.ngx-datatable.newton .datatable-header .datatable-row-right {
  background-position: 0 0;
  background-color: #fff;
  background-repeat: repeat-y;
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAQAAAABCAYAAAD5PA/NAAAAFklEQVQI12PQkNdi1VTQ5gbSwkAsDQARLAIGtOSFUAAAAABJRU5ErkJggg==");
}
.ngx-datatable.newton .datatable-header {
  border-top: 1px solid rgba(0, 0, 0, 0.12);
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}
.ngx-datatable.newton .datatable-header .datatable-header-cell {
  text-align: left;
  padding: 0.5rem 1.2rem;
  font-weight: 500;
  vertical-align: bottom;
}
.ngx-datatable.newton .datatable-header .datatable-header-cell .datatable-header-cell-wrapper {
  position: relative;
}
.ngx-datatable.newton .datatable-header .datatable-header-cell.longpress .draggable::after {
  transition: transform 400ms ease, opacity 400ms ease;
  opacity: 0.5;
  transform: scale(1);
}
.ngx-datatable.newton .datatable-header .datatable-header-cell .draggable::after {
  content: " ";
  position: absolute;
  top: 50%;
  left: 50%;
  margin: -30px 0 0 -30px;
  height: 60px;
  width: 60px;
  background: #eee;
  border-radius: 100%;
  opacity: 1;
  filter: none;
  transform: scale(0);
  z-index: 9999;
  pointer-events: none;
}
.ngx-datatable.newton .datatable-header .datatable-header-cell.dragging .resize-handle {
  border-right: 0;
}
.ngx-datatable.newton .datatable-header .resize-handle {
  border-right: solid 1px #eee;
}
.ngx-datatable.newton .datatable-body .datatable-row-detail {
  background: #f5f5f5;
  padding: 10px;
}
.ngx-datatable.newton .datatable-body .datatable-body-row {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}
.ngx-datatable.newton .datatable-body .datatable-body-row .datatable-body-cell {
  text-align: left;
  padding: 0.5rem 1.2rem;
  vertical-align: top;
  border-top: 0;
  transition: width 0.3s ease;
}
.ngx-datatable.newton .datatable-body .progress-linear {
  display: block;
  position: relative;
  width: 100%;
  height: 5px;
  padding: 0;
  margin: 0;
  position: absolute;
}
.ngx-datatable.newton .datatable-body .progress-linear .container {
  display: block;
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 5px;
  transform: translate(0, 0) scale(1, 1);
  background-color: #aad1f9;
}
.ngx-datatable.newton .datatable-body .progress-linear .container .bar {
  transition: all 0.2s linear;
  animation: query 0.8s infinite cubic-bezier(0.39, 0.575, 0.565, 1);
  transition: -webkit-transform 0.2s linear;
  transition: transform 0.2s linear;
  background-color: #106cc8;
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 5px;
}
.ngx-datatable.newton .datatable-footer {
  font-size: 14px;
}
.ngx-datatable.newton .datatable-footer .page-count {
  line-height: 50px;
  height: 50px;
  padding: 0 1.2rem;
}
.ngx-datatable.newton .datatable-footer .datatable-pager {
  margin: 0 10px;
}
.ngx-datatable.newton .datatable-footer .datatable-pager li {
  vertical-align: middle;
}
.ngx-datatable.newton .datatable-footer .datatable-pager li.disabled a {
  color: #9bafbe !important;
  background-color: transparent !important;
}
.ngx-datatable.newton .datatable-footer .datatable-pager li.active a {
  background-color: #005578;
  color: #fff;
  font-weight: normal;
}
.ngx-datatable.newton .datatable-footer .datatable-pager a {
  height: 20px;
  min-width: 20px;
  line-height: 20px;
  padding: 0;
  margin: 0 2px;
  border-radius: 0;
  border-style: none;
  text-align: center;
  vertical-align: top;
  vertical-align: bottom;
  color: #005578;
  text-decoration: none;
}
.ngx-datatable.newton .datatable-footer .datatable-pager a:hover {
  color: #fff;
  background-color: #005578;
}
.ngx-datatable.newton .datatable-footer .datatable-pager .icon-left, .ngx-datatable.newton .datatable-footer .datatable-pager .footer ul > li > a > span, .footer .ngx-datatable.newton .datatable-footer .datatable-pager ul > li > a > span, .ngx-datatable.newton .datatable-footer .datatable-pager ul.header-link-list > li > a > span, .ngx-datatable.newton .datatable-footer .datatable-pager ul.default-link-list > li > a > span, .ngx-datatable.newton .datatable-footer .datatable-pager .a3 > span, .ngx-datatable.newton .datatable-footer .datatable-pager .a6 ::before, .a6 .ngx-datatable.newton .datatable-footer .datatable-pager ::before,
.ngx-datatable.newton .datatable-footer .datatable-pager .icon-prev,
.ngx-datatable.newton .datatable-footer .datatable-pager .icon-right,
.ngx-datatable.newton .datatable-footer .datatable-pager .icon-skip {
  font-size: 20px;
  line-height: 20px;
  padding: 0;
}

.datatable-checkbox {
  position: relative;
  margin: 0;
  cursor: pointer;
  vertical-align: middle;
  display: inline-block;
  box-sizing: border-box;
  padding: 10px 0;
}
.datatable-checkbox input[type=checkbox] {
  position: relative;
  margin: 0 1rem 0 0;
  cursor: pointer;
  outline: none;
}
.datatable-checkbox input[type=checkbox]::before {
  transition: all 0.3s ease-in-out;
  content: "";
  position: absolute;
  left: 0;
  z-index: 1;
  width: 1rem;
  height: 1rem;
  border: 2px solid #f2f2f2;
}
.datatable-checkbox input[type=checkbox]:checked::before {
  transform: rotate(-45deg);
  height: 0.5rem;
  border-color: #009688;
  border-top-style: none;
  border-right-style: none;
}
.datatable-checkbox input[type=checkbox]::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 1rem;
  height: 1rem;
  background: #fff;
  cursor: pointer;
}

@keyframes query {
  0% {
    opacity: 1;
    transform: translateX(35%) scale(0.3, 1);
  }
  100% {
    opacity: 0;
    transform: translateX(-50%) scale(0, 1);
  }
}
/* stylelint-disable scss/dollar-variable-pattern */
/* stylelint-enable scss/dollar-variable-pattern */
/* © Siemens AG - 4.0.0 - DIRTY */
/* Copyright Siemens AG, 2018 */
/* Licensed under the Siemens Inner Source License 1.1 */
/* the above colors will be used for button gradients, but if the following
is not null the button will get a plain background colors */
si-newton-dropdown.ng-invalid.ng-touched > button, button.ng-invalid.ng-touched,
input.ng-invalid.ng-touched,
textarea.ng-invalid.ng-touched {
  border: 0 solid #dc0000;
  outline: 1px solid #dc0000;
  color: #dc0000;
}

.invalid-feedback {
  left: -1px;
}

.si-invalid label {
  color: #dc0000;
}
.si-invalid .invalid-feedback {
  display: block;
}
.si-invalid .invalid-feedback:empty {
  display: none;
}

button.form-control[disabled] {
  background-color: #dfe6ed;
  border-color: #cdd9e1;
  border-top-color: #879baa;
  color: #879baa;
}

.input-group {
  width: 100%;
}

.form-group label {
  z-index: 4;
}

.platform-server ul.navbar-primary > li.server-navbar-underline > a::after,
.platform-server ul.navbar-primary > li:hover > a::after {
  content: " ";
  border-bottom: 4px solid #099;
  position: absolute;
  width: calc(100% - 20px);
  left: 0;
  bottom: -3px;
}

.ngx-datatable {
  display: block;
  overflow: hidden;
  justify-content: center;
  position: relative;
  transform: translate3d(0, 0, 0);
  /**
   * Vertical Scrolling Adjustments
   */
  /**
   * Horizontal Scrolling Adjustments
   */
  /**
   * Fixed Header Height Adjustments
   */
  /**
   * Fixed row height adjustments
   */
  /**
   * Shared Styles
   */
  /**
   * Header Styles
   */
  /**
   * Body Styles
   */
  /**
   * Footer Styles
   */
}
.ngx-datatable [hidden] {
  display: none !important;
}
.ngx-datatable *,
.ngx-datatable *:before,
.ngx-datatable *:after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
.ngx-datatable.scroll-vertical .datatable-body {
  overflow-y: auto;
}
.ngx-datatable.scroll-vertical.virtualized .datatable-body .datatable-row-wrapper {
  position: absolute;
}
.ngx-datatable.scroll-horz .datatable-body {
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}
.ngx-datatable.fixed-header .datatable-header .datatable-header-inner {
  white-space: nowrap;
}
.ngx-datatable.fixed-header .datatable-header .datatable-header-inner .datatable-header-cell {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.ngx-datatable.fixed-row .datatable-scroll {
  white-space: nowrap;
}
.ngx-datatable.fixed-row .datatable-scroll .datatable-body-row {
  white-space: nowrap;
}
.ngx-datatable.fixed-row .datatable-scroll .datatable-body-row .datatable-body-cell {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.ngx-datatable.fixed-row .datatable-scroll .datatable-body-row .datatable-body-group-cell {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.ngx-datatable .datatable-body-row,
.ngx-datatable .datatable-row-center,
.ngx-datatable .datatable-header-inner {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  flex-direction: row;
  -webkit-flex-flow: row;
  -moz-flex-flow: row;
  -ms-flex-flow: row;
  -o-flex-flow: row;
  flex-flow: row;
}
.ngx-datatable .datatable-body-cell,
.ngx-datatable .datatable-header-cell {
  overflow-x: hidden;
  vertical-align: top;
  display: inline-block;
  line-height: 1.625;
}
.ngx-datatable .datatable-body-cell:focus,
.ngx-datatable .datatable-header-cell:focus {
  outline: none;
}
.ngx-datatable .datatable-row-left,
.ngx-datatable .datatable-row-right {
  z-index: 9;
}
.ngx-datatable .datatable-row-left,
.ngx-datatable .datatable-row-center,
.ngx-datatable .datatable-row-group,
.ngx-datatable .datatable-row-right {
  position: relative;
}
.ngx-datatable .datatable-header {
  display: block;
  overflow: hidden;
}
.ngx-datatable .datatable-header .datatable-header-inner {
  align-items: stretch;
  -webkit-align-items: stretch;
}
.ngx-datatable .datatable-header .datatable-header-cell {
  position: relative;
  display: inline-block;
}
.ngx-datatable .datatable-header .datatable-header-cell.sortable .datatable-header-cell-wrapper {
  cursor: pointer;
}
.ngx-datatable .datatable-header .datatable-header-cell.longpress .datatable-header-cell-wrapper {
  cursor: move;
}
.ngx-datatable .datatable-header .datatable-header-cell .sort-btn {
  line-height: 100%;
  vertical-align: middle;
  display: inline-block;
  cursor: pointer;
}
.ngx-datatable .datatable-header .datatable-header-cell .resize-handle,
.ngx-datatable .datatable-header .datatable-header-cell .resize-handle--not-resizable {
  display: inline-block;
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  width: 5px;
  padding: 0 4px;
  visibility: hidden;
}
.ngx-datatable .datatable-header .datatable-header-cell .resize-handle {
  cursor: ew-resize;
}
.ngx-datatable .datatable-header .datatable-header-cell.resizeable:hover .resize-handle {
  visibility: visible;
}
.ngx-datatable .datatable-header .datatable-header-cell:hover .resize-handle--not-resizable {
  visibility: visible;
}
.ngx-datatable .datatable-header .datatable-header-cell .targetMarker {
  position: absolute;
  top: 0;
  bottom: 0;
}
.ngx-datatable .datatable-header .datatable-header-cell .targetMarker.dragFromLeft {
  right: 0;
}
.ngx-datatable .datatable-header .datatable-header-cell .targetMarker.dragFromRight {
  left: 0;
}
.ngx-datatable .datatable-header .datatable-header-cell .datatable-header-cell-template-wrap {
  height: inherit;
}
.ngx-datatable .datatable-body {
  position: relative;
  z-index: 10;
  display: block;
}
.ngx-datatable .datatable-body .datatable-scroll {
  display: inline-block;
}
.ngx-datatable .datatable-body .datatable-row-detail {
  overflow-y: hidden;
}
.ngx-datatable .datatable-body .datatable-row-wrapper {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -moz-box-orient: vertical;
  -moz-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}
.ngx-datatable .datatable-body .datatable-body-row {
  outline: none;
}
.ngx-datatable .datatable-body .datatable-body-row > div {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
}
.ngx-datatable .datatable-footer {
  display: block;
  width: 100%;
  overflow: auto;
}
.ngx-datatable .datatable-footer .datatable-footer-inner {
  display: flex;
  align-items: center;
  width: 100%;
}
.ngx-datatable .datatable-footer .selected-count .page-count {
  flex: 1 1 40%;
}
.ngx-datatable .datatable-footer .selected-count .datatable-pager {
  flex: 1 1 60%;
}
.ngx-datatable .datatable-footer .page-count {
  flex: 1 1 20%;
}
.ngx-datatable .datatable-footer .datatable-pager {
  flex: 1 1 80%;
  text-align: right;
}
.ngx-datatable .datatable-footer .datatable-pager .pager,
.ngx-datatable .datatable-footer .datatable-pager .pager li {
  padding: 0;
  margin: 0;
  display: inline-block;
  list-style: none;
}
.ngx-datatable .datatable-footer .datatable-pager .pager li,
.ngx-datatable .datatable-footer .datatable-pager .pager li a {
  outline: none;
}
.ngx-datatable .datatable-footer .datatable-pager .pager li a {
  cursor: pointer;
  display: inline-block;
}
.ngx-datatable .datatable-footer .datatable-pager .pager li.disabled a {
  cursor: not-allowed;
}

app-root {
  flex: 1;
  display: flex;
  flex-flow: column;
}

.logo-claim {
  visibility: hidden !important;
}

.modal-dialog {
  max-width: 1000px !important;
}

.modal-content {
  z-index: -1;
}

.container {
  margin-top: 40px;
}

bs-datepicker-container {
  z-index: 2500 !important;
}

.form-group > label.selected {
  top: 4px;
  left: 16px;
  font-size: 12px !important;
  font-weight: bold !important;
  color: #003750 !important;
}

.form-group > label {
  top: 4px;
  left: 16px;
  font-size: 12px !important;
  font-weight: bold !important;
  color: #003750 !important;
}

.normalUpgradeYear {
  background-color: aquamarine;
  margin-left: 10px;
}

.neoUpgradeYear {
  background-color: aqua;
  margin-left: 10px;
}

.nav-dropdown-menu {
  top: 164px !important;
  padding-top: 0px I !important;
}

.toast-container .toast-newton .alert .container .row {
  color: white;
}

.modal.fade .modal-dialog {
  top: 100px;
}

.nav-tabs .nav-link:hover {
  color: white;
  font-weight: 500;
  background-color: #41aaaa;
}

.nav-tabs .nav-link.active {
  color: white;
  background-color: #41aaaa;
}

html, body {
  height: 100%;
}

body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

._mdsp-appName {
  font-size: 15px !important;
}